import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';

import { AbonnementService } from 'src/app/services/core/abonnement.service';
import { ConfigService } from 'src/app/services/core/config.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from "src/app/services/core/modal.service";
import { ProjectsPickerService } from 'src/app/services/core/projects-picker.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { TeamsPickerService } from 'src/app/services/core/teams-picker.service';
import { TeamsService } from 'src/app/services/core/teams.service';
import { UserService } from 'src/app/services/core/user.service';
import { ViewService } from 'src/app/services/core/view.service';
import { MediaextendService } from 'src/app/services/media/mediaextend.service';

import { ProjectPage } from 'src/app/pages/core/projects/project/project.page';
import { TeamPage } from 'src/app/pages/core/user/teams/team/team.page';
import { UserGroupsService } from '../../../../../services/core/user-groups.service';

@Component({
  selector: 'app-customer',
  standalone: false,
  templateUrl: './customer.page.html',
  styleUrls: ['./customer.page.scss'],
})
export class CustomerPage implements OnInit {

  appConfig: pipelineAppConfig;

  customer: user = {
    billing: {

    },
  };

  fallbackAvatarImg: string = './assets/img/avatars/1.webp';
  fallbackImg: string = './assets/img/fallback.webp';

  state: state = {};

  user: user;

  view: any = {
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    itemSize: 64,
    segment: 'general',
    userGroups: [],
    route: 'customer',
  };

  constructor(
    private abo: AbonnementService,
    private alertCtrl: AlertController,
    private configService: ConfigService,
    private events: EventsService,
    private media: MediaextendService,
    private modalService: ModalService,
    private projectsService: ProjectsService,
    private projectsPicker: ProjectsPickerService,
    private teams: TeamsService,
    private teamsPicker: TeamsPickerService,
    private userGroups: UserGroupsService,
    public userService: UserService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();

    this.customer = (this.userService.detailItem() || {} as any);
    this.customer.billing = this.customer.billing || {};

    this.customer.classifications = this.customer.classifications || {};

    this.user = this.userService.getUser() || {};

    this.view.blogConnected = (
      this.customer &&
      this.customer.classifications &&
      this.customer.classifications.blogSource &&
      this.customer.classifications.blogSourceUid
    );

    this.view.shopConnected = (
      this.customer &&
      this.customer.classifications &&
      this.customer.classifications.shopSource &&
      this.customer.classifications.shopSourceUid
    );

    this.view.mode = (!!this.customer && !!this.customer.uid ? 'edit' : 'create');

    this.loadUserGroups();
  }

  addAbonnement() {
    this.abo.pick()
      .then((response: any) => {
        if (!!response && !!response.data && !!response.data.item && !!response.data.item.uid) {
          this.abo.updateUserAbonnement(this.customer.uid, response.data.item.uid)
            .then(() => {
              this.loadAbonnements(true);
            })
            .catch((error: any) => {
              this.events.publish('error', error);
            });
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  addProject() {
    this.projectsPicker.pick({
      multiple: true,
    })
      .then((response: any) => {
        if (!!response && !!response.data && !!response.data.items && !!response.data.items.length) {

          response.data.items.forEach((project: project) => {
            this.projectsService.addUserToProject(project.uid, this.customer.uid);
          });

          setTimeout(() => {
            this.loadProjects(true);
          }, 1000);
        }
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  addTeam() {
    this.teamsPicker.pick()
      .then((response: any) => {
        if (!!response && !!response.data && !!response.data.item && !!response.data.item.uid) {
          this.teams.addUserToTeam(response.data.item.uid, this.customer.uid)
            .then(() => {
              this.loadTeams(true);
            })
            .catch((error: any) => {
              this.events.publish('error', error);
            });
        }
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.title = (!!this.customer && !!this.customer.uid ? 'edit_customer' : 'create_customer');
  }

  create() {
    this.customer.email = this.customer.email || this.customer.billing.email;
    let username = this.customer.email;

    this.customer.nickname = this.customer.nickname || username;
    this.customer.password = this.view.password;
    this.customer.password_repeat = this.view.password_repeat;

    this.userService.create(this.customer, false)
      .then(() => {
        this.dismiss(null, 'done');
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  async createProject() {

    const modal: any = await this.modalService.create({
      component: ProjectPage,
    });

    this.modalService.present(modal);

    modal.onWillDismiss().then(async (response: any) => {
      console.log('project modal dismiss response', response);

      try {
        if (!!this.customer.uid) {
          if (!!response && !!response.uid) {
            await this.projectsService.addToTeam(response.uid, this.customer.uid);
            this.doRefresh();
          } else
            if (!!response && !!response.project && !!response.project.uid) {
              await this.projectsService.addToTeam(response.project.uid, this.customer.uid);
              this.doRefresh();
            }
        }
      } catch (e) {
        console.warn('linking new project failed', e);
      }

      this.doRefresh();
    });

  }

  createTeam() {

  }

  dismiss(data: any | null = null, role: string | null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  doRefresh() {

  }

  ionViewWilLLeave() {
    this.userService.detailItem({} as any);
  }

  async loadAbonnements(blForceRefresh: boolean = false) {

    if (!this.customer || !this.customer.uid) {
      return false;
    }

    this.view.loadingAbonnements = true;

    try {

      const extensionResponse: any = await this.abo.getSubscribedExtensions({
        user: {
          email: this.customer.email,
        },
        userId: this.customer.uid,
      }, blForceRefresh);

      this.view.abonnements = (!!extensionResponse && !!extensionResponse.extensions ? extensionResponse.extensions : []);
      console.log('customer: abonnements', this.view.abonnements);

      this.view.loadingAbonnements = false;
    } catch (e) {
      this.view.abonnements = [];
      this.view.loadingAbonnements = false;
      console.warn('loading plans failed', e);
    }

  }

  loadProjects(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      if (!this.customer || !this.customer.uid) {
        resolve(this.customer);
      } else {
        this.view.loadingProjects = true;

        this.projectsService.getByUserUid(this.customer.uid, blForceRefresh, {
          user: {
            email: this.customer.email,
            uid: this.customer.uid,
          },
          userId: this.customer.uid,
        })
          .then((projects: project[]) => {
            this.view.loadingProjects = false;
            this.view.projects = projects;
            resolve(this.customer);
          })
          .catch((error: any) => {
            this.view.loadingProjects = false;
            reject(error);
          });
      }
    });
  }

  loadTeams(blForceRefresh: boolean = false) {

    if (!this.customer || !this.customer.uid) {
      return false;
    }

    this.view.loadingTeams = true;

    this.teams.getByUserUid(this.customer.uid, blForceRefresh)
      .then((teams: team[]) => {
        this.view.loadingTeams = false;

        this.view.teams = teams || [];
        console.log('this.view.teams', this.view.teams);
      })
      .catch((error: any) => {
        this.view.loadingTeams = false;

        this.view.teams = [];
        console.warn('loading teams failed', error);
      });
  }

  async loadUserGroups() {
    this.view.loadingUserGroups = true;

    try {
      this.view.userGroups = await this.userGroups.getAvailable();
      this.view.loadingUserGroups = false;
    } catch (e) {
      this.view.loadingUserGroups = false;
      console.warn('> load user groups failed', e);
    }
  }

  ngAfterContentInit() {
    try {
      this.loadProjects(true);
      this.loadTeams(true);
      this.loadAbonnements(true);
    } catch (e) {
      this.events.publish('error', e);
    }
  }

  ngOnInit() {
    this.calcViewVars();

    this.events.subscribe('window:resized', () => {
      this.calcViewVars();
    });
  }

  removeAbonnement() {

  }

  removeUserFromAbo(abo: abonnement) {
    console.log('removeUserFromAbo: abo', abo);
  }

  removeUserFromTeam(team: team) {
    console.log('removeUserFromProject: team', team);
  }

  removeUserFromProject(project: project) {
    console.log('removeUserFromProject: project', project);
  }

  resetPassword() {
    this.userService
      .requestNewPassword(this.customer)
      .then(async (response: any) => {

        if (response.success) {

          const alert: any = await this.alertCtrl.create({
            header: 'E-Mail verschickt',
            message: 'Bitte bestätige den Link, den wir Dir an Deine E-Mail Adresse geschickt haben',
            buttons: [{
              text: 'Okay',
            }]
          });

          alert.present();

        } else {
          this.events.publish('error_password_reset_failed');
        }
      })
      .catch((error: any) => {
        this.events.publish('error_password_reset_failed');
      });
  }

  resetPasswordManually() {
    this.view.resetPasswordManuallyMode = true;
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackAvatarImg;
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  update() {
    let user: user = (JSON.parse(JSON.stringify(this.customer)) as user);

    if (!!this.view.password && !!this.view.password_repeat) {
      user.password_update = {
        new: `${this.view.password}`,
        new_repeat: `${this.view.password_repeat}`,
        old: `${this.view.password_old}`,
      }
    }

    this.userService.update(user)
      .then(() => {

        /*
        this.events.publish('toast', {
          message: 'customer_updated',
        });
        */

        this.dismiss(null, 'done');
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  uploadPhoto() {
    this.media.applyFromWeb(null, {
    }).then((response: any) => {
      let imageUrl: string | null = (typeof response === 'string' ? response : null);

      if (!!response && !!response.items && !!response.items[0] && !!response.items[0].thumbnail) {
        imageUrl = response.items[0].thumbnail;
      }

      if (!!imageUrl) {
        this.customer.photo = imageUrl;
      }

    })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  viewAbo(abo: abonnement) {
    console.log('customer: viewAbo: abo', abo);
  }

  viewProject(project: project) {
    console.log('customer: viewProject: project', project);
  }

  async viewTeam(team: team) {
    this.teams.detailItem(team as team);

    const editModal: any = await this.modalService.create({
      component: TeamPage,
      componentProps: {
        team: team,
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    editModal.onWillDismiss().then(() => {
      this.doRefresh();
    });

    this.modalService.present(editModal);
  }

}