import { Component, OnInit } from '@angular/core';

import { AiLoaderService } from 'src/app/services/ai/ai-loader.service';
import { EventsService } from 'src/app/services/core/events.service';
import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { ModalService } from 'src/app/services/core/modal.service';
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-device-setup',
  standalone: false,
  templateUrl: './device-setup.page.html',
  styleUrls: ['./device-setup.page.scss'],
})
export class DeviceSetupPage implements OnInit {

  aiSettings: aiSettings = {};
  
  avatarUrl: string = './assets/img/avatars/1.jpg';

  fallbackImg: string = './assets/img/fallback.webp';

  state: state = {};

  device: device;

  devicesService: any;

  view: any = {
    browserIcons: {
      chrome: 'logo-chrome',
      edge: 'logo-edge',
      firefox: 'logo-firefox',
    },
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'device_setup_top_card',
      text: 'device_setup_top_card_text',
      title: 'device_setup_top_card_title',
    },
    route: 'device/setup',
    setup_steps: [
      {
        checked: true,
        description: 'device_setup_ai_models_download_description',
        icon: 'sparkles-outline',
        label: 'device_setup_ai_models_download',
        type: 'local',
      },
      {
        checked: true,
        description: 'device_setup_ai_models_test_description',
        icon: 'flask-outline',
        label: 'device_setup_ai_models_test',
        type: 'local',
      },
      {
        description: 'device_setup_offline_description',
        icon: 'cloud-offline-outline',
        label: 'device_setup_offline',
      },
      {
        description: 'device_setup_push_notifications_description',
        disabled: true,
        icon: 'notifications-outline',
        label: 'device_setup_push_notifications',
      },
    ],
    title: 'setup_device',
    types: [
      {
        checked: true,
        icon: 'cloud-outline',
        name: 'cloud',
        uid: 'cloud',
      },
      {
        icon: 'hardware-chip-outline',
        name: 'local',
        uid: 'local',
      },
    ],
    type_uid: 'cloud',
  };

  constructor(
    private aiLoader: AiLoaderService,

    private events: EventsService,
    private media: MediaextendService,
    private modalService: ModalService,
    private viewService: ViewService,
  ) {
  }

  aiSettingsChanged(event: any = null) {
    
  }

  async calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.isWebGPUSupported = this.aiLoader.isWebGPUSupported();
    this.view.title = (!!this.device && this.device.uid ? 'edit_device' : 'create_device');

    if (!!this.view.introCard) {
      this.view.introCard.dismissable = false;
      this.view.introCard.hidden = false;
    }
  }

  dismiss(data: any | null = null, role: string | null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  doRefresh(event: any | null = null) {
    this.loadDevice(true)
      .then(() => {
        if (event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  ionViewWillLeave() {
    this.devicesService.detailItem({} as any);
  }

  loadDevice(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      if (!this.device || !this.device.uid) {
        reject('error_missing_device_uid');
      } else {
        this.devicesService.getByUid(this.device.uid, blForceRefresh)
          .then((device: device) => {
            this.device = device;
            resolve(this.device);
          })
          .catch(reject);
      }
    });
  }

  ngOnInit() {

    const deviceFromService: device | null = this.devicesService.detailItem();

    const device: device = deviceFromService || (this.device || {
      active: true,
      description: '',
    });

    console.log('device-setup: device', device);

    if (!!device) {
      this.device = device;
    }

    this.calcViewVars();

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });
  }

  scaleFactorChanged(num: number | null = null) {
    if (num !== null && ((num > 0 && this.view.scale_factor < 5) || (num < 0 && this.view.scale_factor > 1))) {
      this.view.scale_factor += num;
    }
  }

  setup() {
    this.update();
  }

  skip() {
    this.dismiss();
  }

  thumbnailLoadingFailed(device: device) {
    device.photo = this.fallbackImg;
  }

  toggleType(type, iType) {
    this.view.types.forEach((_type: any) => {
      _type.checked = (type.uid === _type.uid);
    });

    this.view.type_uid = type.uid;
  }

  update(blDismiss: boolean = true) {

    if (!this.device || !this.device.token || !this.device.token.length) {
      return false;
    }

    this.view.loading = true;

    this.devicesService.update(this.device)
      .then((response: any) => {

        if (!!response && !!response.device) {
          this.device = response.device;
        }

        if (!!blDismiss) {
          this.dismiss(response, 'done');
        }

        this.view.loading = false;
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        this.view.loading = false;
      });
  }

  uploadPhoto() {
    this.media.applyFromWeb(null, {
    })
      .then((response: any) => {
        let imageUrl: string | null = (typeof response === 'string' ? response : null);

        if (!!response && !!response.items && !!response.items[0] && !!response.items[0].thumbnail) {
          imageUrl = response.items[0].thumbnail;
        }

        this.device.photo = imageUrl;
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

}