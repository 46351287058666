import { Component, ElementRef, Input, NgZone, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActionSheetController, IonContent } from '@ionic/angular';

import { AiWorkerService } from "src/app/services/ai/ai-worker.service";
import { AppcmsService } from "src/app/services/core/appcms.service";
import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { TranslationService } from 'src/app/services/core/translation.service';
import { ViewService } from 'src/app/services/core/view.service';
import { FtpService } from 'src/app/services/integrations/ftp.service';
import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { StablediffusionService } from 'src/app/services/media/stablediffusion.service';
import { VideosService } from 'src/app/services/media/videos.service';
import { BrowserService } from "src/app/services/utils/browser.service";
import { ClipboardService } from "src/app/services/utils/clipboard.service";
import { IntroService } from 'src/app/services/utils/intro.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';

import { ImageEditorComponent } from 'src/app/components/editors/image-editor/image-editor.component';
import { VideoEditorComponent } from 'src/app/components/editors/video-editor/video-editor.component';
import { MediaItemInfoPopoverComponent } from 'src/app/components/media/media-item-info-popover/media-item-info-popover.component';

import { hostUrl, proxyUrl } from "src/config/variables";

@Component({
  selector: 'app-view-media',
  standalone: false,
  templateUrl: './view-media.page.html',
  styleUrls: ['./view-media.page.scss'],
})
export class ViewMediaPage implements OnDestroy, OnInit {

  aiSettings: aiSettings = {};

  appConfig: pipelineAppConfig;

  cards: any = {
    audio_transcription: { open: false },
    attributes: { open: false },
    caption: { open: false },
    comments: { open: true },
    item_attributes: { open: false },
    masks: { open: false },
    statistics: { open: true },
    tags: { open: true },
    voiceover: { open: false },
  };

  commentsListOptions: any = {};

  @ViewChild(IonContent) content: IonContent;

  @ViewChild('downloadPopover') downloadPopover: any;

  fallbackImg: string = './assets/img/fallback.webp';

  @ViewChild(MediaItemInfoPopoverComponent) itemInfoPopover: any;

  @ViewChild('preview', { read: ElementRef, }) preview: ElementRef;
  @ViewChildren('previewImage', { read: ElementRef, }) previewImages: QueryList<ElementRef>;
  @ViewChild('slider', { read: ElementRef, }) slider: any; // ElementRef<HTMLImgComparisonSliderElement>;

  @ViewChild(ImageEditorComponent) imageEditor: any;
  @ViewChild(VideoEditorComponent) videoEditor: any;

  index: number = 0;

  introCard: introCardConfig = {
    uid: 'view_media_top_card',
    text: 'view_media_top_card_text',
    title: 'view_media_top_card_title',
  };

  isDownloadPopoverOpen: boolean = false;

  mediaList: any[];

  proxyUrl: string;

  selectionOptions: selectionOption[] = [
    {
      icon: 'cloud-download-outline',
      label: 'import',
      uid: 'import',
    },
    {
      icon: 'expand-outline',
      label: 'upscale',
      uid: 'upscale',
    },
    {
      icon: 'film-outline',
      label: 'send_to_video_creator',
      uid: 'send_to_video_creator',
    },
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
  ];

  settingsModal: any;

  state: state = {

  };

  tableButtons: button[] = [];

  tableSelectionOptions: selectionOption[] = [];

  tableViewOptions: tableViewOptions = {
    mode: 'view',
  };

  media: any = {};

  @Input() mode: string = 'view';

  view: any = {
    canNavigateBack: false,
    canNavigateNext: false,
    colSize: (window.innerWidth > 768 ? 4 : 12),
    inpainting_send_mask: true,
    itemKeys: [],
    mode: 'view',
    platforms: [],
    showReactionsCard: true,
    title: 'view_media',
    uploading: false,
  };

  constructor(
    public aiWorker: AiWorkerService,

    private actionSheetCtrl: ActionSheetController,
    private AppCMS: AppcmsService,
    private browser: BrowserService,
    private clipboard: ClipboardService,
    private configService: ConfigService,
    private domSanitizer: DomSanitizer,
    private events: EventsService,
    private ftp: FtpService,
    private introService: IntroService,
    private mediaService: MediaextendService,
    private modalService: ModalService,
    private sd: StablediffusionService,
    private sidebar: SidebarService,
    private translations: TranslationService,
    private videos: VideosService,
    private viewService: ViewService,
    private zone: NgZone,
  ) {
    this.appConfig = this.configService.getConfig();

    this.commentsListOptions = {
      allowWriteComment: (!!this.appConfig && !!this.appConfig.allowUserCommentMedia),
    };

    this.proxyUrl = proxyUrl;

    this.view.itemKeys = Object.keys(this.media);
    this.view.mode = (this.mode || this.view.mode) || 'create';
  }

  addMedia() {
    this.translations.get([
      'add_to_post_header',
      'audio',
      'cancel',
      'delete_photo',
      'image',
      'take_photo',
      'upload_photo',
      'video',
    ])
      .subscribe(async (response: any) => {

        let buttons = [
          /*
          {
            text: response.audio || 'audio',
            handler: () => {
              //this.addAudioToPost();
            }
          },
          */
          {
            text: response.image || 'image',
            handler: () => {
              //this.addImageToPost();
            }
          },
          {
            text: response.video || 'video',
            handler: () => {
              //this.addVideoToPost();
            }
          },
          {
            text: response.cancel || 'cancel',
            role: "cancel",
          }
        ];

        let actionSheet = await this.actionSheetCtrl.create({
          header: response.add_to_post_header || 'add_to_post_header',
          buttons: buttons,
        });

        await actionSheet.present();

      });
  }

  addResponseImagesToHistory(images: any[]) {
    this.view.multiple = !!(!!images && !!images.length);

    if (!images || !images.length) {
      return false;
    }

    images.forEach((part: string, splitIndex: number) => {
      setTimeout(() => {

        if (!!part) {
          this.view.history.push({
            mode: 'view',
            photo: part,
            post_mime_type: `${this.media.post_mime_type || 'image'}`,
            thumbnail: part,
            guid: part,
            role: 'assistant',
            type: `${this.media.type || 'image'}`,
            url: part,
          });
        }

        this.scrollDown();

      }, (splitIndex) * 300);
    });
  }

  addResponseQueueItemToHistory(item: any) {
    console.log('view-media: addResponseQueueItemToHistory', item);
    //this.dismiss();
  }

  addTag() {
    this.view.addTagMode = !this.view.addTagMode;
  }

  aiSettingsChanged(event: any | null = null) {
    console.log('aiSettingsChanged', event);
  }

  aiTools_upscale() {
    this.view.loading = true;

    this.sd.upscale(this.media.guid)
      .then((response: any) => {
        this.zone.run(() => {

          if (!!response && !!response.images) {
            //this.addResponseImagesToHistory(response.images);
            this.media.live_preview = response.images[0];
            this.view.history[0] = this.media;
          }

          this.view.ai_tool_selection = null;
          this.view.loading = false;
          this.view.mode = (!!response && !!response.images ? 'edit' : 'view');
        });
      })
      .catch((error: any) => {
        this.events.publish('error', error);

        this.view.ai_tool_selection = null;
        this.view.loading = false;
      });
  }

  analyseMedia(media: mediaItem | null = null, blForceRefresh: boolean = true) {
    console.log('view-media: analyseMedia', media);

    if (!!this.view.analyzing || !!this.view.analyzed) {
      return false;
    }

    this.analyseSingleMedia(media, false, blForceRefresh)
      .then(() => {
        this.view.analyzed = true;
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  analyseSingleMedia(media: mediaItem | null = null, blBackground: boolean = false, blForceRefresh: boolean = true) {
    return new Promise((resolve, reject) => {
      media = media || this.media;

      if (!blBackground) {
        this.view.analyzing = true;

        this.events.publish('toast', {
          message: 'analyzing_media_item',
        });
      }

      this.mediaService.analyse([media.ID], {}, blForceRefresh)
        .then(() => {
          console.log('analyze done');

          this.view.analyzing = false;

          resolve(this.view);
        })
        .catch((error: any) => {
          console.warn('analyze failed', error);

          this.view.analyzing = false;

          reject(error);
        });
    });
  }

  bindKeyEvents() {
    window.addEventListener('keydown', (event) => {
      event.stopImmediatePropagation();

      this.onKeyDown(event);
    }, false);
  }

  calcDownloadOptions(options: any = {}) {

    if (!this.media || !this.media.post_mime_type) {
      return false;
    }

    let formats: string[] = [];

    switch (this.media.post_mime_type) {
      case 'document':
        break;
      case 'image':
        formats = ['jpg', 'png', 'webp'];

        break;
      case 'video':
        formats = ['mp3', 'webm'];

        break;
      default:
        console.warn('not implemented', this.media);
    }

    return {
      formats: (formats || []),
      options: (options || {}),
    };
  }

  calcSelectedItems(item: any | null = null) {
    if (this.view.history && this.view.history.length) {
      this.view.selectedItems = this.view.history.filter((_item: any) => {
        return !!_item.checked || (!!item && (_item.uid === item.uid && !!item.checked));
      });
    } else {
      this.view.selectedItems = [];
    }

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  async calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.colSize = (window.innerWidth > 768 ? 4 : 12);

    this.view.canNavigateBack = (!!this.mediaList && (this.index !== 0));
    this.view.canNavigateNext = (!!this.mediaList && (this.index !== this.mediaList.length));

    const introCardUid: string = `${this.view.mode || 'view'}_${this.media.type || 'media'}_top_card`;

    this.introCard = {
      hidden: await this.introService.isIntroCardHidden(introCardUid),
      uid: introCardUid,
      text: `${introCardUid}_text`,
      title: `${introCardUid}_title`,
    };
  }

  copyToClipboard(string: string) {
    this.clipboard.copyText(`${string}`);
  }

  create() {
    this.videos.submitVideo(this.media)
      .then((response: any) => {
        this.dismiss(response, 'done');
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  async createMask(maskType: string = 'mask') {
    let exec: any;

    try {
      switch (maskType) {
        case 'depth_estimation':
          exec = await this.aiWorker.imageToDepth(this.media.guid);
          break;
        case 'mask':
          exec = await this.sd.imageToMask(this.media.guid);
          break;
        case 'segmentation':
          exec = await this.sd.imageToSegmentation(this.media.guid);
          break;
      }

      console.log('createMask: exec', exec);

      if (!!exec && !!exec.url) {
        this.view.attributesConfig[maskType] = exec.url;
      }

      console.log('createMedia: updated view.attributesConfig', this.view.attributesConfig);

    } catch (e: any) {
      console.error('Create mask failed: ', e);
      this.events.publish('error', e);
    }
  }

  copyUrl() {
    let url: string = `${this.media.post_mime_type === 'image' ? (this.media.thumbnail || this.media.guid) : (this.media.videoSrc || this.media.guid)}`;
    this.copyToClipboard(url);
  }

  delete() {

    if (!this.media.uid) {
      return false;
    }

    this.mediaService.deleteMediaItem(this.media.uid)
      .then(() => {
        if (!!this.view.canNavigateNext) {
          this.navNext();
        } else
          if (!!this.view.canNavigateBack) {
            this.navBack();
          } else {
            this.dismiss();
          }
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  async dismiss(data: any | null = null, role: string | null = 'dismiss') {

    if (this.view.mode === 'edit') {
      this.view.mode = 'view';
      return false;
    }

    if (!!data) {
      data.post = data.post || this.media;
    }

    this.modalService.dismiss(data, role);
  }

  doRefresh() {

    if (!this.media || !this.media.uid) {
      return false;
    }

    this.mediaService.getMediaItemByUid(this.media.uid, true)
      .then((media: any) => {
        this.zone.run(() => {

          // only update media object if user has no updated live_preview
          if (!this.media.live_preview && (!!media && !!media.ID)) {
            this.media = media;
            this.view.history[0] = this.media;
          }

          this.loadMetaData(true);
        });
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  download(event: any | null = null, format: any | null = null) {

    const downloadOptions: any = this.calcDownloadOptions({
      format: format,
    });

    console.log('downloadOptions', downloadOptions);
    console.log('download format', format);

    // if multiple formats found, show popup
    if (!!downloadOptions && !!downloadOptions.formats && (downloadOptions.formats.length > 1)) {
      this.view.downloadOptions = downloadOptions;

      this.isDownloadPopoverOpen = true;

      console.log('downloadPopover', this.downloadPopover);
      this.downloadPopover.event = event;
      return false;
    }

    // else, directly download the file
    this.mediaService.download(this.media.uid, {
      format: format,
    })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  downloadInFormat(format: any, event: any | null = null) {
    console.log('downloadInFormat', format);

    this.isDownloadPopoverOpen = false;

    return this.download(event, format);
  }

  edit() {
    this.zone.run(() => {

      // update view variables
      this.view.mode = 'edit';
      this.view.title = 'edit_media';

      // update media item
      //this.media.live_preview = (this.media.guid || this.media.url);
      this.view.history[0] = this.media;

      this.calcViewVars();
    });
  }

  exportCanvas(index: number = 0, event: any | null = null) {
    try {
      return this.imageEditor.exportCanvas(index, event);
    } catch (e) {
      console.warn('editor init failed', e);
    }
  }

  exportCanvasMask(index: number = 0, event: any | null = null, backgroundColor: string = '#ffffff') {
    try {
      return this.imageEditor.exportCanvasMask(index, event, backgroundColor);
    } catch (e) {
      console.warn('editor init failed', e);
    }
  }

  async initInpainting() {
    try {
      this.imageEditor.initInpainting();
    } catch (e) {
      console.warn('editor init failed', e);
    }
  }

  hasChanges() {
    this.view.hasChanges = true;
  }

  initEvents() {
    this.view.events = this.view.events || {};

    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);
      this.view.colSize = (window.innerWidth > 768 ? 4 : 12);
    });
  }

  async inpaint(index: number = 0, mode: string = 'inpaint') {
    console.log('view-media: inpaint', index);

    this.media.live_preview = null;

    if (!this.view.history || !this.view.history[index] || !this.view.history[index].guid) {
      return false;
    }

    if (this.view.history[index].mode === mode) {
      this.view.history[index].mode = 'edit';
    }

    this.view.mode = 'edit';
    this.view.title = 'edit_image';

    const introCardUid: string = `${mode}_image_top_card`;

    this.introCard = {
      hidden: await this.introService.isIntroCardHidden(introCardUid),
      uid: introCardUid,
      text: `${introCardUid}_text`,
      title: `${introCardUid}_title`,
    };

    // if media has not been analysed yet, analyse first
    if (!!this.view.attributesConfig && (!this.view.attributesConfig.mask || !this.view.attributesConfig.mask.length)) {
      await this.analyseSingleMedia(this.view.history[index], true);
    }

    this.view.history[index].mode = mode;

    setTimeout(async () => {

      // calc canvas sizes after mode change (required updated grid first)
      if (mode === 'inpaint') {
        this.initInpainting();
      }

      // then, load + render init canvas screen (overlay first)
      setTimeout(async () => {
        this.view.history[index].overlay_initialized = true;

        // after initializing overlay, init main canvas
        setTimeout(async () => {
          this.view.history[index].initialized = true;

          if (!this.view.history[index].guid) {
            return false;
          }

          try {
            if (mode === 'inpaint') {
              const blIsExternalUrl: boolean = !!(this.view.history[index].guid.indexOf('http') !== -1),
                url: string = (blIsExternalUrl ? this.proxyUrl + this.view.history[index].guid : this.view.history[index].guid);

              var img = new Image();
              img.crossOrigin = "anonymous";
              img.src = url;

              img.onload = () => {
                const inpaintCanvas: any = this.imageEditor.getInpaintCanvas();

                if (!!inpaintCanvas) {
                  const ctx: any = inpaintCanvas.nativeElement.getContext('2d');
                  ctx.drawImage(img, 0, 0, this.view.drawCanvasWidth, this.view.drawCanvasHeight);
                }
              };
            }
          } catch (e) {
            console.warn('init inpaint editor failed', e);
          }

          this.view.loading = false;
        }, 150);
      }, 50);
    }, 100);
  }

  ionViewDidEnter() {
    this.calcViewVars();
  }

  ionViewWillEnter() {
    this.calcViewVars();
  }

  ionViewWillLeave() {
    this.calcViewVars();
  }

  itemInfo(image: any, event: any | null = null) {
    console.log('get item info', image);

    this.view.item = image;

    this.itemInfoPopover.show({
      event: event,
      item: image,
    });
  }

  async load() {
    this.view.loading = true;

    if (!!this.media && !!this.media.guid) {

      // add host url to thumbnail if missing
      if (!!this.media.thumbnail && (this.media.thumbnail[0] === '/')) {
        this.media.thumbnail = `${hostUrl}${this.media.thumbnail}`;
      }

      // add host url to guid if missing
      if (this.media.guid[0] === '/') {
        this.media.guid = `${hostUrl}${this.media.guid}`;
      }

      // calculate extension if missing
      if (!this.media.extension) {
        const extensionSplit: string[] = this.media.guid.split('.');

        if (extensionSplit.length > 1) {
          this.media.extension = extensionSplit[extensionSplit.length - 1];
        }
      }
    }

    try {
      await this.loadCards();
      await this.loadMetaData();
    } catch (e) {
      console.warn('loading failed', e);
    }

    this.view.loading = false;
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  loadComments(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      if (!this.view.expertMode) {
        reject('error_component_not_visible');
      } else
        if (!this.media || !this.media.uid) {
          reject('error_missing_media_uid');
        } else {
          this.view.comments = [];
          resolve(this.view.comments);
        }
    });
  }

  loadExpertSidebarData() {

    if (!this.view.expertMode) {
      return false;
    }

    // load statistics
    try {
      this.loadStatistics();
    } catch (e) {
      console.warn('loading statistics failed', e);
    }

    // load comments
    try {
      this.loadComments();
    } catch (e) {
      console.warn('loading statistics failed', e);
    }

  }

  loadMetaData(blForceRefresh: boolean = false) {
    this.view.title = 'view_media';

    if (!!this.media && (this.media.platform === 'ftpmanager')) {
      return this.loadViaFtp(blForceRefresh);
    }

    console.log('loadMetaData: media', this.media);

    if (!this.media || !this.media.ID) {
      return false;
    }

    this.mediaService.getMediaMetaData(this.media.ID, {}, blForceRefresh)
      .then((metadata: any) => {
        console.log('loadMetaData: metadata', metadata);

        if (!!metadata.attributes && !!metadata.attributes.length) {
          this.media.attributes = this.parseAndExtractAttributes(metadata.attributes || []);
        } else {
          this.media.attributes = [];

          try {
            if (!this.view.analyzed) {
              this.analyseMedia();
            }
          } catch (e) {
            console.warn('analyzing media failed', e);
          }
        }

        if (!!metadata && !!metadata.videos) {
          this.view.variations = (!!metadata.videos && !!metadata.videos.length ? metadata.videos : []);
        }
      })
      .catch((error: any) => {
        console.warn('loading metadata failed', error);
      });

    const thumbnailPrefix: string = `/images/thumbnail.webp?width=1500&height=1500&input=`;

    if (!!this.media && !!this.media.post_type && (this.media.post_type === 'attachment')) {
      this.view.document_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.media.guid);
    }

    if (!!this.media && !!this.media.guid && (this.media.guid.indexOf('ftpmanager') !== -1) && (this.media.guid.indexOf(thumbnailPrefix) === -1)) {
      const apiUrl: string = this.AppCMS.getApiUrl();
      this.media.photo = `${apiUrl}${thumbnailPrefix}${encodeURIComponent(this.media.guid)}`;
    }
  }

  loadStatistics(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      if (!this.view.expertMode) {
        reject('error_component_not_visible');
      } else {
      }
    });
  }

  async loadViaFtp(blForceRefresh: boolean = false) {
    this.view.storageConnection = this.ftp.detailItem();

    if (!this.view.storageConnection || !this.view.storageConnection.uid) {
      return false;
    }

    try {

      if (!!this.media.guid && this.media.guid.indexOf('http') === 0) {
        if (!!this.media.post_type && (this.media.post_type === 'attachment')) {
          this.view.document_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.media.guid);
        }
      } else {

        const load: any = await this.ftp.proxy(this.view.storageConnection, {
          file: this.media.guid,
        }, blForceRefresh);

        if (!!load && !!load.file) {
          this.media.guid = load.file.url || this.media.guid;
          this.media.post_mime_type = load.file.post_mime_type || 'attachment';
          this.media.post_type = load.file.post_type || 'attachment';
          this.media.type = load.file.type || 'attachment';

          if (!!this.media.post_type && (this.media.post_type === 'attachment')) {
            this.view.document_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.media.guid);
          }
        }
      }
    } catch (e) {
      console.warn('loadViaFtp: failed', e);

      this.events.publish('error', e);
    }
  }

  mediaItemActionsToolbarButtonClicked(event: any | null = null) {
    console.log('mediaItemActionsToolbarButtonClicked', event);

    switch (event.uid) {
      case 'addResponseImagesToHistory':
        this.addResponseImagesToHistory(event.images || []);
        break;
      case 'addResponseQueueItemToHistory':
        this.addResponseQueueItemToHistory(event.item || {});
        break;
      case 'inpaint':
        this.inpaint();
        break;
      case 'outpaint':
        this.outpaint();
        break;
      case 'remove_background':
        this.removeBackground();
        break;
      case 'useAsPrimary':
        this.useAsPrimary(event.item, event.index || 0);
        break;
    }
  }

  navBack() {

    if (!this.mediaList[this.index - 1]) {
      return false;
    }

    this.index--;
    this.onNavIndexChanged();
  }

  navNext() {

    if (!this.mediaList[this.index + 1]) {
      return false;
    }

    this.index++;
    this.onNavIndexChanged();
  }

  ngOnDestroy() {

    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }

    this.unbindKeyEvents();
  }

  ngOnInit() {
    this.view.history = [this.media];

    this.calcViewVars();
    this.bindKeyEvents();
    this.initEvents();

    this.load();
  }

  onCaptionChanged(caption: string | null = null) {
    console.log('onCaptionChanged', caption);
  }

  onCommentsChanged(comments: any[] | null = []) {
    this.view.comments = (comments || []);
  }

  async onInpaintChanged(event: any | null = null) {
    console.log('view-media: onInpaintChanged: event', event);

    if (!!this.view.history && !!this.view.history[event.index]) {

      // first, update preview frame based on drawing
      const dataURL: string | null = this.exportCanvas(event.index, event);

      if (!dataURL || !dataURL.length) {
        return false;
      }

      this.view.history[event.index].live_preview = dataURL;

      // if ai prompt is already inserted, live reload after draw

      if (!this.view.aiPrompt || !this.view.aiPrompt.length) {
        return false;
      }

      this.runHistoryItemInpainting(event.index);
    }
  }

  onTableFieldsChanged(event: any | null = null) {
    console.log('onTableFieldsChanged: event', event);
  }

  onTableItemsChanged(event: any | null = null) {
    console.log('onTableItemsChanged: event', event);
  }

  onKeyDown(event: any) {
    switch (event.key) {
      case "ArrowLeft":
        if (!this.view.canNavigateBack) { return false; }
        this.navBack();
        break;
      case "ArrowRight":
        if (!this.view.canNavigateNext) { return false; }
        this.navNext();
        break;
    }
  }

  onLoadImage() {
  }

  onMediaItemChecked(mediaItem: mediaItem) {
    this.calcSelectedItems();
  }

  onNavIndexChanged() {
    this.zone.run(() => {

      // reset media metadata
      this.media.attributes = [];

      // reset view variables
      this.view.analyzed = false;
      this.view.analyzing = false;
      this.view.attributesConfig = {};
      this.view.mode = 'view';
      this.view.rebuild = true;

      setTimeout(() => {
        this.media = this.mediaList[this.index];
        this.media.initialized = false;
        this.media.mode = 'view';
        this.media.loading = false;
        this.media.overlay_initialized = false;

        this.view.history = [this.media];
        this.view.loading = false;
        this.view.rebuild = false;

        this.calcViewVars();
        this.loadExpertSidebarData();
        this.loadMetaData();
      }, 250);
    });
  }

  onSelectedItemsChanged(event: any | null = null) {
    this.calcSelectedItems();
    this.calcViewVars();
  }

  onSelectionActionChanged(event: any | null = null) {
    console.log('onSelectionActionChanged', event);
  }

  onVoiceoverSettingsChanged(settings: any) {
    console.log('onVoiceoverSettingsChanged', settings);
  }

  async outpaint(index: number = 0) {
    this.media.live_preview = null;
    this.view.mode = 'edit';

    return this.inpaint(index, 'outpaint');
  }

  parseAndExtractAttributes(attributes: any[] = []) {
    const hiddenKeys: string[] = ['aspect_ratio', 'audio_transcription', 'caption', 'mask', 'segmentation', 'height', 'width'];

    let attributesConfig: any = {};

    if (!!attributes && !!attributes.length) {

      // prepare attributes config
      attributes.forEach((attribute: any) => {
        attributesConfig[attribute.key] = (!!attribute.value && !!attribute.value[0] && !!attribute.value[0].title ? attribute.value[0].title : (!!attribute.value[0] ? attribute.value[0] : attribute.value));
      });

      // filter attributes list then
      attributes = attributes.filter((attribute: any) => {
        return hiddenKeys.indexOf(attribute.key) === -1;
      });
    }

    this.view.attributesConfig = attributesConfig;

    this.aiSettings.height = parseInt(`${attributesConfig.height || 0}`);
    this.aiSettings.width = parseInt(`${attributesConfig.width || 0}`);

    return attributes;
  }

  removeBackground() {
    this.view.loading = true;

    this.aiWorker.removeBackground(this.media.guid || this.media.thumbnail)
      .then((response: any) => {
        this.view.ai_tool_selection = null;
        this.view.loading = false;

        const url: string = response.url || response.canvas.toDataURL('image/png');

        if (!!url) {
          this.media.live_preview = url;
          this.view.mode = 'edit';
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);

        this.view.ai_tool_selection = null;
        this.view.loading = false;
      });
  }

  runAiPrompt(index: number = 0) {
    let history: aiExecutionHistoryItem[] = [];

    // calculate history
    if (!!this.view.history && !!this.view.history.length) {
      history = this.view.history.map((historyItem: any) => {
        return {
          input: `${(historyItem.input || historyItem.thumbnail) || historyItem.guid}`,
          role: (historyItem.role || 'user'),
        };
      });
    }

    if (!!this.view.history[index] && (this.view.history[index].mode === 'inpaint')) {
      // run history item inpainting if mode is inpaint
      return this.runHistoryItemInpainting(index);
    } else
      if (!!this.view.history[index] && (this.view.history[index].mode === 'outpaint')) {
        // run history item outpainting if mode is outpaint
        return this.runHistoryItemOutpainting(index);
      }

    // else, run global img2img
    this.view.loading = true;

    setTimeout(() => {
      this.scrollDown();
    }, 100);

    const aiParams: any = {
      history: history,
      mask_image: (!!this.view.inpainting_send_mask ? this.view.attributesConfig.mask : null),
      post_content: `${this.view.aiPrompt || ''}`,
      /*
      urls: history.map((historyItem: any) => {
        return `${(historyItem.input || historyItem.thumbnail) || historyItem.guid}`;
      }),
      */
    };

    console.log('global img2img aiParams', aiParams);

    this.sd.imageToImage(this.media.thumbnail || this.media.guid, aiParams)
      .then((response: any) => {
        this.view.loading = false;

        // add ai input to history if set
        if (!!this.view.aiPrompt) {
          history.push({
            input: `${this.view.aiPrompt || ''}`,
            role: 'user',
          });
        }

        if (!!response && !!response.error_message) {
          this.events.publish('error', response.error_message);
        } else
          if (!!response && !!response.images) {

            if (!!response.images && (response.images.length > 1)) {
              this.addResponseImagesToHistory(response.images);
            } else
              if (!!response.images && !!this.view.history[index]) {
                this.view.history[index].live_preview = response.images[0];
              }

            this.view.aiPrompt = '';
          }

        setTimeout(() => {
          this.scrollDown();
        }, 100);

      })
      .catch((error: any) => {
        this.view.loading = false;
        this.events.publish('error', error);
      });
  }

  runHistoryItemInpainting(index: number, mode: string = 'inpaint') {
    this.view.history[index].loading = true;

    const inputImage: string = `${this.view.history[index].guid}`;
    let maskImage: any | null = null;

    // try creating new mask
    if (mode === 'inpaint') {
      maskImage = this.exportCanvasMask(index) || this.view.attributesConfig.mask;
    }

    // apply existing mask otherwise
    if (!maskImage && !!this.view.attributesConfig.mask && !!this.view.attributesConfig.mask.length) {
      maskImage = this.view.attributesConfig.mask;
    }

    if (!inputImage || !inputImage.length) {
      console.warn('missing input image');
      return false;
    } else
      if (mode === 'inpaint' && (!maskImage || !maskImage.length)) {
        console.warn('missing mask image');
        return false;
      }

    const aiParams: any = {
      mask_image: maskImage,
      post_content: `${this.view.aiPrompt || ''}`,
    };

    const methodName: string = (mode === 'inpaint' ? 'imageToImage' : mode);

    this.sd[methodName](inputImage, aiParams)
      .then((response: any) => {
        this.view.history[index].loading = false;

        if (!!response && !!response.error_message) {
          this.events.publish('error', response.error_message);
        } else
          if (!!response && !!response.images && !!response.images[0]) {
            this.view.history[index].live_preview = response.images[0];
          }
      })
      .catch((error: any) => {
        this.view.history[index].loading = false;
        this.events.publish('error', error);
      });
  }

  runHistoryItemOutpainting(index: number) {
    return this.runHistoryItemInpainting(index, 'outpaint');
  }

  scrollDown() {
    try {
      this.preview.nativeElement.scrollTop = this.preview.nativeElement.scrollHeight;
    } catch (e) {
      console.warn('scroll down failed', e);
    }
  }

  submitTag() {

    this.media.tags.push({
      title: `${this.view.newTagName}`,
    });

    this.view.newTagName = '';
    this.addTag();
  }

  syncComments() {

  }

  toggleCard(cardName: string) {

    if (!this.cards[cardName]) {
      this.cards[cardName] = {};
    }

    this.cards[cardName].open = !this.cards[cardName].open;

    this.sidebar.setCards(this.cards);
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  unbindKeyEvents() {
    window.removeEventListener('keydown', this.onKeyDown, false);
  }

  update(blDismiss: boolean = true) {
    //this.media.user = this.user.getUid();

    if (!!this.media && !!this.media.title) {
      this.media.post_title = this.media.title;
    }

    delete this.media.live_preview;

    this.mediaService.update(this.media)
      .then(() => {
        if (!!blDismiss) {
          this.dismiss(null, 'done');
        } else {
          this.doRefresh();
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  useAsPrimary(item: any, index: number) {
    console.log('useAsPrimary', item);

    this.view.history = [];

    setTimeout(() => {
      this.zone.run(async () => {
        try {

          if (!!item.live_preview) {
            item.photo = item.live_preview;
            item.guid = item.live_preview;
          }

          this.view.history = JSON.parse(JSON.stringify([item]));
          this.scrollDown();

          await this.update(false);

          this.view.mode = 'view';
          item.mode = 'view';

          await this.analyseMedia();

          this.slider.nativeElement.reset();

        } catch (e) {
          console.warn('reseting slider failed', e);
        }
      });
    }, 100);
  }

  variationChanged() {
    switch (this.media.post_mime_type) {
      case 'image':
        this.media.thumbnail = `${this.media.guid}`;
        break;
      case 'video':
        this.media.videoSrc = `${this.media.guid}`;
        break;
    }
  }

  viewMask(maskUrl: string | null = null) {

    if (!maskUrl && (!this.view.attributesConfig || !this.view.attributesConfig.mask)) {
      return false;
    }

    this.browser.create(maskUrl || this.view.attributesConfig.mask);
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();

    if (!!this.view.expertMode) {
      this.loadExpertSidebarData();
    }

  }

  viewVariation(url: string) {

    if (!url) {
      return false;
    }

    this.view.backupGuid = `${this.media.guid || ''}`;

    this.zone.run(() => {
      this.media.live_preview = url;

      if (!!this.view.history[0]) {
        this.view.history[0].live_preview = url;
      }

      try {
        this.slider.nativeElement.reset();
      } catch (e) {
        console.warn('reseting slider failed', e);
      }
    });
  }

}