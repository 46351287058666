import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingController } from '@ionic/angular';

import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from "src/app/services/core/modal.service";
import { ProjectsService } from 'src/app/services/core/projects.service';
import { ViewService } from 'src/app/services/core/view.service';
import { ShopService } from 'src/app/services/ecommerce/shop.service';
import { CountriesService } from 'src/app/services/geo/countries.service';
import { DaniService } from 'src/app/services/getgenius/dani.service';
import { MailCrawlerService } from "src/app/services/integrations/mail-crawler.service";
import { CreatorsService } from 'src/app/services/pipeline/creators.service';
import { SharingService } from "src/app/services/sharing/sharing.service";
import { CommentsService } from "src/app/services/social/comments.service";
import { BrowserService } from "src/app/services/utils/browser.service";
import { ChooserService } from 'src/app/services/utils/chooser.service';
import { ClipboardService } from "src/app/services/utils/clipboard.service";
import { ExportService } from 'src/app/services/utils/export.service';
import { FoldersService } from 'src/app/services/utils/folders.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { ToolsService } from "src/app/services/utils/tools.service";

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';
import { SelectionOptionsPickerComponent } from 'src/app/components/generic/selection/selection-options-picker/selection-options-picker.component';

import { ViewMediaPage } from 'src/app/pages/core/media/media/view-media/view-media.page';
import { CreateObjectPage } from 'src/app/pages/core/objects/create-object/create-object.page';
import { ShopProductPage } from 'src/app/pages/extensions/shop/shop-product/shop-product.page';
import { MailCrawlerPage } from '../mail-crawler/mail-crawler.page';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mail-inbox',
  standalone: false,
  templateUrl: './mail-inbox.page.html',
  styleUrls: ['./mail-inbox.page.scss'],
})
export class MailInboxPage implements OnInit {
  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;

  appConfig: pipelineAppConfig;

  articleSelectionOptions: selectionOption[] = [
    {
      icon: 'search-outline',
      label: 'analyze',
      uid: 'analyse',
    },
    {
      icon: 'sync-outline',
      label: 'crawl_article_data',
      uid: 'crawl_article_data',
    },
    {
      icon: 'hardware-chip-outline',
      label: 'add_to_training',
      uid: 'add_to_training',
    },
    {
      color: 'danger',
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    }
  ];

  cards: any = {
    answer: { open: true },
    articles: { open: true },
    attachments: { open: true },
    attributes: { open: false },
    item_attributes: { open: false },
    request: { open: true },
    supplier_requests: { open: true },
    tags: { open: false },
  };

  cta: any = {
    handler: () => {
      this.connect();
    },
    icon: 'link-outline',
    label: 'connect',
  };

  ctaProducts: any = { icon: 'add-outline', label: 'add', url: '/shop/products' };

  detailItem: mailInboxMessage;

  fallbackImg: string = './assets/img/fallback.webp';

  @ViewChild('headerPopover') headerPopover;

  iInbox: number;

  inbox: mailInboxMessage[];
  inbox_backup: mailInboxMessage[];

  introCard: introCardConfig = {
    uid: 'mail_inbox_top_card',
    text: 'mail_inbox_top_card_text',
    title: 'mail_inbox_top_card_title',
  };

  isHeaderPopoverOpen: boolean = false;

  mailSelectionOptions: selectionOption[] = [
    {
      icon: 'list-outline',
      label: 'apply_rules',
      uid: 'apply_rules',
    },
    {
      icon: 'search-outline',
      label: 'analyze',
      uid: 'analyse',
    },
    {
      icon: 'sync-outline',
      label: 'crawl_article_data',
      uid: 'crawl_article_data',
    },
    {
      icon: 'text-outline',
      label: 'generate_auto_reply',
      uid: 'generate_auto_reply',
    },
    {
      icon: 'document-text-outline',
      label: 'generate_supplier_request',
      uid: 'generate_supplier_request',
    },
    /*
    {
      color: 'warning',
      icon: 'send-outline',
      label: 'auto_reply',
      uid: 'auto_reply',
    },
    */
    {
      icon: 'folder-outline',
      label: 'move_folder',
      uid: 'move_folder',
    },
    /*
    {
      icon: 'briefcase-outline',
      label: 'move_project',
      uid: 'move_project',
    },
    {
      icon: 'hardware-chip-outline',
      label: 'add_to_training',
      uid: 'add_to_training',
    },
    */
    {
      color: 'danger',
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    }
  ];

  messageId: number;

  pageLimit: number = 10;
  pageOffset: number = 0;

  paginationConfig: paginationConfig = {
    itemsKey: 'inbox',
    limit: 250,
  };

  selectionOptions: selectionOption[] = [];

  search: searchOptions = {
    itemsKey: 'inbox',
    keys: ['timestamp', 'name', 'answer', 'email', 'value'],
    query: '',
  };

  @ViewChild(SelectionOptionsPickerComponent) selectionOptionsPicker: any;

  state: state = {};

  subs = new Subscription();

  view: any = {
    filters: [
      {
        icon: 'person-outline',
        multiple: true,
        name: 'sender',
        type: 'select',
        uid: 'sender',
        values: [],
      },
      {
        icon: 'finger-print-outline',
        multiple: true,
        name: 'project_uid',
        type: 'select',
        uid: 'project_uid',
        values: [],
      },
      {
        icon: 'list-outline',
        multiple: true,
        name: 'article',
        type: 'select',
        uid: 'article',
        values: [],
      },
      {
        icon: 'construct-outline',
        multiple: true,
        name: 'manufacturer',
        type: 'select',
        uid: 'manufacturer_uid',
        values: [],
      },
      {
        icon: 'business-outline',
        multiple: true,
        name: 'supplier',
        type: 'select',
        uid: 'supplier_uid',
        values: [],
      },
      {
        icon: 'arrow-back-outline',
        name: 'start_date',
        type: 'date',
        uid: 'start_date',
      },
      {
        icon: 'arrow-forward-outline',
        name: 'end_date',
        type: 'date',
        uid: 'end_date',
      },
      {
        icon: 'cash-outline',
        max: 99999,
        min: 0,
        name: 'order_volume',
        type: 'range',
        value: [0, 99999],
        uid: 'order_volume',
      }
    ],
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    itemSize: 96,
    itemsKey: 'inbox',
    key: ['title', 'description', 'name', 'url', 'uid'],
    multiple: true,
    options: {},
    showMenuButton: true,
    showProjectsSelect: true,
    title: 'mail_inbox',
  };

  constructor(
    private browser: BrowserService,
    private chooser: ChooserService,
    private comments: CommentsService,
    private configService: ConfigService,
    private clipboard: ClipboardService,
    private countries: CountriesService,
    private creators: CreatorsService,
    private dani: DaniService,
    private events: EventsService,
    private exportService: ExportService,
    private folders: FoldersService,
    private loadingController: LoadingController,
    public mail: MailCrawlerService,
    private modalService: ModalService,
    private projects: ProjectsService,
    private route: ActivatedRoute,
    private sharing: SharingService,
    private shop: ShopService,
    private sidebar: SidebarService,
    private tools: ToolsService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();

    this.messageId = parseInt(this.route.snapshot.paramMap.get('messageId'));
  }

  async addArticleToMail(item: mailInboxMessage) {
    console.log('addArticleToMail: item', item);

    const loading = await this.loadingController.create();
    loading.present();

    const products: any = await this.shop.getProducts();
    console.log('addArticleToMail: products', products);

    loading.dismiss();

    if (!products || !products.data || !products.data.length) {
      return false;
    }

    const chooseConfig: chooseConfig = {
      data: products.data,
      labelKey: 'name',
      multiple: true,
      service: this,
      valueKey: 'uid',
    };

    this.chooser.choose(chooseConfig)
      .then((chooseResponse: chooseResponse) => {
        console.log('chooseResponse', chooseResponse);
      });
  }

  async add_to_training(options: any) {

    const countriesData: any[] = (this.view.countries || []).map((item: any) => {
      return { name: item.name, uid: item.uid, };
    }).filter((item: any) => {
      return (item.uid === options.new) || (item.uid === options.old);
    });

    const manufacturersData: any[] = (this.view.manufacturers || []).map((item: any) => {
      return { name: item.name, uid: item.uid, };
    }).filter((item: any) => {
      return (item.uid === options.new) || (item.uid === options.old);
    });

    let history: aiExecutionHistoryItem[] = [];

    switch (options.key) {
      case 'country_uid':
        history.push({
          role: 'user',
          content: `IMPORTANT FOR MAPPING: Countries: ${JSON.stringify(countriesData)}`,
        });
        break;
      default:
        history.push({
          role: 'user',
          content: `IMPORTANT FOR MAPPING: Suppliers / Manufacturers: ${JSON.stringify(manufacturersData)}`,
        });
        break;
    }

    this.dani.requestLearn({
      contexts: [
        'text_classification',
      ],
      history: history,
      input: options.input,
      output: options.output,
      tools: [
        'mail_inbox',
        'shop_products',
      ],
    })
      .then((response: any) => {
        console.log('learning response (mail-inbox)', response);
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  analyse(item: any) {
    return this.calcItemAttributes(item);
  }

  analyseSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.tools.bulk({
      action: 'calcItemAttributes',
      items: this.view.selectedItems,
      onItemResponse: (response: any, item: any) => {
        if (!!item && !!item.uid) {
          item.loading = false;
        }
      },
      service: this,
      useObjectAsIdentifier: true,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  auto_reply(item: mailInboxMessage) {
    console.log('auto_reply: item', item);
  }

  autoReplySelectedItems() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.view.loading = true;

    this.tools.bulk({
      action: 'auto_reply',
      items: this.view.selectedItems,
      service: this,
      useObjectAsIdentifier: true,
    })
      .then(() => {
        this.view.loading = false;
        this.doRefresh();
      })
      .catch((error: any) => {
        this.view.loading = false;
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  calcAttributesBasedFilters() {
    let projects: any[] = [],
      projectUids: any[] = [];

    if (!!this.inbox && !!this.inbox.length) {
      this.inbox.forEach((item: mailInboxMessage) => {

        // parse attributes
        if (!!item.attributes && !!item.attributes.length) {
          item.attributes.forEach((attribute: any) => {
            if (!!attribute.value && !!attribute.value.length && (typeof attribute.value === 'object')) {
              attribute.value.forEach((value: any) => {
                if ((attribute.key === 'project_uid' || attribute.key === 'project_id') && !!value && !!value.title && (projectUids.indexOf(value.title) === -1)) {
                  projects.push({ label: value.title, uid: value.title });
                  projectUids.push(value.title);
                }
              });
            } else
              if ((attribute.key === 'project_uid' || attribute.key === 'project_id') && !!attribute.value && (projectUids.indexOf(parseInt(attribute.value)) === -1)) {
                projects.push({ label: attribute.value, uid: attribute.value });
                projectUids.push(attribute.value);
              }
          });
        }
      });
    }

    this.view.filters[1].values = projects;
  }

  calcColSizes() {
    this.view.colSize = this.view.colSize || {};
    this.view.colSize.left = this.view.sidebarSize || (window.innerWidth > 768 ? 3 : 12);

    this.view.colSize.right = (!!this.view.colSize.left && (this.view.colSize.left < 12) ?
      (12 - (this.view.colSize.left * (!!this.view.expertMode ? 2 : 1))) :
      (window.innerWidth > 768 && !!this.view.expertMode ? 6 : 12)
    );
  };

  async calcCountries() {
    try {
      if (!this.view.hasOwnProperty('countries')) {
        const allCountries: any = await this.countries.getCountries();
        this.view.countries = (allCountries || []);
      }
    } catch (e) {
      console.warn('calculating countries failed', e);
    }
  }

  calcFilters() {

    // senders
    this.view.filters[0].value = [];
    this.view.filters[0].values = [];

    // project
    this.view.filters[1].value = [];
    this.view.filters[1].values = [];

    // articles
    this.view.filters[2].value = [];
    this.view.filters[2].values = [];

    // manufacturer
    this.view.filters[3].value = [];
    this.view.filters[3].values = [];

    // supplier
    this.view.filters[4].value = [];
    this.view.filters[4].values = [];

    let articles: any[] = [],
      articleNames: string[] = [],
      manufacturers: any[] = [],
      manufacturerUids: number[] = [],
      suppliers: any[] = [],
      supplierUids: number[] = [],
      emails: string[] = [];

    if (!!this.inbox && !!this.inbox.length) {
      this.inbox.forEach((item: mailInboxMessage) => {

        // emails for sender
        if (!!item.email && (emails.indexOf(item.email) === -1)) {
          emails.push(item.email);
        }

        // parse articles
        if (!!item.articles && !!item.articles.length) {
          item.articles.forEach((article: any) => {

            if (!!article.name && (articleNames.indexOf(article.name) === -1)) {
              articleNames.push(article.name);
              articles.push(Object.assign(article, { label: article.name, uid: (article.uid || article.name) }));
            }

            if (!!article.manufacturer_uid && (manufacturerUids.indexOf(article.manufacturer_uid) === -1)) {
              manufacturerUids.push(article.manufacturer_uid);
              manufacturers.push({ label: (article.manufacturer || article.supplier), uid: article.manufacturer_uid });
            }

            if (!!article.supplier_uid && (supplierUids.indexOf(article.supplier_uid) === -1)) {
              supplierUids.push(article.supplier_uid);
              suppliers.push({ label: (article.supplier || article.manufacturer), uid: article.supplier_uid });
            }

          });
        }
      });
    }

    const senders: any[] = emails.map((email: string) => {
      return { label: email, uid: email };
    });

    // senders
    //this.view.filters[0].value = emails;
    this.view.filters[0].values = senders;
    this.view.senders = senders;

    // articles
    //this.view.filters[2].value = articleNames;
    this.view.filters[2].values = articles;

    // manufacturers
    //this.view.filters[3].value = manufacturerUids;
    this.view.filters[3].values = manufacturers;

    // suppliers
    //this.view.filters[4].value = supplierUids;
    this.view.filters[4].values = suppliers;

  }

  async calcItemAttributes(item: any) {
    item.description = item.description || item.value;
    item.loadingAttributes = true;
    item.location = 'mail_inbox';
    item.rebuild = true;
    item.type = 'mail_inbox';

    try {

      const response: any = await this.comments.analyseComments({
        history: [
          {
            role: 'system',
            content: 'Make sure to ALWAYS detect OR generate a new, unique project id!',
          },
        ],
        items: [item],
        platform: 'mail',
      });

      console.log('calcItemAttributes: response', response);

      if (!!response && !!response.results && !!response.results[0] && !!response.results[0].info) {
        item.info = response.results[0].info;
      }

      setTimeout(() => {
        item.loadingAttributes = false;
        item.rebuild = false;

        return response;
      });
    } catch (error: any) {
      item.loadingAttributes = false;
      item.rebuild = false;

      if (!!error) {
        this.events.publish('error', error);
      }

      return false;
    }
  }

  calcSelectionActions() {

  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.calcColSizes();
  }

  copyToClipboard(string: string) {
    this.clipboard.copyText(`${string}`);
  }

  async connect() {

    const modal: any = await this.modalService.create({
      component: MailCrawlerPage,
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal',
    });

    modal.onWillDismiss().then((data: any) => {
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  crawl_article_data(item: any) {
    return this.fetchArticles(item);
  }

  crawlSelectedItemsArticlesData() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.tools.bulk({
      action: 'crawl_article_data',
      items: this.view.selectedItems,
      onItemResponse: (response: any, item: any) => {
        if (!!item && !!item.uid) {
          item.loading = false;
        }
      },
      service: this,
      useObjectAsIdentifier: true,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  delete(item: mailInboxMessage, event: any | null = null) {

    if (!item || !item.uid) {
      return false;
    }

    this.mail.deleteInboxMessage(item.uid)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  deleteSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.tools.bulk({
      action: 'deleteInboxMessage',
      items: this.view.selectedItems,
      onItemResponse: (response: any, item: any) => {
        if (!!item && !!item.uid) {
          item.hidden = true;
          item.loading = false;
        }
      },
      service: this.mail,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  doRefresh(event: any | null = null, blBackground: boolean = false) {
    this.load(true, blBackground)
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
        this.runSearch();
      })
      .catch((error: any) => {
        if (!!event) {
          event.target.complete();
        }
        this.events.publish('error', error);
      });
  }

  expandList(listName: string) {
    this.view.expandedList = listName;
  }

  export() {
    this.exportService
      .setData(this.inbox)
      .setItemParser((item: any) => {
        console.log('item', item);
        return {
          uid: item.uid,
          active: item.active,
          email: item.email,
          name: item.name,
          url: item.url,
          timestamp: item.timestamp,
        };
      })
      .asCsv()
      .download();
  }

  async fetchArticles(item: mailInboxMessage) {
    item.loadingArticles = true;

    try {

      const response: any = await this.mail.extractArticles({
        item: item,
        suppliers: (this.view.suppliers || []).map((supplier: any) => {
          return { uid: supplier.uid, name: supplier.name };
        }),
      }, true);

      item.loadingArticles = false;

      if (!!response && !!response.hasOwnProperty('articles')) {
        // apply parsed articles if provided

        if (!response.articles || !response.articles.length) {
          console.warn('> response.articles is wrong', response.articles);
        }

        item.articles = (response.articles || []);
      } else
        if (!!response && !!response.output) {
          // else, parse articles manually

          response.output = this.tools.trim(response.output.replace('```json', '').replace('```', ''));

          const json: any | null = (!!response && !!response.output ? this.tools.extractJson(response.output) : null);
          console.log('mail-inbox: json', json);

          item.articles = json;
          return item.articles;
        }
    } catch (e) {
      console.warn('fetch error', e);

      item.loadingArticles = false;

      this.events.publish('error', e || 'error_analyzing_mail_content_failed');

      return false;
    }
  }

  filterBy(key: string, value: any) {
    let blFilterByKey: boolean = false;
    this.view.rebuild = true;

    if (typeof value === 'string') {
      value = [value];
    }

    switch (key) {
      case 'email':
        this.view.filters[0].value = value;
        blFilterByKey = true;
        break;
    }

    if (!!blFilterByKey && !!this.inbox_backup && !!this.inbox_backup.length) {

      this.inbox = this.inbox_backup.filter((message: any) => {
        return (!value || !value.length) || (value.indexOf(message[key]) !== -1);
      });

      if (!!this.paginationConfig) {
        this.paginationConfig.backup = this.inbox;
      }
    }

    setTimeout(() => {
      this.view.rebuild = false;
    });
  }

  async generateAnswer(item: mailInboxMessage) {

    if (!item) {
      return false;
    }

    console.log('generateAnswer: item', item);

    let articles: any[] | null = (item.hasOwnProperty('articles') && !!item.articles ? item.articles : null);
    console.log('generateAnswer: articles', articles);

    if (!item.hasOwnProperty('info')) {
      this.calcItemAttributes(item);
    }

    if (!articles || !articles.length) {
      try {
        const fetch: any = await this.fetchArticles(item);

        if (!!fetch && !!fetch.length) {
          articles = fetch;
        }
      } catch (e) {
        console.warn('fetching articles failed', e);
      }
    }

    item.articles = articles;
    item.loading = true;

    this.mail.createMailReplyMessage({
      articles: articles,
      item: item,
    }, true)
      .then((response: any) => {
        console.log('createMailReplyMessage: response', response);

        item.loading = false;

        if (!!response && !!response.output) {
          item.answer = this.tools.nl2br(`${response.output}`).replace('<br>', '<br /><br>');
          console.log('item.answer', `${item.answer}`);
        }
      })
      .catch((error: any) => {
        item.loading = false;
        this.events.publish('error', error);
      });
  }

  generate_auto_reply(item: mailInboxMessage) {
    return this.generateAnswer(item);
  }

  generateAutoReplyForSelectedItems() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.view.loading = true;

    this.tools.bulk({
      action: 'generateAnswer',
      items: this.view.selectedItems,
      service: this,
      useObjectAsIdentifier: true,
    })
      .then(() => {
        this.view.loading = false;
        this.doRefresh();
      })
      .catch((error: any) => {
        this.view.loading = false;
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  generateSupplierRequestsForSelectedItems() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.view.loading = true;

    this.tools.bulk({
      action: 'generateSupplierRequest',
      items: this.view.selectedItems,
      service: this,
      useObjectAsIdentifier: true,
    })
      .then(() => {
        this.view.loading = false;
        this.doRefresh();
      })
      .catch((error: any) => {
        this.view.loading = false;
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  async generateSupplierRequest(item: mailInboxMessage) {
    console.log('generateSupplierRequest: item', item);
  }

  generate_supplier_request(item: mailInboxMessage) {
    return this.generateSupplierRequest(item);
  }

  importEntries() {

  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', (project: project) => {
      this.view.project = project;
      this.doRefresh();
    });

    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);
      this.calcColSizes();
    });
  }

  initRefresher() {
    this.stopRefresher();

    this.view.refresherInterval = setInterval(() => {
      this.doRefresh(null, true);
    }, (30 * 1000));
  }

  ionViewWillEnter() {
    this.initEvents();

    // for auto-refresh:
    //this.initRefresher();
  }

  ionViewWillLeave() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }

    this.stopRefresher();
  }

  learnInputChanged(messageIndex: number, articleIndex: number, key: string, event: any | null = null) {

    if (!this.inbox.hasOwnProperty(messageIndex)) {
      console.warn('Message not found in inbox, no learning possible');
      return false;
    }

    const message: any = this.inbox[messageIndex];

    if (!message.articles || !message.articles.hasOwnProperty(articleIndex)) {
      console.warn('Message has no articles, no learning possible');
      return false;
    }

    const backupMessage: any = this.inbox_backup[messageIndex];
    const article: any = message.articles[articleIndex];

    let backupArticle: any = JSON.parse(JSON.stringify(backupMessage.articles[articleIndex]));
    let oldValue: any = backupArticle[key], newValue: any = article[key];

    /*
    const nameKey = key.replace('_uid', '');

    if(!!backupArticle[nameKey]) {
      oldValue = `${oldValue} (${backupArticle[nameKey]})`;
    }

    if(!!backupArticle[nameKey]) {
      newValue = `${newValue} (${article[nameKey]})`;
    }
    */

    if (!!newValue && (newValue !== oldValue)) {
      delete backupArticle.country;
      delete backupArticle.manufacturer;
      delete backupArticle.matching_storage_items;
      delete backupArticle.photo;
      delete backupArticle.supplier;

      let trainingData: any = {
        input: JSON.stringify(backupArticle),
        key: key,
        new: newValue,
        old: oldValue,
      };

      if (!!oldValue) {
        trainingData.old = oldValue;
        trainingData.output = `Key: ${key}\nWrong: ${oldValue}\nCorrect: ${newValue}`;
      } else {
        trainingData.output = `Key: ${key}\nCorrect value: ${newValue}`;
      }

      this.add_to_training(trainingData);
    }
  }

  load(blForceRefresh: boolean = false, blBackground: boolean = false) {
    return new Promise((resolve, reject) => {
      this.loadInbox({}, blForceRefresh, blBackground)
        .then(async () => {
          try {
            await this.loadFolders(blForceRefresh)
            await this.loadCrawlers(blForceRefresh);

            resolve(this.view);
          } catch (e) {
            console.warn('loading crawlers failed', e);
            reject(e);
          }
        })
        .catch(reject);
    });
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  loadCrawlers(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      await this.loadProject();

      this.view.connections = [];

      try {
        const sharingConnections: any = await this.sharing.getConnections(blForceRefresh);

        if (!!sharingConnections) {
          this.view.connections = sharingConnections.map((connection: integrationConnection) => {
            connection.checked = true;
            return connection;
          });
        }
      } catch (e) {

      }

      if (!this.view.project || !this.view.project.uid) {
        resolve(this.view);
      } else {
        this.view.loadingConnections = true;

        this.mail.getCrawlers(this.view.options, blForceRefresh)
          .then((response: any) => {

            if (!!response && !!response.length) {
              this.view.connections = (this.view.connections || []).concat(
                response.map((connection: integrationConnection) => {
                  connection.checked = true;
                  return connection;
                })
              );
            } else
              if (response.items && !!response.items.length) {
                this.view.connections = (this.view.connections || []).concat(
                  response.items.map((connection: integrationConnection) => {
                    connection.checked = true;
                    return connection;
                  })
                );
              }

            this.view.loadingConnections = false;

            resolve(this.view);
          })
          .catch((error: any) => {
            this.view.loadingConnections = false;
            reject(error);
          });
      }
    })
  }

  loadFolders(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      this.view.project = await this.projects.getCurrent();

      if (!this.view.project || !this.view.project.uid) {
        this.view.loadingFolders = false;
        return false;
      } else {
        this.view.loadingFolders = true;

        this.mail.getFolders(this.view.options, blForceRefresh, { limit: 1000 })
          .then((folders: folder[]) => {
            this.view.loadingFolders = false;

            this.view.folders = (folders || [])
              .map((folder: folder) => {
                folder.active = !!(!!this.view.currentFolder && (this.view.currentFolder.uid === folder.uid));
                return folder;
              })
              .sort((a: any, b: any) => {

                const _a: string = `${a.title}`.toLowerCase(),
                  _b: string = `${b.title}`.toLowerCase();

                if (_a < _b) return -1;
                if (_b > _a) return 1;

                return 0;
              });

            resolve(folders);
          })
          .catch((error: any) => {
            this.view.loadingFolders = false;
            reject(error);
          });
      }

    });
  }

  loadInbox(options: any = {}, blForceRefresh: boolean = false, blBackground: boolean = false, blReturnOnly: boolean = false) {
    return new Promise(async (resolve, reject) => {

      // load project if missing
      if (!this.view.project || !this.view.project.uid) {
        await this.loadProject();
      }

      if (!blBackground && !blReturnOnly) {
        this.inbox = [];
      }

      // if project still not set, prevent loading
      if (!this.view.project || !this.view.project.uid) {
        resolve(this.inbox);
      } else {

        if (!blBackground) {
          this.view.loading = true;
          this.view.loadingFilters = true;
        }

        this.view.options = this.view.options || {};

        let params: any = Object.assign(options || {}, JSON.parse(JSON.stringify(this.view.options.filter || {})));
        params.limit = params.limit || this.pageLimit;
        params.page = params.offset || this.pageOffset;

        delete params.location;
        delete params.source;

        this.mail.getInbox(params, blForceRefresh)
          .then((response: any) => {
            this.view.loading = false;

            let inbox: any[];

            if (!!response && response.hasOwnProperty('items')) {
              inbox = (response.items || []).map((item: any) => {
                item.answer = item.answer || '';
                item.value = this.tools.nl2br(`${item.value || ''}`);

                return item;
              });
            } else {
              inbox = (response || []);
            }

            if (!blReturnOnly) {
              this.inbox = inbox;
              this.inbox_backup = JSON.parse(JSON.stringify(this.inbox));

              console.log('inbox: ', this.inbox);

              this.calcColSizes();
              this.calcCountries();
              this.calcFilters();

              this.view.loadingFilters = false;

              setTimeout(() => {
                this.calcAttributesBasedFilters();
              }, 3500);
            }

            resolve(inbox);
          })
          .catch((error: any) => {
            this.view.loading = false;
            this.view.loadingFilters = true;

            reject(error);
          });
      }
    })
  }

  loadMailsByFolder(event: any | null = null) {
    if (!event) {
      this.doRefresh();
    }
  }

  loadNext(event: any) {
    this.pageOffset++;

    //let iCountBefore = !!this.inbox && !!this.inbox.length ? this.inbox.length : 0;

    this.loadInbox({}, true, true, true)
      .then((response: any) => {
        event.target.complete();

        if (!!response && !!response.length) {
          this.inbox = this.inbox.concat(response);
          this.inbox_backup = JSON.parse(JSON.stringify(this.inbox));

          this.calcCountries();
          this.calcFilters();
        }
      })
      .catch((error: any) => {
        event.target.complete();
        this.events.publish('error', error);
      });
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  loadShops(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      await this.loadProject();

      if (!this.view.project || !this.view.project.uid) {
        this.view.manufacturers = [];
        resolve(this.view);
      } else {
        this.view.loadingSuppliers = true;

        this.creators.getShops(blForceRefresh)
          .then((shops: shopSource[]) => {

            this.view.suppliers = (shops || []).map((connection: integrationConnection) => {
              connection.checked = true;
              return connection;
            });

            this.view.manufacturers = JSON.parse(JSON.stringify(this.view.suppliers));

            this.view.loadingSuppliers = false;

            this.loadFolders(blForceRefresh);

            resolve(shops);
          })
          .catch((error: any) => {
            this.view.loadingSuppliers = false;
            this.events.publish('error', error);
          });
      }
    });
  }

  moveFolder(item: mailInboxMessage) {
    this.folders.moveFolder([item.uid], 'mail_inbox')
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  move_folder(item: mailInboxMessage) {
    return this.moveFolder(item);
  }

  moveSelectedFolders() {

    const itemIds: number[] = this.view.selectedItems.map((item: mailInboxMessage) => {
      return item.uid;
    });

    console.log('moveSelectedFolders: itemIds', itemIds);

    this.folders.moveFolder(itemIds, 'mail_inbox')
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  moveSelectedProjects() {

  }

  ngOnInit() {
    this.calcViewVars();
    this.loadCards();

    // reset some init states for cards:
    this.cards = this.cards || {};
    this.cards.attributes = this.cards.attributes || {};
    this.cards.attributes.open = false;
    this.cards.item_attributes = this.cards.item_attributes || {};
    this.cards.item_attributes.open = false;

    this.load()
      .then(() => {

        // open active message by uid if set
        if (!!this.messageId && !!this.inbox && !!this.inbox.length) {
          this.inbox.forEach((_message: any, _i: number) => {
            if (_message.uid === this.messageId) {
              this.open(_message, _i);
            }
          });
        }

        this.calcColSizes();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });

    this.loadShops();
  }

  onArticleItemChecked(article: any) {

  }

  async onArticleMatchClick(match, article, index: number = 0) {
    console.log('onArticleMatchClick: article', article);
    console.log('onArticleMatchClick: match', match);

    let page: any = ShopProductPage;

    let props: any = {
      index: index,
      product: match,
      products: article.matching_storage_items,
    };

    if (match.type === 'object') {
      page = CreateObjectPage;
      props = {
        item: match.value,
        type: {
          uid: match.object_type,
        },
      };
    }

    console.log('onArticleMatchClick: props', props);

    const modal: any = await this.modalService.create({
      component: page,
      componentProps: props,
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal',
    });

    modal.onWillDismiss().then((data: any) => {
      //this.doRefresh();
    });

    this.modalService.present(modal);
  }

  async onAttachmentClick(attachment: any) {

    if (!attachment || (!attachment.url && !attachment.guid)) {
      return false;
    }

    if (!attachment.ID) {
      this.browser.create(`${attachment.url || attachment.guid}`);
      return false;
    }

    const modal: any = await this.modalService.create({
      component: ViewMediaPage,
      componentProps: {
        mode: 'view',
        media: attachment,
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    this.modalService.present(modal);
  }

  onAttributeFiltersChanged(event: any | null = null) {

    if (!!this.view.filters && !!this.view.filters.attributes) {
      this.view.options = this.view.options || {};
      this.view.options.filters = this.view.options.filters || {};
      this.view.options.filters.attributes = this.view.filters.attributes;
    }

    this.doRefresh();
  }

  onConnectionsFilterChanged(connections: any[] | null = []) {
    this.view.options = this.view.options || {};
    this.view.options.filters = this.view.options.filters || {};

    this.view.options.filters.connections = (connections || []).map((connection: integrationConnection) => {
      return connection.uid;
    });

    this.doRefresh();
  }

  onFiltersChanged(event: any | null = null) {
    if (!!event && !!event[0] && !!event[0].value) {
      this.filterBy('email', event[0].value);
    } else {
      this.runSearch();
    }
  }

  onFolderLoaded(items: any[] | null = null) {
    this.view.rebuild = true;
    this.view.loading = true;

    this.inbox = JSON.parse(JSON.stringify((items || [])));

    this.paginationConfig = this.paginationConfig || {};
    this.paginationConfig.backup = this.inbox;

    setTimeout(() => {
      //this.runSearch();
      this.view.rebuild = false;
      this.view.loading = false;
    }, 100);
  }

  onItemChecked(item: mailInboxMessage, iInbox: number) {

    this.view.selectedItems = this.inbox.filter((_item: mailInboxMessage) => {
      return _item.checked;
    });

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);

    this.open(item, iInbox, false);
  }

  onMouseMove(e: any) {
    this.view.dropItem = document.elementFromPoint(e.clientX, e.clientY);
  }

  onSearchChanged(searchOptions: any | null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }

  async onSelectedTagChanged(event: any | null = null) {
    this.view.tag = event;
    //await this.runSearch();
  }

  onSelectionActionChanged(event: any | null = null) {

    if (!event || !event.option || !event.option.uid) {
      return false;
    }

    this.view.selectedItems = (!!event && !!event.item ? [event.item] : (!!event && !!event.items ? event.items : (this.view.selectedItems || [])));

    switch (event.option.uid) {
      case 'analyse':
        this.analyseSelected();
        break;
      case 'auto_reply':
        this.autoReplySelectedItems();
        break;
      case 'crawl_article_data':
        this.crawlSelectedItemsArticlesData();
        break;
      case 'delete':
        this.deleteSelected();
        break;
      case 'generate_auto_reply':
        this.generateAutoReplyForSelectedItems();
        break;
      case 'generate_supplier_request':
        this.generateSupplierRequestsForSelectedItems();
        break;
      case 'move_folder':
        this.moveSelectedFolders();
        break;
      default:
        this.events.publish('error', 'not_implemented');
        break;
    }

  }

  onSizesChange(sizes: any) {
    console.log('onSizesChange: sizes', sizes);
  }

  open(item: mailInboxMessage, iInbox: number, blDisableOthers: boolean = true) {
    item.checked = true;
    item.open = true;

    if (!!blDisableOthers && !!this.inbox && !!this.inbox.length) {
      this.inbox.forEach((entry: any) => {
        if (entry.uid !== item.uid) {
          entry.checked = false;
          entry.open = false;;
        }
      });
    }

    this.detailItem = item;
    this.iInbox = iInbox;
  }

  openArticle(article: any) {

  }

  openURL(url: string) {

    if (!url || (url.indexOf('.') === -1)) {
      return false;
    }

    this.browser.create(url);
  }

  presentArticlePopover(e: Event, item: any) {
    this.selectionOptions = this.articleSelectionOptions;

    try {
      this.selectionOptionsPicker.show({
        event: e,
        item: item,
      });
    } catch (e) {
      this.events.publish('error', e);
    }
  }

  presentMailPopover(e: Event, item: any) {
    this.selectionOptions = this.mailSelectionOptions;

    try {
      this.selectionOptionsPicker.show({
        event: e,
        item: item,
      });
    } catch (e) {
      this.events.publish('error', e);
    }
  }

  async runItemSelectionOption(event) {
    try {
      console.log('mail-inbox: runItemSelectionOption', event);

      if (!event || !event.option || !event.option.uid) {
        return false;
      }

      const exec: any = await this[event.option.uid](event.item);

      this.selectionOptionsPicker.close();
    } catch (e) {
      console.warn('executing single selection on item failed', e);
      this.events.publish('error', e);
    }
  }

  async runSearch() {
    try {

      const exec: any = await this.searchToolbar.runSearch();
      console.log('search exec', exec);

    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

  searchProduct(article: any, iArticle: number) {
    console.log('searchProduct', article);
    article.mode = 'search';
  }

  showHeaderPopover(event: any | null = null) {
    this.headerPopover.event = event;
    this.isHeaderPopoverOpen = true;
  }

  stopRefresher() {
    try {
      if (!!this.view.refresherInterval) {
        clearInterval(this.view.refresherInterval);
      }
    } catch (e) {
      console.warn('stopping mail inbox refresher failed', e);
    }
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
    item.thumbnail = this.fallbackImg;
  }

  toggleActive(item: mailInboxMessage) {
    console.log('toggleActive', item);
  }

  toggleArticleMatches(article, item) {
    if (!!article && !!article.matching_storage_items && !!article.matching_storage_items.length) {
      article.show_matches = !article.show_matches;
    }
  }

  toggleCard(cardName: string) {

    if (!this.cards[cardName]) {
      this.cards[cardName] = {};
    }

    this.cards[cardName].open = !this.cards[cardName].open;

    this.sidebar.setCards(this.cards);
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}