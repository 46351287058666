import { Injectable } from '@angular/core';
import { LoadingController, NavController } from '@ionic/angular';

import { AiPlannerService } from '../ai/ai-planner.service';
import { AiToolsService } from "src/app/services/ai/ai-tools.service";
import { AiWorkerService } from "src/app/services/ai/ai-worker.service";

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { NewslettersService } from '../newsletters/newsletters.service';
import { ParamsService } from 'src/app/services/core/params.service';
import { ToolsService } from 'src/app/services/utils/tools.service';
import { TranslationService } from "src/app/services/core/translation.service";

import { CreateMediaPage } from 'src/app/pages/core/media/media/create-media/create-media.page';
import { EditPostPage } from 'src/app/pages/core/post/edit-post/edit-post.page';
import { DaniAlertLearnPage } from 'src/app/pages/getgenius/dani-alert-learn/dani-alert-learn.page';

@Injectable({
  providedIn: 'root'
})
export class DaniService {

  /**
   * Stores the paths to the spline urls based on requested dani action
   */
  urlsByState: any = {
    IDLE: './assets/spline/dani/dani_idle.splinecode',
    //MAIN: './assets/spline/dani/dani.splinecode',
    PRESENTING: './assets/spline/dani/dani_presenting.splinecode',
    WAVING: './assets/spline/dani/dani_waving.splinecode',
    WORKING: './assets/spline/dani/dani_working.splinecode',
  };

  constructor(
    private aiPlanner: AiPlannerService,
    private aiTools: AiToolsService,
    private aiWorker: AiWorkerService,

    private AppCMS: AppcmsService,
    private events: EventsService,
    private loadingCtrl: LoadingController,
    private modalService: ModalService,
    private navCtrl: NavController,
    private newslettersService: NewslettersService,
    private paramsService: ParamsService,
    private tools: ToolsService,
    private translations: TranslationService,
  ) {
    this.initEvents();
  }

  ai_search(options: any = {}) {
    return new Promise((resolve, reject) => {
      options.query = `${(options.query || options.input) || ''}`;

      this.aiTools.setSearchOptions(options);
      this.navCtrl.navigateForward('/ai/search');

      resolve(true);
    });
  }

  create_article(options: any = {}) {
    return this.create_post(options);
  }

  create_asset(options: any = {}) {

    options.types = [
      {
        checked: true,
        icon: 'image-outline',
        uid: 'image',
        name: 'image',
      },
      {
        checked: false,
        icon: 'film-outline',
        uid: 'video',
        name: 'video',
      }
    ];

    return this.create_media(options);
  }

  create_creative(options: any = {}) {
    options = options || {};

    options.types = [
      {
        checked: false,
        icon: 'image-outline',
        uid: 'image',
        name: 'image',
      },
      {
        checked: true,
        icon: 'film-outline',
        uid: 'video',
        name: 'video',
      }
    ];

    return this.create_media(options);
  }

  create_media(options: any = {}) {
    return new Promise(async (resolve, reject) => {

      const loading: any = await this.loadingCtrl.create({
        backdropDismiss: false,
        spinner: 'circular',
      });

      loading.present();

      this.AppCMS.loadPluginData('getgenius', {
        options: options,
      }, ['dani', 'create_media'])
        .then(async (response: any) => {

          let search: searchOptions = {
            query: this.tools.stripHtml(`${options.input || ''}`),
          };

          let view: any = {
            types: (options.types || [
              {
                checked: true,
                icon: 'image-outline',
                uid: 'image',
                name: 'image',
              },
              {
                checked: true,
                icon: 'film-outline',
                uid: 'video',
                name: 'video',
              }
            ]),
          };

          // apply media list to view
          if (!!options.mediaList) {
            view.mediaList = (options.mediaList || []);
          }

          // apply text overlay data if set
          if (!!response.sections) {
            view.sections = (response.sections || []);
            view.media = {};

            if (!!response.sections[0] && !!response.sections[0].title) {
              view.media.title = response.sections[0].title;
            }

            if (!!response.sections[0] && !!response.sections[0].subtext) {
              view.media.subtext = response.sections[0].subtext;
            }

          }

          // apply UI view data based on request
          this.paramsService.set('viewData_createMedia', {
            fire: true,
            search: search,
            view: view
          });

          const modal: any = await this.modalService.create({
            component: CreateMediaPage,
            componentProps: options,
            animated: true,
            presentingElement: await this.modalService.getTop(),
            cssClass: 'defaultModal',
          });

          modal.onWillDismiss().then((data: any) => {
            console.warn('create_media from dani: dismissed', data);
          });

          loading.dismiss();
          this.modalService.present(modal);
        })
        .catch((error: any) => {
          loading.dismiss();
          reject(error);
        });
    })
  }

  create_newsletter(options: any = {}) {
    return new Promise(async (resolve, reject) => {

      const loading: any = await this.loadingCtrl.create({
        backdropDismiss: false,
        spinner: 'circular',
      });

      loading.present();

      const config: any = {},
        templates: any = await this.newslettersService.getTemplates(),
        template: mediaTemplate = (!!templates && !!templates.length ? this.tools.shuffle(templates)[0] : null);

      if (!!template && !!template.value) {
        options.post_content = template.value;
      }

      this.newslettersService.generate({
        query: `${(options.input || options.query) || ''}`,
        post_content: `${options.post_content || ''}`,
      }, config)
        .then((response: any) => {
          loading.dismiss();
          console.log('newsletter generation response', response);
          resolve(response);
        })
        .catch((error: any) => {
          loading.dismiss();
          reject(error);
        });
    });
  }

  create_plan(options: any = {}) {
    return new Promise(async (resolve, reject) => {

      const loading: any = await this.loadingCtrl.create({
        backdropDismiss: false,
        spinner: 'circular',
      });

      loading.present();

      /*
      this.aiPlanner.schedule(this.viewData.plan || {}, {
        channels: (this.viewData.channels || []),
        create_assets: !!this.viewData.create_assets,
        create_creatives: !!this.viewData.create_creatives,
        languages: (this.viewData.languages || []),
        mediaList: (this.viewData.mediaList || []),
        topics: topicsString,
      })
      */
      this.aiPlanner.generate(options)
        .then((response: any) => {
          loading.dismiss();

          resolve(response);
        })
        .catch((error: any) => {
          loading.dismiss();
          reject(error);
        });
    });
  }

  create_project(options: any = {}) {
    return new Promise((resolve, reject) => {
      console.log('create_project', options);
    });
  }

  create_post(options: any = {}) {
    return new Promise(async (resolve, reject) => {

      const loading: any = await this.loadingCtrl.create({
        backdropDismiss: false,
        spinner: 'circular',
      });

      loading.present();

      this.executeCreatePost(options)
        .then(async (response: any) => {

          let post: any = {
            name: this.tools.stripHtml(`${options.input || ''}`),
          };

          if (!!response && !!response.post) {
            post = Object.assign(post, response.post || {});
          }

          if (!!response.output) {
            post.post_content = `${response.output || ''}`;
          }

          this.paramsService.set('viewData_createPost', {
            post: post,
          });

          const modal: any = await this.modalService.create({
            component: EditPostPage,
            componentProps: options,
            animated: true,
            presentingElement: await this.modalService.getTop(),
            cssClass: 'defaultModal',
          });

          modal.onWillDismiss().then((data: any) => {
            console.warn('create_post from dani: dismissed', data);
          });

          loading.dismiss();
          this.modalService.present(modal);
        })
        .catch((error: any) => {
          loading.dismiss();
          reject(error);
        });
    })
  }

  /*
  create_team(options: any = {}) {
    console.log('create_team', options);
  }
  */

  create_ticket(options: any = {}) {
    return new Promise((resolve, reject) => {
      console.log('create_ticket', options);
    });
  }

  /*
  create_user(options: any = {}) {
    console.log('create_user', options);
  }
  */

  executeCreateAutomation(options: any = {}) {
    return this.AppCMS.loadPluginData('getgenius', {
      options: options,
    }, ['dani', 'create_automation']);
  }

  executeCreateCampaign(options: any = {}) {
    return this.AppCMS.loadPluginData('getgenius', {
      options: options,
    }, ['dani', 'create_campaign']);
  }

  executeCreateCode(options: any = {}, params: any = {}) {
    return this.AppCMS.loadPluginData('getgenius', Object.assign(params, {
      options: options,
    }), ['dani', 'create_code']);
  }

  executeCreatePost(options: any = {}) {
    return this.AppCMS.loadPluginData('getgenius', {
      options: options,
    }, ['dani', 'create_post']);
  }

  executeCreateTextToAudio(options: any = {}) {
    return this.AppCMS.loadPluginData('getgenius', {
      options: options,
    }, ['dani', 'text_to_audio']);
  }

  executeCreateTextToMusic(options: any = {}) {
    return this.AppCMS.loadPluginData('getgenius', {
      options: options,
    }, ['dani', 'text_to_music']);
  }

  executeCreateTextToSpeech(options: any = {}) {
    return this.AppCMS.loadPluginData('getgenius', {
      options: options,
    }, ['dani', 'text_to_speech']);
  }

  executeCreateVideo(options: any = {}) {
    return this.AppCMS.loadPluginData('getgenius', {
      options: options,
    }, ['dani', 'create_video']);
  }

  getUrlsByState() {
    return this.urlsByState;
  }

  async handleFunctionCall(call: any) {
    if (!!call && !!call.name) {
      try {
        this[call.name](call.arguments)
          .then((response: any) => {
            console.log('call response', response);
          })
          .catch((e: any) => {
            console.warn('dani: call failed (2)', e);
            this.events.publish('error', e);
          });
      } catch (e) {
        console.warn('dani: call failed (1)', e);
        this.events.publish('error', e);
      }
    }
  }

  initEvents() {
    this.events.subscribe('ai:function:call', (call: any) => {
      this.handleFunctionCall(call);
    });
  }

  requestLearn(entry: any = {}, options: any = {}) {
    return new Promise(async (resolve, reject) => {

      const modal: any = await this.modalService.create({
        component: DaniAlertLearnPage,
        componentProps: Object.assign(options, {
          entry: entry,
        }),
        animated: true,
        presentingElement: await this.modalService.getTop(),
        cssClass: 'defaultModal',
      });

      modal.onWillDismiss().then((data: any) => {
        console.warn('create_media from dani: dismissed', data);
        resolve(data);
      });

      this.modalService.present(modal);
    });
  }

  sendChat(item: chatSendRequestOptions, blForceRefresh: boolean = false, config: any | null = null, params: any = {}) {
    return new Promise(async (resolve, reject) => {

      item.config = Object.assign(
        (this.aiWorker.getConfig() || {}),
        (config || (item.config || {}))
      );

      if (!!item.config && !!item.config.provider && (item.config.provider === 'local')) {
        try {

          const exec: any = await this.aiWorker.executeLocal(item, true, item.config, {

            // stream partial result (currently only supported in local mode)
            onPartialResult: params.onPartialResult || ((partialResult: string, partIndex: number) => {
              console.log('dani: sendChat: partialResult', partialResult);
            }),

          });

          console.log('dani: local exec', exec);

          resolve(exec);
        } catch (e) {
          reject(e);
        }
      } else {
        this.AppCMS.loadPluginData('getgenius', Object.assign(params, {
          context: 'chat_completions',
          item: item,
        }), ['dani', 'chat'], {}, blForceRefresh).then(resolve).catch(reject);
      }
    });
  }

  text_to_image(options: any) {
    return this.create_asset(options);
  }

  text_to_video(options: any) {
    return this.create_asset(options);
  }

  speak(text: string) {
    this.translations.get([text])
      .subscribe((translations: any) => {
        var msg = new SpeechSynthesisUtterance();

        msg.text = `${(translations[text] || text) || ''}`;
        msg.lang = 'en-US';

        window.speechSynthesis.speak(msg);
      });
  }

}