import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { ThemesService } from '../../../services/core/themes.service';

import { hostUrl, proxyUrl } from 'src/config/variables';

@Component({
  selector: 'pipeline-video-editor',
  standalone: false,
  templateUrl: './video-editor.component.html',
  styleUrls: ['./video-editor.component.scss']
})
export class VideoEditorComponent implements AfterViewInit, OnInit {

  @ViewChild('aiVideoCanvas', { read: ElementRef, }) canvas: ElementRef;

  @Input() disabled: boolean;

  engine: any;

  @Input() height: number;

  @Input() input: string;
  @Output() inputChange = new EventEmitter();

  @Input() instance: any;

  @Input() licenceConfig: any = {
    licenseName: "GetGenius Beta",
    licenseKey: "7C618C2CFE857EC92E5B0001",
  };

  @Input() loading: boolean = false;

  @Input() media: mediaItem = {};
  @Input() mediaByIndex: any = {};
  @Input() mediaIdsByMediaUrls: any = {};

  @Input() mediaList: mediaItem[];

  @Output() onClipUpdated = new EventEmitter();
  @Output() onClipStyleUpdated = new EventEmitter();
  @Output() onLayerUpdated = new EventEmitter();
  @Output() onLibraryChanged = new EventEmitter();
  @Output() onTimeUpdated = new EventEmitter();

  @Input() project: aiVideoProject;

  @Input() theme: string = 'light';

  @Input() view: any = {};

  @Input() width: number;

  constructor(
    private themes: ThemesService,
  ) {
    const scheme: any = this.themes.getScheme();

    if (!!scheme && (scheme === 'dark')) {
      this.theme = 'dark';
    }
  }

  public async addMedia(url: string, options: any = {}, blUpdateIfExists: boolean = false) {

    if (!this.instance) {
      return false;
    }

    options.index = options.index || 0;

    if (!!url && (url[0] === '.')) {
      url = hostUrl + url.replace('./', '/');
    }

    const mediaUrl: string = (url.indexOf(';base64,') !== -1 ? url : (proxyUrl + url));
    console.log('video-editor: addMedia: mediaUrl', mediaUrl);

    const mediaId: any = (this.mediaIdsByMediaUrls[mediaUrl] || await this.instance.library.addMedia(mediaUrl));

    if (!!mediaId && !this.mediaIdsByMediaUrls[mediaUrl]) {
      this.mediaIdsByMediaUrls[mediaUrl] = mediaId;
    }

    let clipOptions: any = Object.assign(options, {
      mediaDataId: mediaId,
      type: options.type || this.media.post_mime_type,
      startTime: options.startTime || 0,
    });

    if (clipOptions.type !== 'audio' && clipOptions.type !== 'video') {
      clipOptions.duration = clipOptions.duration || 5;
    }

    if (!!this.mediaByIndex[options.index] && !!this.mediaByIndex[options.index].clip && !!blUpdateIfExists) {
      this.mediaByIndex[options.index].clip.destroy();
    }

    const layer: any = (!!this.mediaByIndex[options.index] && !!this.mediaByIndex[options.index].layer && !!blUpdateIfExists ? this.mediaByIndex[options.index].layer : this.createLayer({
      index: options.index,
    }));

    console.log('addMedia: clipOptions', clipOptions);

    const clip: any = await layer.addClip(clipOptions);
    console.log('addMedia: clip', clip);

    if (!this.mediaByIndex[options.index] || !!blUpdateIfExists) {
      this.mediaByIndex[options.index] = {
        clip: clip,
        layer: layer,
        options: clipOptions,
        url: mediaUrl,
      };
    }

    console.log('addMedia: mediaByIndex', this.mediaByIndex);

    return this.mediaByIndex;
  }

  public addSection(section: any) {
    console.log('video-editor: addSection: section', section);
  }

  public createLayer(options: any = {}) {
    return this.instance.timeline.createLayer(options);
  }

  public destroy(full: boolean = false) {
    return this.instance.destroy(full);
  }

  initRendley() {

    if (!this.canvas || !this.canvas.nativeElement) {
      return false;
    }

    this.canvas.nativeElement.addEventListener('click', (event: any) => {
      const element: any | null = document.elementFromPoint(event.x, event.y);
      console.log('click: element', element);
    });

    this.canvas.nativeElement.addEventListener("onRenderSuccess", (blobUrl) => {
      // Handle render success
      console.log('onRenderSuccess: blobUrl', blobUrl);
    });

    this.canvas.nativeElement.addEventListener("onRenderError", (message) => {
      // Handle render error
      console.log('onRenderError: message', message);
    });

    this.canvas.nativeElement.addEventListener("onReady", async () => {
      this.engine = await this.canvas.nativeElement.getEngine();
      this.instance = this.engine.getInstance();

      console.log('video-editor: init: instance', this.instance);
      console.log('video-editor: init: media', this.media);
      console.log('video-editor: init: video project', this.project);

      if (!!this.project && !!this.project.sections) {
        await this.loadVideoProject(this.project);
      } else
        if (!!this.media && !!this.media.guid) {
          await this.addMedia(this.media.guid);
        }

      this.instance.events.on("clip:updated", (data: any) => {
        const keys: string[] = Object.keys(this.mediaByIndex || {});

        const matchingKeys: string[] = keys.filter((key: string) => {
          const item: any = this.mediaByIndex[key];
          return !!item && !!item.clip && (item.clip.id === data.clipId);
        });

        const match: any | null = (!!matchingKeys && (matchingKeys.length === 1) && !!this.mediaByIndex[matchingKeys[0]] ? this.mediaByIndex[matchingKeys[0]] : null);

        this.onClipUpdated.emit(match);
      });

      this.instance.events.on("clip:style:updated", (data: any) => {
        this.onClipStyleUpdated.emit(data);
      });

      this.instance.events.on("layer:updated", (data: any) => {
        this.onLayerUpdated.emit(data);
      });

      this.instance.events.on("library:added", (mediaDataId) => {
        this.onLibraryChanged.emit({
          mediaByIndex: this.mediaByIndex,
          mediaDataId: mediaDataId,
          mediaIdsByMediaUrls: this.mediaIdsByMediaUrls,
        });
      });

      this.instance.events.on("log", (data: any) => {
        console.log('log: ', data);
      });

      this.instance.events.on("playing", (data: any) => {
        console.log('playing: ', data);
      });

      this.instance.events.on("ready", (data: any) => {
        console.log('ready: ', data);
      });

      this.instance.events.on("render:completed", (data: any) => {
        console.log('render:completed: ', data);
      });

      this.instance.events.on("time", (data: any) => {
        this.onTimeUpdated.emit(data);
      });
    });
  }

  public loadFromJson(json: any) {

    if (typeof json === 'string') {
      json = JSON.parse(json);
    }

    if (!json) {
      return false;
    }

    json = json || {};

    json.settings = json.settings || {
      "decoderPreferredAcceleration": "prefer-hardware",
      "encoderPreferredAcceleration": "prefer-hardware",
      "m3u8MaxResolution": [
        1920,
        1080
      ],
      "renderShowPreview": true,
      "renderMaxQueueSize": 10,
      "renderThrottleFactor": 1,
      "renderAudioUseWorker": false,
      "encoderCodec": "h264",
      "encoderUseWebCodecs": true,
      "encoderUseAlpha": false,
      "encoderBitrate": 5000000,
      "encoderBitrateMode": "constant",
      "encoderPerformanceMode": "quality",
      "encoderDeadline": 10000,
      "encoderCrf": 23,
      "encoderForcedCodecRFCString": "",
      "subtitlesScaleOnResize": false,
      "subtitlesAutoWrapOnResize": false,
      "viewAutoLayoutOnResize": "prefer-height"
    };

    json.subtitlesManager = json.subtitlesManager || {
      "textMode": "full",
      "highlightAnimation": "none",
      "highlightAnimationSpeed": 1,
      "mainTextStyle": {
        "color": "#ffffff",
        "fontSize": 50,
        "fontWeight": "400",
        "fontFamily": "Arial",
        "fontStyle": "normal",
        "backgroundColor": "transparent",
        "backgroundPadding": 0,
        "backgroundCornerRadius": 0,
        "strokeThickness": 0,
        "strokeColor": "#000000",
        "padding": 0,
        "wordWrapWidth": 1000,
        "wordWrap": false,
        "leading": 0
      },
      "highlightTextStyle": {
        "color": "#ffffff",
        "fontSize": 50,
        "fontWeight": "400",
        "fontFamily": "Arial",
        "fontStyle": "normal",
        "backgroundColor": "transparent",
        "backgroundPadding": 0,
        "backgroundCornerRadius": 0,
        "strokeThickness": 0,
        "strokeColor": "#000000",
        "padding": 0
      },
      "position": [
        960,
        930
      ],
      "scale": 1
    };

    console.log('video-editor: loadFromJson: json', json);

    return this.engine.deserialize(json);
  }

  public loadVideoProject(project: aiVideoProject) {

    if (!project || !project.sections) {
      return false;
    }

    const audioLayerIndex: number = project.sections.length;

    project.sections.forEach((section: any, index: number) => {
      setTimeout(async () => {

        // add main media if url provided
        if (!!section.url) {
          await this.updateMedia(section.url, {
            duration: (!!section.settings && !!section.settings.end ? (section.settings.end || 4) - (section.settings.start || 0) : 4),
            index: section.index || index,
            startTime: (!!section.settings ? (section.settings.start || 0) : (index * 4)),
            type: section.type,
          });
        }

        // add media audio if provided
        if (!!section && !!section.settings && !!section.settings.speaker_text_file) {
          setTimeout(async () => {
            await this.addMedia(section.settings.speaker_text_file, {
              index: audioLayerIndex || (section.index || index),
              startTime: (!!section.settings ? (section.settings.start || 0) : (index * 4)),
              type: 'audio',
            }, false);
          }, 500);
        }
      }, (index * 1000));
    });
  }

  ngAfterViewInit() {
    this.initRendley();
  }

  ngOnInit() {
    console.log('video-editor component: video project', this.project);
  }

  public setResolution(width: number, height: number) {

    if (!this.instance || !this.instance.display) {
      return false;
    }

    return this.instance.display.setResolution(width, height);
  }

  public toJSON() {
    return this.instance.serialize();
  }

  public async updateMedia(url: string, options: any = {}) {
    options.index = options.index || 0;

    if (!!this.mediaByIndex[options.index] && !!this.mediaByIndex[options.index].clip) {
      return this.addMedia(url, options, true);
    } else {
      return this.addMedia(url, options);
    }
  }

}