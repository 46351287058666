import { AfterContentInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular';

import { AiToolsService } from "src/app/services/ai/ai-tools.service";
import { AiWorkerService } from 'src/app/services/ai/ai-worker.service';

import { EventsService } from "src/app/services/core/events.service";

@Component({
  selector: 'pipeline-ai-settings-picker',
  standalone: false,
  templateUrl: './ai-settings-picker.component.html',
  styleUrls: ['./ai-settings-picker.component.scss']
})
export class AiSettingsPickerComponent implements AfterContentInit, OnDestroy, OnInit {
  @ViewChild('aiSettingsPopover') aiSettingsPopover: any;

  @Input() cards: any = {};
  @Input() category: string = 'text-generation';
  @Input() color: string = 'dark';
  @Input() config: aiSettings = {};
  @Input() context: string;
  @Input() disabled: boolean = false;
  @Input() fill: string = 'clear';
  @Input() mini: boolean = false;
  @Input() size: string;
  @Input() view: any;

  @Output() changed = new EventEmitter();

  fallbackImg: string = './assets/img/fallback.webp';

  isAiSettingsPopoverOpen: boolean = false;

  models: any = [];

  @Input() options: aiSettingsOptions = {
    operations: ['text-generation'],
  };

  constructor(
    public ai: AiToolsService,
    private aiWorker: AiWorkerService,

    private events: EventsService,
    private navCtrl: NavController,
  ) {
    this.view = this.view || {};

    if (!!this.context) {
      this.config.context = this.context;
    }

    console.log('ai-settings-picker: init config', this.config);
  }

  initViewVars() {
  }

  ngAfterContentInit() {
    this.initViewVars();
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    console.log('ai-settings-picker: ngOnInit config', this.config);

    if (!!this.context) {
      this.config.context = this.context;
    }
  }

  modelChanged(value: any) {
    this.changed.emit(value);
  }

  openModal(event: any = null) {
    this.aiSettingsPopover.event = event;
    this.isAiSettingsPopoverOpen = true;
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
  }

  viewAll() {
    this.events.publish('menu:load:appPages', { key: 'models_admin' });
    this.navCtrl.navigateForward('/ai/models');
  }

}