import { Component, NgZone, OnInit, ViewChild } from "@angular/core";

import {
  IonSearchbar,
  NavController,
  Platform,
} from "@ionic/angular";

import { TranslateService } from "@ngx-translate/core";

/* Services */
import { AbonnementService } from "src/app/services/core/abonnement.service";
import { AccountsService } from "src/app/services/core/accounts.service";
import { AdminService } from "src/app/services/core/admin.service";
import { AlertService } from "src/app/services/core/alert.service";
import { AppcmsService } from "src/app/services/core/appcms.service";
import { AuthService } from "src/app/services/core/auth.service";
import { ConfigService } from "src/app/services/core/config.service";
import { CrudService } from "src/app/services/core/crud.service";
import { ErrorService } from "src/app/services/core/error.service";
import { EventsService } from "src/app/services/core/events.service";
import { GlobalEventsService } from "src/app/services/core/global-events.service";
import { LanguageService } from "src/app/services/core/language.service";
import { LogfileService } from "src/app/services/core/logfile.service";
import { LoginService } from "src/app/services/core/login.service";
import { MenuService } from "src/app/services/core/menu.service";
import { ModalService } from "src/app/services/core/modal.service";
import { NetworkService } from "src/app/services/core/network.service";
import { ProjectsService } from 'src/app/services/core/projects.service';
import { PushnotificationsService } from "src/app/services/core/pushnotifications.service";
import { TeamsService } from "src/app/services/core/teams.service";
import { TestingService } from "src/app/services/core/testing.service";
import { ThemesService } from "src/app/services/core/themes.service";
import { UserService } from "src/app/services/core/user.service";
import { ViewService } from 'src/app/services/core/view.service';
import { WindowManagerService } from 'src/app/services/core/window-manager.service';
import { BasketService } from "src/app/services/ecommerce/basket.service";
import { PagesService } from "src/app/services/extensions/pages.service";
import { InboxService } from "src/app/services/social/inbox.service";
import { BrowserService } from "src/app/services/utils/browser.service";
import { IntroService } from "src/app/services/utils/intro.service";
import { ShortcutsService } from "src/app/services/utils/shortcuts.service";
import { ToolsService } from "src/app/services/utils/tools.service";
import { GetgeniusService } from "./services/getgenius/getgenius.service";
import { ToastService } from "./services/utils/toast.service";

/* Pages */
import { TeamPage } from 'src/app/pages/core/user/teams/team/team.page';
import { ChangeAccountPage } from "./pages/core/account/change-account/change-account.page";
import { ViewPage } from "./pages/core/view/view.page";

/* Plugins */
//import { Plugins } from "@capacitor/core";
import { App } from "@capacitor/app";
import { Keyboard } from "@capacitor/keyboard";
import { StatusBar } from '@capacitor/status-bar';

declare const IonicDeeplink: any;

@Component({
  selector: "app-root",
  standalone: false,
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  @ViewChild('globalSearchInput') searchInput: IonSearchbar;
  @ViewChild('createPopover') createPopover;
  @ViewChild('profileSettingsPopover') profileSettingsPopover;
  @ViewChild('tabChildrenPopover') tabChildrenPopover;
  @ViewChild('toolPickerPopover') toolPickerPopover;

  isCreatePopoverOpen: boolean = false;
  isProfileSettingsPopoverOpen: boolean = false;
  isTabChildrenPopoverOpen: boolean = false;
  isToolPickerPopoverOpen: boolean = false;

  appConfig: pipelineAppConfig;

  public appPages: appPage[];

  availableAppPages: appPage[] = [];

  public avatarUrl: string = "./assets/img/avatars/1.webp";

  basketInfo: basketInfo = {};

  categories: category[];

  daniConfig: daniConfig = {
    hasChat: false,
    mini: true,
    uiMode: 'widget',
    userCanWrite: false,
    zoom: (window.outerWidth > 768 ? 0.55 : 0.25),
  };

  fallbackAvatarImg: string = './assets/img/avatars/1.webp';
  fallbackImg: string = './assets/img/fallback.webp';

  icons: any = {};

  inboxInterval: any;

  inboxIntervalTime: number;

  menuData: any = {
    visible: false,
  };

  project: project;

  search: searchOptions = {
    keys: ['title', 'excerpt', 'url', 'description', 'name', 'indent'],
    query: '',
  };

  public selectedIndex = 0;

  sidebarMode: string = 'view';

  team: team = {};

  urls: any = {
    career: "https://pipeline.page/karriere/",
    help_center: "https://pipeline.page/hilfe-center/",
    submit_blog: "https://pipeline.page/blog-eintragen/",
  };

  user: user;

  view: any = {
    currentMenuItem: 'creator_studio',
    dani: {
      state: {
        speaking: false,
      },
    },
    toastsBlocked: false,
  };

  constructor(
    private abo: AbonnementService,
    private accounts: AccountsService,
    private admin: AdminService,
    private alert: AlertService,
    private AppCMS: AppcmsService,
    private authService: AuthService,
    private basketService: BasketService,
    private browser: BrowserService,
    private configService: ConfigService,
    private crud: CrudService,
    private errorHandler: ErrorService,
    private events: EventsService,
    private getgenius: GetgeniusService,
    private globalEvents: GlobalEventsService,
    private inbox: InboxService,
    private intro: IntroService,
    private languages: LanguageService,
    private log: LogfileService,
    private loginService: LoginService,
    private menu: MenuService,
    private modalService: ModalService,
    private navCtrl: NavController,
    private network: NetworkService,
    private pages: PagesService,
    private platform: Platform,
    private projects: ProjectsService,
    private push: PushnotificationsService,
    private shortcuts: ShortcutsService,
    private teams: TeamsService,
    private testing: TestingService,
    private themes: ThemesService,
    private translate: TranslateService,
    private toast: ToastService,
    private tools: ToolsService,
    private zone: NgZone,
    private userService: UserService,
    private viewService: ViewService,
    private windowManager: WindowManagerService,
  ) {
    this.appConfig = this.configService.getConfig();
    this.avatarUrl = this.userService.getAvatarUrl();

    const locationParams: any = new URLSearchParams(location.search);
    const hideMenu: boolean = !!(locationParams.get('hide_menu') == '1');

    this.view.hide_menu = !!hideMenu;
    this.menuData.visible = !!this.userService.getUid() && !this.view.hide_menu;

    this.loadUser();

    this.authService.init();

    this.calculateSidebarPosition();
  }

  abonnement() {
    this.navCtrl.navigateForward("/abonnements");
    if (window.innerWidth <= 768) {
      this.menu.close();
    }
  }

  account() {
    this.isProfileSettingsPopoverOpen = false;

    this.navCtrl.navigateForward("/account", { animated: true });

    if (window.innerWidth <= 768) {
      this.menu.close();
    }

    this.modalService.closeAll();
  }

  addAccount() {
    this.isProfileSettingsPopoverOpen = false;
    this.accounts.setMultiMode(true);
    this.events.publish('login:show');
  }

  async addAppPage(item: any, event: any = null) {
    this.appPages = this.appPages || [];
    this.appPages.push(item);

    try {
      await this.menu.setCustomPages(this.appPages, this.view.appPagesKey);
    } catch (e) {
      console.warn('setting custom pages failed', e);
    }

    this.isToolPickerPopoverOpen = false;
  }

  async addItemToSidebar(event: any | null = null) {
    this.toolPickerPopover.event = event;
    this.isToolPickerPopoverOpen = true;
  }

  afterLogout() {
    const route: string = window.location.pathname;

    if (route !== '/login') {
      this.navCtrl.navigateRoot('/login');
    }
  }

  appearance() {
    this.navCtrl.navigateForward("/appearance");
    if (window.innerWidth <= 768) {
      this.menu.close();
    }
    this.modalService.closeAll();
  }

  appearanceDisabled() {
    if (this.view.aboVersion === "free") {
      this.navCtrl.navigateForward("/abonnements");
      if (window.innerWidth <= 768) {
        this.menu.close();
      }
    }
    this.modalService.closeAll();
  }

  basket() {
    this.modalService.closeAll();
    this.events.publish("view:basket");
    if (window.innerWidth <= 768) {
      this.menu.close();
    }
  }

  async calcExpertMode() {
    try {
      this.view.expertMode = await this.viewService.isExpertMode();
    } catch (e) {
      console.log('loading view mode settings failed', e);
    }
  }

  calcSubMode() {
    if (!!this.view.currentMenuItem && (this.view.currentMenuItem === 'creator_studio')) {
      this.view.subMode = false;
    } else {
      this.view.subMode = !!this.appPages && ((JSON.stringify(this.appPages) !== JSON.stringify(this.view.initAppPages)));
    }
  }

  calculateSidebarPosition() {
    this.view.sidebarPosition = this.user?.classifications?.settings?.sidebar_position || 'left';
  }

  async calcViewVars() {
    this.view.isDesktop = this.tools.isDesktop();
    this.view.useWindowManager = await this.windowManager.useWindowManager();

    this.calcExpertMode();
  }

  career() {
    this.browser.create(this.urls.career);
    if (window.innerWidth <= 768) {
      this.menu.close();
    }
    this.modalService.closeAll();
  }

  createNewObjectType(event: any = null) {
    this.navCtrl.navigateForward('/objects/types/new');
  }

  dani() {
    this.hideDani();

    this.navCtrl.navigateForward('/dani/chat');

    if (window.innerWidth <= 768) {
      this.menu.close();
    }
  }

  dismissToolsPopover() {
    this.isToolPickerPopoverOpen = false;
  }

  editSidebar(bl: boolean = true) {
    if (!!bl) {
      this.sidebarMode = 'edit';
    } else {
      this.sidebarMode = 'view';
    }
  }

  hideDani() {
    this.view.dani.state.hidden = true;
  }

  hideSidebarMenuItem(item: any, index: number, bl: boolean = true) {
    this.appPages[index].hidden = bl;
    this.menu.setCustomPages(this.appPages, this.view.appPagesKey);
  }

  async initAppPages(blForceRefresh: boolean = false) {
    const userId: number = this.userService.getUid();

    if (!!userId && (userId !== -1)) {
      this.view.appPagesKey = 'main';
      this.view.parentMenuName = 'main';

      const blAppendObjectTypes: boolean = !!(this.project && this.project.uid);

      this.appPages = await this.menu.getAppPages(
        this.view.appPagesKey,
        blAppendObjectTypes,
        true,
        (blForceRefresh || blAppendObjectTypes)
      );

      this.availableAppPages = (await this.menu.getAvailableAppPages(blForceRefresh) || []) as appPage[];
      this.view.initAppPages = JSON.parse(JSON.stringify(this.appPages));
    }
  }

  async initBasket() {

    if (!this.appConfig.useShop) {
      return false;
    }

    this.basketInfo = await this.basketService.calculateBasketInfo();

    this.events.subscribe(
      "basketInfo:updated",
      async (basketInfo: basketInfo) => {
        this.basketInfo = basketInfo;
      }
    );
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.languages.init();
      this.alert.init();
      this.errorHandler.init();
      this.network.init();
      this.crud.init();
      this.viewService.init();

      this.initKeyboard();
      this.initThemes();
      this.initTeams();
      this.initBackButton();
      this.initEvents();

      try {
        this.initBasket();

        this.windowManager.init();

        StatusBar.setOverlaysWebView({ overlay: false });
      } catch (e) {
        console.warn("basket & global init failed", e);
      }
    });
  }

  initBackButton() {
    try {
      App.addListener("backButton", ({ canGoBack }) => {
        const blIsModal: boolean = this.modalService.isModal();

        if (!!blIsModal) {
          this.modalService.close();
        } else if (!canGoBack) {
          App.exitApp();
        } else {
          window.history.back();
        }
      });
    } catch (e) {
      console.warn("back button error", e);
    }
  }

  initDeepLinks() {
    try {
      IonicDeeplink.route(
        {
          //'/code/verify/:codeId': CodeVerifyPage,
          "/view/:postId": ViewPage,
        },
        (match: any) => {
          //console.log("Successfully matched route", match);
        },
        (nomatch: any) => {
          //console.error("Got a deeplink that didn't match", nomatch);
        }
      );
    } catch (e) {
      //console.warn("deeplinks error", e);
    }

    App.addListener("appUrlOpen", (data: any) => {
      this.zone.run(() => {

        const url: string = (data.url || "")
          .replace("https://", "")
          .replace("open.", "")
          .replace("web.", "")
          .replace("pipeline.page", "")
          .replace("/", "");

        const split: string[] = url.split("/");

        switch (split[0]) {
          case "code":
            if (split[1]) {
              switch (split[1]) {
                case "verify":
                  //console.log("verify code", split);
                  break;
                default:
                  //console.log("default code", split);
                  break;
              }
            }
            break;
          case "profile":
            if (split[1] && split[1].length) {
              this.events.publish("view:profile", parseInt(split[1]));
            }
            break;
          case "view":
            if (split[1] && split[1].length) {
              this.events.publish("view:post", parseInt(split[1]));
            }
            break;
          default:
            if (split[0] && split[0].length) {
              this.events.publish("view:post", parseInt(split[0]));
            }
            break;
        }
      });
    });
  }

  initEvents() {
    this.view.events = this.view.events || {};

    this.view.events.menuDaniHide = this.events.subscribe('menu:dani:hide', () => {
      this.hideDani();
    });

    this.view.events.menuDaniShow = this.events.subscribe('menu:dani:show', () => {
      this.hideDani();
    });

    this.view.events.menuHide = this.events.subscribe("menu:hide", () => {
      this.zone.run(() => {
        this.menuData.visible = false;
      });
    });

    this.view.events.menuLoadAppPages = this.events.subscribe('menu:load:appPages', async (data: any) => {
      this.view.appPagesKey = data.key;

      const appPages: appPage[] = await this.menu.getAppPages(data.key);

      if (!!appPages && !!appPages.length) {
        this.appPages = appPages;
        this.view.parentMenuName = await this.menu.getParentMenuName(data.key);
        this.calcSubMode();
      }

      this.view.currentMenuItem = `${data.item || data.key}`;
    });

    this.view.events.loginShow = this.events.subscribe('login:show', () => {
      this.loginService.show();
    });

    this.view.events.menuShow = this.events.subscribe("menu:show", () => {
      this.zone.run(() => {
        if (!this.view.hide_menu) {
          this.menuData.visible = true;
        }
      });
    });

    this.view.events.appcmsUserLoggedout = this.events.subscribe("appcms:user:loggedout", () => {
      this.accounts.setMultiMode(false);
      this.loadUser();
      this.log.loggedOut();

      this.up();

      if (this.inboxInterval) {
        clearInterval(this.inboxInterval);
      }

      this.afterLogout();
    });

    this.view.events.appcmsUserLoggedin = this.events.subscribe("appcms:user:loggedin", () => {
      this.onUserLoggedIn();
    });

    this.view.events.appcmsUserUpdated = this.events.subscribe("appcms:user:updated", async () => {
      this.onUserUpdated();
    });

    this.view.events.objectsTypeCreated = this.events.subscribe('objects:type:deleted', async () => {
      this.initAppPages();
    });

    this.view.events.objectsTypeCreated = this.events.subscribe('objects:type:created', async () => {
      this.initAppPages();
    });

    this.view.events.objectsTypeUpdated = this.events.subscribe('objects:type:updated', async () => {
      this.initAppPages();
    });

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', async (project: project) => {
      this.projectChanged(project);
    });

    this.view.events.viewModeChanged = this.events.subscribe('view:mode:changed', (bl: boolean | null) => {

      if (bl !== null) {
        this.view.expertMode = !!bl;
      }

      this.viewModeChanged();
    });

    this.events.subscribe('window:manager:state:updated', (bl: boolean) => {
      this.view.useWindowManager = !!bl;
    });

    this.events.subscribe('window:resized', () => {
      this.calcViewVars();

      if (window.innerWidth >= 768 && !!this.userService.getUid()) {
        this.menu.enable();
      }
    });

    this.toast.init();
    this.globalEvents.init();
  }

  initInbox() {
    setTimeout(() => {
      if (this.user && this.user.uid) {
        this.startInboxInterval();
      }

      this.events.subscribe("inbox:interval:update", (time: number) => {
        this.inboxIntervalTime = time;
        this.startInboxInterval();
      });
    }, 5 * 1000);
  }

  initKeyboard() {
    if (!this.tools.isWeb()) {
      try {
        Keyboard.setAccessoryBarVisible({ isVisible: true });
      } catch (e) {
        console.warn("keyboard error", e);
      }
    }
  }

  initPushNotifications() {
    this.push.init();
  }

  initShortcuts() {
    this.shortcuts.init();
  }

  initTeams() {

    this.events.subscribe('team:updated', (team: team) => {
      this.team = team;
    });

    this.teams.init()
      .then(async () => {
        this.team = (await this.teams.getCurrentTeam()) || {};
      })
      .catch((error: any) => {
        console.warn('> init teams failed', error);
      });
  }

  initTesting() {
    this.testing.init();
  }

  initThemes() {
    this.themes.init();
  }

  initWallet() {
    this.getgenius.init();
  }

  interests() {
    this.navCtrl.navigateForward("/interests");
    if (window.innerWidth <= 768) {
      this.menu.close();
    }
    this.modalService.closeAll();
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  loadUser() {
    this.user = this.userService.getUser() || {};

    if (!!this.user && !!this.user.uid) {
      this.AppCMS.setApiCredentials({
        email: this.user.email,
        password: this.user.password,
        uid: this.user.uid,
      });
    } else {
      this.AppCMS.setApiCredentials({});
    }

    try {

      if (!!this.menuData) {
        this.menuData.visible = !!(this.user && this.user.uid) && (!this.view || !this.view.hide_menu);
      }

      if (!!this.user && !!this.user.uid) {
        this.menu.enable();
      } else {
        this.menu.close();
      }
    } catch (e) {
      console.warn('post user-loading error: ', e);
    }
  }

  logoutAll() {
    this.isProfileSettingsPopoverOpen = false;

    this.authService.logout()
      .then(() => {
        this.teams.setCurrentTeam(null);
        this.afterLogout();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.calcViewVars);
  }

  ngOnInit() {
    this.view.aboVersion = this.abo.getCurrentVersion();
    this.view.globalCreateItems = this.configService.getGlobalCreateItems();

    this.calcViewVars();
    this.initializeApp();

    this.events.subscribe("appcms:user:updated", () => {
      this.view.aboVersion = this.abo.getCurrentVersion();
      this.initAppPages();
      this.initWallet();
    });

    setTimeout(() => {
      this.validateUser();
    }, 1000);
  }

  onMenuHeaderLogoClick() {
    this.navCtrl.navigateRoot('/dashboard');
  }

  async onMenuToggleClick(data: any | null = null, index: number | null = null) {
    this.isTabChildrenPopoverOpen = false;

    if (this.sidebarMode === 'edit') {
      return false;
    }

    this.view.currentMenuItem = `${data.item || data.key}`;

    if (!!this.searchInput) {
      this.searchInput.value = '';
    }

    this.view.activeMenuItem = data;
    this.view.activeMenuItemIndex = index;
    this.view.search_results = null;

    let menu: any | null = null;

    const blValidate: boolean = await this.validateMenuClickPermission(data);

    if (!blValidate) {
      return false;
    }

    // if item has children, toggle dropdown
    if (!!data && !!data.children) {
      if (!!data.active) {
        data.active = false;
      } else {
        this.appPages.forEach((_page: appPage) => {
          _page.active = false;
        });
        data.active = !data.active;
      }
    }

    switch (data.key) {
      case "creator_studio":
        this.view.appPagesKey = 'main';
        break;
      default:
        this.view.appPagesKey = data.key;
        break;
    }

    menu = await this.menu.getAppPages(this.view.appPagesKey);
    let blAllowWindowManager: boolean = true;

    if (menu === this.appPages) {
      this.menu.close();
    } else
      if (menu && menu.length) {
        blAllowWindowManager = false;
        this.appPages = menu;
        this.calcSubMode();
      } else
        if (!data || !data.children || !data.children.length) {
          this.menu.close();
        }

    const blUseWindowManager: boolean = await this.windowManager.useWindowManager();

    if (data && data.handler) {

      if (typeof data.handler === 'string') {
        data.handler = this.pages.getHandler(data.handler);
      }

      try {
        data.handler();
      } catch (e) {
        console.warn('> handler failed', e);
      }

    } else
      if (data && data.url) {

        if (!data || !data.url) {
          return false;
        }

        if (!!blUseWindowManager && (!data || !data.children || !data.children.length)) {
          if (!blAllowWindowManager) {
            this.navCtrl.navigateForward(data.url);
          } else {
            this.windowManager
              .open({
                route: data.url,
                uid: data.key,
              })
              .catch((error: any) => {
                this.events.publish('error', error);
              });
          }
        } else {
          this.modalService.closeAll();
          this.navCtrl.navigateRoot(data.url);
        }
      }

    if (!blUseWindowManager) {
      this.modalService.closeAll();
    }

    return false;
  }

  onTabButtonClick(appPage: appPage, index: number, event: any = null) {
    if (!!appPage && !!appPage.children && !!appPage.children.length) {
      this.isTabChildrenPopoverOpen = true;

      this.tabChildrenPopover.event = event;

      this.view.openTab = appPage;
      this.view.openTabIndex = index;
    } else {
      return this.onMenuToggleClick(appPage, index);
    }
  }

  async onUserClicked() {
    this.isProfileSettingsPopoverOpen = false;

    const changeAccountModal: any = await this.modalService.create({
      component: ChangeAccountPage,
      componentProps: {
        accountsService: this.accounts,
      },
      animated: true,
      canDismiss: true,
      presentingElement: document.getElementById('ion-router-outlet-content'),
      cssClass: 'defaultModal'
    });

    this.modalService.present(changeAccountModal);
  }

  async onUserLoggedIn() {
    this.loadUser();
    this.menu.enable();
    this.calculateSidebarPosition();
  }

  async onUserUpdated() {
    this.avatarUrl = this.userService.getAvatarUrl();
    this.loadUser();

    this.calculateSidebarPosition();
    this.languages.applyDisplayLanguage();

    if (this.user && this.user.uid) {
      this.menu.enable();
      this.intro.loadedSliderPage(true);
      this.team = (await this.teams.getCurrentTeam() || {});

      if (this.userService.isType("Admin")) {
        this.admin.init();
      }

      if (!this.tools.isWeb()) {
        this.initPushNotifications();
      }

      try {
        this.push.sendTag("type", this.userService.getType());
      } catch (e) {
        console.warn("updating tag error", e);
      }
    }
  }

  async projectChanged(project: project | null) {
    this.appPages = [];
    this.project = project;

    this.initAppPages(true);
  }

  refreshInbox() {
    this.inbox.refresh().catch((error) => {
      console.warn("inbox error", error);
    });
  }

  reorderSidebar(event: any | null = null) {
    if (event) {
      this.appPages = event.detail.complete(this.appPages);
    }
  }

  runSearch(event: any | null = null) {

    if (event && !!event.target) {
      this.search.query = `${event.target.value}`;
    }

    if (!this.search || !this.search.query || !this.search.query.length) {
      delete this.view.search_results;
      return false;
    }

    new Promise((resolve, reject) => {
      let _index: number = 0, allPages: appPage[] = [].concat(this.appPages);

      if (this.view.allPages && this.view.allPages.length) {
        resolve(this.view.allPages);
      } else
        if (this.appPages && this.appPages.length) {
          this.appPages.forEach(async (appPage: appPage) => {

            this.view.appPagesKey = appPage.key;

            let childKeys: string[] = [appPage.key],
              children: appPage[] = await this.menu.getAppPages(this.view.appPagesKey);

            if (children && children.length) {
              allPages = allPages.concat(children);

              children.forEach(async (child: appPage) => {
                this.view.appPagesKey = child.key;
                let subChilds: appPage[] = await this.menu.getAppPages(this.view.appPagesKey);
                childKeys.push(child.key);

                if (subChilds && subChilds.length) {
                  allPages = allPages.concat(subChilds);
                  subChilds.forEach((subChild: appPage) => {
                    childKeys.push(subChild.key);
                  });
                }
              });
            }

            this.translate.get(childKeys)
              .subscribe((translations: any) => {
                _index++;

                allPages.forEach((page: appPage) => {
                  page.label = page.label || (translations[page.key] || page.key);
                });

                if (_index === this.appPages.length) {
                  this.view.allPages = this.tools.unique(allPages, ['key']);
                  resolve(this.view.allPages);
                }

              });
          });
        } else {
          resolve([]);
        }
    })
      .then((allPages: appPage[]) => {

        const foundPages: appPage[] = allPages.filter((appPage: appPage) => {
          return (
            (`${appPage.key}`.indexOf(`${this.search.query}`.toLowerCase()) !== -1) ||
            (`${appPage.label}`.toLowerCase().indexOf(`${this.search.query}`.toLowerCase()) !== -1)
          );
        });

        this.view.search_results = foundPages;
      });
  }

  async selectGlobalCreateItem(item: any, event: any) {
    const validate: boolean = await this.validateMenuClickPermission(item);

    if (!validate) {
      return false;
    }

    if (!!item.url) {
      this.navCtrl.navigateForward(`${item.url}`, { animated: false });
    }

    this.isCreatePopoverOpen = false;
    this.menu.close();

    return false;
  }

  showCreatePopover(e: Event) {
    this.createPopover.event = e;
    this.isCreatePopoverOpen = true;
  }

  showDani() {
    this.view.dani.state.hidden = false;
  }

  showProfileSettingsPopover(e: Event) {
    this.profileSettingsPopover.event = e;
    this.isProfileSettingsPopoverOpen = true;
  }

  startInboxInterval() {
    const time: number = (this.inboxIntervalTime || 60 * 1000 * 2);

    if (this.inboxInterval) {
      clearInterval(this.inboxInterval);
    }

    if (this.user && this.user.uid) {
      this.refreshInbox();

      this.inboxInterval = setInterval(() => {
        this.refreshInbox();
      }, time);
    }
  }

  submitBlog() {
    this.browser.create(this.urls.submit_blog);
    if (window.innerWidth <= 768) {
      this.menu.close();
    }
    this.modalService.closeAll();
  }

  support() {
    this.browser.create('mailto:support@thepipelinemag.com');
  }

  teamPhotoLoadingFailed() {
    this.team.photo = this.fallbackImg;
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  async up() {
    const menuName: string = ((this.view.currentMenuItem === 'creator_studio' ? 'main' : this.view.currentMenuItem) || 'main');
    const parentMenuName: string | null = (await this.menu.getParentMenuName(menuName) || 'main');

    this.view.appPagesKey = parentMenuName || 'main';
    this.appPages = await this.menu.getAppPages(this.view.appPagesKey);

    this.view.appPagesKeys = await this.menu.getAppPagesKeys();
    this.view.currentMenuItem = (parentMenuName === 'main' ? 'creator_studio' : parentMenuName);
    this.view.parentMenuName = parentMenuName;

    this.calcSubMode();

    this.modalService.closeAll();

    this.navCtrl.navigateRoot('/dashboard');
  }

  userPhotoLoadingFailed() {
    this.user.photo = this.fallbackAvatarImg;
  }

  async validateMenuClickPermission(data: any) {
    let bl: boolean = false;

    try {
      if (!!this.appConfig.useAbonnements && data.hasOwnProperty('requiresAboExtension') && !!data.requiresAboExtension) {
        bl = await this.abo.hasMatchingAbonnement(data.requiresAboExtension);

        if (!bl) {

          //this.abo.showAboPrompt(data.requiresAboExtension);
          //this.abo.setRequiredAbonnement(data.requiresAboExtension);

          this.menuData.visible = false;
          this.navCtrl.navigateRoot('/abonnements');
        }

      } else {
        bl = true;
      }
    } catch (e) {
      console.warn('checking subscribed state failed', e);
    }

    return bl;
  }

  validateUser() {
    this.loadUser();

    this.authService.validateUser(this.user, false)
      .then(() => {
        this.initAppPages();
        this.initTesting();
        this.initWallet();
      })
      .catch((error: any) => {
        console.error('app-component: validateUser: error', error);
        if (!!this.user && !!this.user.uid && (this.user.uid !== -1)) {
          this.events.publish('error', error);
        }
      });
  }

  viewModeChanged() {
    this.calcViewVars();
  }

  async viewTeam() {
    this.isProfileSettingsPopoverOpen = false;

    this.teams.detailItem(this.team as team);

    const editModal: any = await this.modalService.create({
      component: TeamPage,
      componentProps: {
        team: this.team,
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    this.modalService.present(editModal);
  }

}