import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from "src/app/services/core/events.service";

// native spline viewer
import { Application } from '@splinetool/runtime';

@Component({
  selector: 'pipeline-spline-viewer',
  standalone: false,
  templateUrl: './spline-viewer.component.html',
  styleUrls: ['./spline-viewer.component.scss'],
})
export class SplineViewerComponent implements OnDestroy, OnInit {
  @Input() options: splineOptions;

  private resizeObserver: ResizeObserver;

  @ViewChild('splineCanvas', { read: ElementRef }) private canvas: ElementRef;

  app: any;

  appConfig: pipelineAppConfig;

  blocked: boolean = false;

  lastZoom: number;

  view: any = {
    path: './assets/spline/dani/dani_idle.splinecode',
  };

  constructor(
    private configService: ConfigService,
    private elementRef: ElementRef,
    private events: EventsService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  error(event: any | null = null) {
    console.warn('error', event);
  }

  initEvents() {
    this.events.subscribe('window:resized', () => {
      this.updateAnimation();
    });
  }

  initSpline() {
    setTimeout(() => {

      if (!this.canvas || !this.canvas.nativeElement) {
        return false;
      }

      // native spline viewer
      this.app = new Application(this.canvas.nativeElement);

      this.loadSplineScene();
    });
  }

  async loadSplineScene() {

    if (!this.app || !!this.blocked) {
      return false;
    }

    // update spline scene if path is different
    if (!!this.view.path && (this.view.path !== this.view.last_path)) {
      this.blocked = true;

      await this.app.load(
        this.view.path,
        undefined,
        {
          credentials: 'include',
          mode: 'no-cors',
        }
      );

      this.blocked = false;
      this.view.last_path = `${this.view.path}`;
    }

    // update zoom if setting is set
    if (!!this.app && !!this.options && !!this.options.zoom) {
      this.app.setZoom(this.options.zoom);
    }

    return this.app;
  }

  ngAfterViewInit() {
    this.resizeObserver.observe(this.elementRef.nativeElement);
  }

  ngOnChanges(changes: any) {
    if (!!changes && !!changes.options && !!changes.options.currentValue) {
      this.updateAnimation(changes.options.currentValue);
    }
  }

  ngOnDestroy() {

    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }

    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  ngOnInit() {
    this.initEvents();
    this.initSpline();

    this.resizeObserver = new ResizeObserver(() => {
      this.updateAnimation();
    });
  }

  public play() {
    return this.app.play();
  }

  public setSize(width: number, height: number) {
    console.log('spline-viewer: setSize', width, height);

    try {
      return this.app.setSize(width, height);
    } catch (e) {
      return e;
    }
  }

  public setZoom(zoom: number) {

    if (zoom === this.lastZoom) {
      return false;
    }

    try {

      this.options = {
        ...(this.options || {}),
        zoom: zoom,
      };

      if (!this.app) {
        return false;
      }

      const exec: any = this.app.setZoom(zoom);

      this.lastZoom = zoom;

      return exec;
    } catch (e) {
      console.warn('setting zoom failed', e);
      return e;
    }
  }

  public stop() {
    try {
      return this.app.stop();
    } catch (e) {
      return e;
    }
  }

  public async updateAnimation(options: any | null = null) {

    if (!!options) {
      this.options = options;
    }

    // @debug: tmp fix: flip options variables
    options = this.options;
    this.options = options || {};

    if (!!this.options.path || !!this.options.url) {
      this.view.path = (this.options.path || this.options.url);
      await this.loadSplineScene();
    }

    if (!!this.options.zoom) {
      await this.setZoom(this.options.zoom);
    }

    return this.app;
  }

}