<!-- Field type picker -->
<ion-popover #fieldTypePopover [isOpen]="isFieldTypePopoverOpen" (didDismiss)="isFieldTypePopoverOpen = false">
  <ng-template>

    <ion-list *ngIf="!!view.fieldTypes && !!view.fieldTypes.length">

      <!-- Field ID -->
      <ion-item>
        <ion-input [(ngModel)]="view.field.uid" [label]="'field_uid'|translate" labelPlacement="floating"
          [placeholder]="'field_uid'|translate"></ion-input>
      </ion-item>

      <!-- Type -->
      <ion-item [disabled]="!!view.loading">
        <ion-select [(ngModel)]="view.field.type" [label]="'type'|translate" labelPlacement="floating"
          interface="popover" [okText]="'okay'|translate" [cancelText]="'cancel'|translate"
          (ionChange)="onFieldsChanged($event)">
          <ion-select-option *ngFor="let type of view.fieldTypes" [innerHTML]="type.name|translate"
            [value]="type.uid"></ion-select-option>
        </ion-select>
      </ion-item>

      <!-- Max length -->
      <ion-item>
        <ion-input [(ngModel)]="view.field.max_length" [label]="'max_length'|translate" labelPlacement="floating"
          (ionInput)="onFieldsChanged($event)" [placeholder]="'max_length'|translate"></ion-input>
      </ion-item>

      <!-- Is field required? -->
      <ion-item>
        <ion-checkbox justify="start" labelPlacement="end" [(ngModel)]="view.field.required"
          (ionChange)="onFieldsChanged($event)" [innerHTML]="'required'|translate"></ion-checkbox>
      </ion-item>

      <!-- Delete field -->
      <ion-item button (click)="deleteField(view.field)">
        <ion-icon color="danger" name="trash-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'delete'|translate"></ion-label>
      </ion-item>

    </ion-list>
  </ng-template>
</ion-popover>

<ion-grid>

  <!-- Top row -->
  <ion-row [style.width]="(!!view.previewGridWidth ? (view.previewGridWidth + 50) + 'px' : '100%')"
    [class.has-multiple-cols]="!!fields && (fields.length > 1)">

    <!-- first header col is empty -->
    <ion-col></ion-col>

    <!-- Iterate fields (top header bar )-->
    <ion-col *ngFor="let field of fields">
      <ion-item lines="none">

        <!-- Field select: View mode -->
        <ion-select *ngIf="mode === 'view'" [(ngModel)]="field.name"
          [label]="((field.name || field.uid) || 'field_name')|translate" labelPlacement="floating" interface="popover"
          [okText]="'okay'|translate" [cancelText]="'cancel'|translate">
          <ion-select-option [innerHTML]="'all'|translate" [value]="''"></ion-select-option>
          <ion-select-option *ngFor="let option of field.options" [innerHTML]="option"
            [value]="option"></ion-select-option>
        </ion-select>

        <!-- Field input: Edit mode -->
        <ion-input [(ngModel)]="field.name" [placeholder]="'field_name'|translate" (ionInput)="onFieldsChanged($event)"
          *ngIf="mode === 'create' || mode === 'edit'"></ion-input>

        <ion-button (click)="pickFieldType(field, $event)" fill="clear" icon-only color="dark"
          *ngIf="mode === 'create' || mode === 'edit'">
          <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
        </ion-button>

      </ion-item>
    </ion-col>

    <!-- Add field button -->
    <ion-col *ngIf="mode === 'create' || mode === 'edit'">
      <ion-item button lines="none" (click)="addField($event)">
        <ion-icon name="add-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'add_field'|translate"></ion-label>
      </ion-item>
    </ion-col>

  </ion-row>

  <!-- Iterate items -->
  <ion-row *ngFor="let item of items"
    [style.width]="(!!view.previewGridWidth ? (view.previewGridWidth + 50) + 'px' : '100%')"
    [class.has-multiple-cols]="!!fields && (fields.length > 1)">

    <!-- single selection -->
    <ion-col>
      <ion-item lines="none">
        <ion-checkbox [(ngModel)]="item.checked" (ionChange)="onItemCheckboxClicked(item)"></ion-checkbox>
      </ion-item>
    </ion-col>

    <!-- Iterate fields -->
    <ion-col *ngFor="let field of fields; let i = index;">

      <ion-item lines="none">

        <!-- Input based on field name -->
        <ion-input *ngIf="!itemHasValueObject && !field.uid" [(ngModel)]="item[field]" (ionInput)="onItemInputChanged($event, i)"
          [disabled]="mode !== 'edit'"></ion-input>

        <!-- Input based on field uid -->
        <ion-input *ngIf="!itemHasValueObject && !!field.uid" [(ngModel)]="item[field.uid]" (ionInput)="onItemInputChanged($event, i)"
          [disabled]="mode !== 'edit'"></ion-input>

        <!-- Input based on field name (in value object)-->
        <ion-input *ngIf="!!itemHasValueObject && !field.uid" [(ngModel)]="item.value[field]" (ionInput)="onItemInputChanged($event, i)"
          [disabled]="mode !== 'edit'"></ion-input>

        <!-- Input based on field uid (in value object)-->
        <ion-input *ngIf="!!itemHasValueObject && !!field.uid" [(ngModel)]="item.value[field.uid]" (ionInput)="onItemInputChanged($event, i)"
          [disabled]="mode !== 'edit'"></ion-input>

      </ion-item>

    </ion-col>

  </ion-row>

  <!-- No entries -->
  <pipeline-no-entries-card *ngIf="!!items && !items.length" [hidden]="!!view.loading"></pipeline-no-entries-card>

</ion-grid>