<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-back-button *ngIf="!view.isModal && (view.mode !== 'edit')"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view"
        *ngIf="!!view.isModal || (view.mode === 'edit')"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-title [hidden]="!view.isDesktop" [innerHTML]="view.title|translate"></ion-title>

    <ion-buttons slot="end">

      <!-- Create -->
      <ion-button (click)="create()" *ngIf="view.mode == 'create' || (media && !media.uid)" fill="solid" shape="round"
        color="primary">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'create'|translate"></ion-label>
      </ion-button>

      <!-- Edit -->
      <ion-button (click)="edit()" *ngIf="view.mode !== 'edit' && (!!media && !!media.uid)" fill="clear" color="dark">
        <ion-icon name="brush-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'edit'|translate"></ion-label>
      </ion-button>

      <!-- Update -->
      <ion-button (click)="update()" *ngIf="(view.mode == 'edit' || view.mode == 'view') && (!!media && !!media.uid)"
        [disabled]="!view.hasChanges" fill="solid" shape="round" color="primary">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'update'|translate"></ion-label>
      </ion-button>

    </ion-buttons>
  </ion-toolbar>

</ion-header>

<ion-content>

  <!-- Download popover -->
  <ion-popover #downloadPopover [isOpen]="isDownloadPopoverOpen" (didDismiss)="isDownloadPopoverOpen = false">
    <ng-template>
      <ion-content>

        <ion-radio-group [(ngModel)]="view.downloadFormat"
          *ngIf="!!view.downloadOptions && !!view.downloadOptions.formats">

          <ion-item button *ngFor="let format of view.downloadOptions.formats"
            (click)="downloadInFormat(format, $event)">
            <ion-icon name="document-outline" slot="start"></ion-icon>
            <ion-radio value="space-between" justify="space-between" [value]="format">
              <ion-label [innerHTML]="format|translate"></ion-label>
            </ion-radio>
          </ion-item>

        </ion-radio-group>

      </ion-content>
    </ng-template>
  </ion-popover>

  <!-- Single media item information popover -->
  <pipeline-media-item-info-popover></pipeline-media-item-info-popover>

  <!-- Navigate back -->
  <ion-button *ngIf="!!mediaList && !!mediaList.length" fill="clear" color="primary" shape="round" class="nav-btn back"
    icon-only [disabled]="!view.canNavigateBack" (click)="navBack()">
    <ion-icon name="chevron-back-outline"></ion-icon>
  </ion-button>

  <!-- Navigate next -->
  <ion-button *ngIf="!!mediaList && !!mediaList.length" fill="clear" color="primary" shape="round" class="nav-btn next"
    icon-only [disabled]="!view.canNavigateNext" (click)="navNext()">
    <ion-icon name="chevron-forward-outline"></ion-icon>
  </ion-button>

  <div class="container container-fluid" [class.has-nav]="!!mediaList && !!mediaList.length">

    <!-- Mobile intro card -->
    <pipeline-intro-card *ngIf="!view.isDesktop && !view.loading" [hidden]="introCard.hidden" class="introCard"
      [(view)]="introCard"></pipeline-intro-card>

    <ion-grid>

      <ion-row>

        <!-- Sidebar -->
        <ion-col class="sidebar" [size]="view.isDesktop ? 3 : 12">

          <div class="sidebar-inner">

            <!-- General -->
            <ion-card class="sidebar-accordion-card">

              <ion-card-header>

                <ion-card-title [innerHTML]="'general'|translate"></ion-card-title>

                <ion-button *ngIf="!!cards && !!cards.general" class="card-toggle" (click)="toggleCard('general')"
                  icon-only color="dark" fill="clear">
                  <ion-icon
                    [name]="!!cards && !!cards.general && cards.general.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                </ion-button>

              </ion-card-header>

              <ion-list [hidden]="!!cards && !!cards.general && !cards.general.open">

                <!-- Media title -->
                <ion-item lines="none">
                  <ion-icon name="text-outline" slot="start"></ion-icon>
                  <ion-input type="text" [(ngModel)]="media.title" [label]="'title'|translate" labelPlacement="stacked"
                    [placeholder]="'media_title'|translate" (ionChange)="hasChanges()"
                    (ionInput)="hasChanges()"></ion-input>
                </ion-item>

                <!-- Post date -->
                <ion-item [hidden]="!media.guid">
                  <ion-icon name="calendar-outline" slot="start"></ion-icon>
                  <ion-label class="ion-text-wrap">
                    <h3 [innerHTML]="'date'|translate"></h3>
                    <p [innerHTML]="media.post_date|date:'dd.MM.y HH:mm'"></p>
                  </ion-label>
                </ion-item>

                <!--
                <ion-item [hidden]="!media.guid" lines="none">
                  <ion-icon name="link-outline" slot="start"></ion-icon>

                  <ion-label class="ion-text-wrap">
                    <h3 [innerHTML]="'url'|translate"></h3>

                    <p *ngIf="media.post_mime_type === 'image'" [innerHTML]="media.thumbnail || media.guid"></p>
                    <p *ngIf="media.post_mime_type === 'video'" [innerHTML]="media.videoSrc || media.guid"></p>

                  </ion-label>

                </ion-item>
                -->

                <!-- Resolution / Upscaling -->
                <ion-item
                  *ngIf="!!view.attributesConfig && !!view.attributesConfig.height && !!!!view.attributesConfig.width">

                  <ion-icon name="tv-outline" slot="start"></ion-icon>

                  <ion-label class="ion-text-wrap">
                    <h3 [innerHTML]="'resolution'|translate"></h3>
                    <p [innerHTML]="view.attributesConfig.width + ' x ' + view.attributesConfig.height"></p>
                  </ion-label>

                  <!-- Upscale -->
                  <ion-button (click)="aiTools_upscale()" *ngIf="media.type === 'image' || media.type === 'video'"
                    [hidden]="!!view.attributesConfig && ((view.attributesConfig.height > 1024) && (view.attributesConfig.width > 1024))"
                    slot="end" fill="outline" color="primary">
                    <ion-label [innerHTML]="'ai_image_upscale'|translate"></ion-label>
                  </ion-button>

                </ion-item>

                <!-- Aspect ratio -->
                <ion-item *ngIf="!!view.attributesConfig && !!view.attributesConfig.aspect_ratio">

                  <ion-icon name="resize-outline" slot="start"></ion-icon>

                  <ion-label class="ion-text-wrap">
                    <h3 [innerHTML]="'aspect_ratio'|translate"></h3>
                    <p [innerHTML]="view.attributesConfig.aspect_ratio"></p>
                  </ion-label>

                </ion-item>

              </ion-list>

            </ion-card>

            <!-- Media actions -->
            <ion-card *ngIf="!!media" class="sidebar-accordion-card">
              <ion-grid class="btn-actions-grid" [hidden]="!media.guid">
                <ion-row>

                  <!-- Analyse -->
                  <ion-col [size]="view.isDesktop ? 6 : 3">

                    <ion-button (click)="analyseMedia()" size="block" color="dark" fill="clear"
                      [disabled]="!!view.analyzing">
                      <ion-spinner [hidden]="!view.analyzing"></ion-spinner>
                      <ion-icon [hidden]="!!view.analyzing" name="search-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'analyze'|translate" [hidden]="!view.isDesktop"></ion-label>
                    </ion-button>

                  </ion-col>

                  <!-- Copy url -->
                  <ion-col [size]="view.isDesktop ? 6 : 3">
                    <ion-button size="block" (click)="copyUrl()" fill="clear" color="dark" class="ion-text-wrap">
                      <ion-icon name="copy-outline" slot="start"></ion-icon>
                      <ion-label class="ion-text-wrap" [innerHTML]="'copy_url'|translate"
                        [hidden]="!view.isDesktop"></ion-label>
                    </ion-button>
                  </ion-col>

                  <!-- Download -->
                  <ion-col [size]="view.isDesktop ? 6 : 3">
                    <ion-button size="block" (click)="download($event)" fill="clear" color="dark" class="ion-text-wrap">
                      <ion-icon name="download-outline" slot="start"></ion-icon>
                      <ion-label class="ion-text-wrap" [innerHTML]="'download'|translate"
                        [hidden]="!view.isDesktop"></ion-label>
                    </ion-button>
                  </ion-col>

                  <!-- Delete -->
                  <ion-col [size]="view.isDesktop ? 6 : 3">
                    <ion-button size="block" (click)="delete()" color="danger" fill="clear" class="ion-text-wrap">
                      <ion-icon name="trash-outline" slot="start"></ion-icon>
                      <ion-label class="ion-text-wrap" [innerHTML]="'delete'|translate"
                        [hidden]="!view.isDesktop"></ion-label>
                    </ion-button>
                  </ion-col>

                </ion-row>
              </ion-grid>
            </ion-card>

            <!-- Caption ( desktop )-->
            <ion-card [hidden]="!view.isDesktop" *ngIf="!!view.attributesConfig && !!view.attributesConfig.caption"
              class="sidebar-accordion-card">

              <ion-card-header>

                <ion-card-title [innerHTML]="'caption'|translate"></ion-card-title>

                <ion-button *ngIf="!!cards && !!cards.caption" class="card-toggle" (click)="toggleCard('caption')"
                  icon-only color="dark" fill="clear">
                  <ion-icon
                    [name]="!!cards && !!cards.caption && cards.caption.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                </ion-button>

              </ion-card-header>

              <ion-list [hidden]="!!cards && !!cards.caption && !cards.caption.open">

                <ion-item lines="none">
                  <ion-label class="ion-text-wrap">
                    <p [innerHTML]="view.attributesConfig.caption"></p>
                  </ion-label>
                </ion-item>

              </ion-list>

            </ion-card>

            <!-- Masks ( desktop )-->
            <ion-card [hidden]="!view.isDesktop || !view.isDesktop" *ngIf="!!view.attributesConfig"
              class="sidebar-accordion-card">

              <ion-card-header>

                <ion-card-title [innerHTML]="'masks'|translate"></ion-card-title>

                <ion-button *ngIf="!!cards && !!cards.masks" class="card-toggle" (click)="toggleCard('masks')" icon-only
                  color="dark" fill="clear">
                  <ion-icon
                    [name]="!!cards && !!cards.masks && cards.masks.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                </ion-button>

                <div [hidden]="!!cards && !!cards.masks && !cards.masks.open">

                  <ion-grid class="masks-grid">
                    <ion-row>

                      <!-- Mask -->
                      <ion-col size="4">

                        <ion-card>

                          <ion-button icon-only *ngIf="!view.attributesConfig || !view.attributesConfig.mask"
                            (click)="createMask('mask')" size="block" fill="clear" color="primary">
                            <ion-icon name="add-outline"></ion-icon>
                          </ion-button>

                          <ion-thumbnail *ngIf="!!view.attributesConfig && !!view.attributesConfig.mask"
                            (click)="viewMask()">
                            <ion-img [src]="view.attributesConfig.mask"></ion-img>
                          </ion-thumbnail>

                          <p [innerHTML]="'mask'|translate"></p>

                        </ion-card>

                      </ion-col>

                      <!-- Depth Estimation -->
                      <ion-col size="4">

                        <ion-card>

                          <ion-button icon-only
                            *ngIf="!view.attributesConfig || !view.attributesConfig.depth_estimation"
                            (click)="createMask('depth_estimation')" size="block" fill="clear" color="primary">
                            <ion-icon name="add-outline"></ion-icon>
                          </ion-button>

                          <ion-thumbnail *ngIf="!!view.attributesConfig && !!view.attributesConfig.depth_estimation"
                            (click)="viewMask(view.attributesConfig.depth_estimation)">
                            <ion-img [src]="view.attributesConfig.depth_estimation"></ion-img>
                          </ion-thumbnail>

                          <p [innerHTML]="'depth_estimation'|translate"></p>

                        </ion-card>

                      </ion-col>

                      <!-- Segmentation -->
                      <ion-col size="4">

                        <ion-card>

                          <ion-button icon-only *ngIf="!view.attributesConfig || !view.attributesConfig.segmentation"
                            (click)="createMask('segmentation')" size="block" fill="clear" color="primary">
                            <ion-icon name="add-outline"></ion-icon>
                          </ion-button>

                          <ion-thumbnail *ngIf="!!view.attributesConfig && !!view.attributesConfig.segmentation"
                            (click)="viewMask(view.attributesConfig.segmentation)">
                            <ion-img [src]="view.attributesConfig.segmentation"></ion-img>
                          </ion-thumbnail>

                          <p [innerHTML]="'segmentation'|translate"></p>

                        </ion-card>

                      </ion-col>

                    </ion-row>
                  </ion-grid>

                </div>

              </ion-card-header>
            </ion-card>

            <!-- Audio transcription ( desktop )-->
            <ion-card *ngIf="!!view.attributesConfig && !!view.attributesConfig.audio_transcription"
              class="sidebar-accordion-card">

              <ion-card-header>

                <ion-card-title [innerHTML]="'audio_transcription'|translate"></ion-card-title>

                <ion-button *ngIf="!!cards && !!cards.audio_transcription" class="card-toggle"
                  (click)="toggleCard('audio_transcription')" icon-only color="dark" fill="clear">
                  <ion-icon
                    [name]="!!cards && !!cards.audio_transcription && cards.audio_transcription.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                </ion-button>

              </ion-card-header>

              <ion-list [hidden]="!!cards && !!cards.audio_transcription && !cards.audio_transcription.open">

                <ion-item lines="none">
                  <ion-label class="ion-text-wrap">
                    <p [innerHTML]="view.attributesConfig.audio_transcription"></p>
                  </ion-label>
                </ion-item>

              </ion-list>

            </ion-card>

            <!-- Sidebar (only on desktop, above on mobile)-->
            <div *ngIf="!!view.isDesktop">

              <!-- Media tags -->
              <pipeline-item-tags-card [(cards)]="cards" [(media)]="media" [(view)]="view"></pipeline-item-tags-card>

              <!-- Captions -->
              <pipeline-caption-generator [(cards)]="cards" [(media)]="media" [(view)]="view"
                (captionChanged)="onCaptionChanged($event)"></pipeline-caption-generator>

              <!-- Media attributes -->
              <pipeline-media-attributes-card *ngIf="!view.rebuild && !view.loading" [(cards)]="cards" [(media)]="media"
                [(view)]="view"></pipeline-media-attributes-card>

              <!-- Voiceover -->
              <pipeline-voiceover-card
                *ngIf="(view.mode === 'edit') && !!media && (media.type === 'audio' || media.type === 'video')"
                [(cards)]="cards" [options]="{ input: (view.audioInputText || '')}"
                (settingsChanged)="onVoiceoverSettingsChanged($event)" [(view)]="view"></pipeline-voiceover-card>

            </div>

          </div>

        </ion-col>

        <!-- Preview -->
        <ion-col #preview id="preview" class="preview" [size]="view.isDesktop ? (!!view.expertMode ? 6 : 9) : 12">

          <!-- variations toolbar -->
          <ion-toolbar class="ion-no-border"
            *ngIf="!!view.variations && !!view.variations.length && (view.variations.length > 1)">
            <ion-segment scrollable [(ngModel)]="media.guid" (ionChange)="variationChanged()">
              <ion-segment-button *ngFor="let variation of view.variations; trackBy:trackItems"
                [value]="variation.guid">
                <ion-icon [name]="variation.icon || 'film-outline'"></ion-icon>
                <ion-label [innerHTML]="('comp_' + variation.composition)|translate"></ion-label>
              </ion-segment-button>
            </ion-segment>
          </ion-toolbar>

          <!-- Desktop intro card -->
          <pipeline-intro-card *ngIf="!!view.isDesktop" [hidden]="introCard.hidden" class="introCard"
            [(view)]="introCard"></pipeline-intro-card>

          <!-- History selection options -->
          <pipeline-selection-toolbar *ngIf="!!view.history && (view.history.length > 1)" [(items)]="view.history"
            [(view)]="view" [options]="selectionOptions" (selectedItemsChanged)="onSelectedItemsChanged($event)"
            (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

          <!-- chat history -->
          <div class="history-list-wrapper" *ngIf="!!view.history && !!view.history.length">

            <div class="history-list" *ngFor="let media of view.history; let i = index; trackBy:trackItems">

              <div class="history-item" [class.has-multiple]="view.history.length > 1">

                <ion-card class="preview-card"
                  *ngIf="!!media.thumbnail || !!media.photo || !!media.guid || !!media.videoSrc">

                  <!-- item top bar -->
                  <ion-item lines="none"
                    *ngIf="view.history.length !== 1 || (!!media && !!media.info) || (!!i || !!media.live_preview)">

                    <ion-checkbox [(ngModel)]="media.checked" justify="start" labelPlacement="end"
                      (ionChange)="onMediaItemChecked(media)" [hidden]="view.history.length === 1">
                      <p [innerHTML]="media.title"></p>
                    </ion-checkbox>

                    <!-- Item info -->
                    <ion-button *ngIf="!!media && !!media.info" slot="end" fill="clear" color="dark" icon-only
                      (click)="itemInfo(media, $event)">
                      <ion-icon name="information-circle-outline"></ion-icon>
                    </ion-button>

                    <ion-button slot="end" fill="clear" *ngIf="!!i || !!media.live_preview"
                      (click)="useAsPrimary(media, i)" color="dark" icon-only>
                      <ion-icon name="checkmark-outline"></ion-icon>
                    </ion-button>

                  </ion-item>

                  <ion-grid>
                    <ion-row>

                      <!-- Main col [size]="(((media.mode === 'inpaint') || (media.mode === 'outpaint'))) && !!view.isDesktop ? 6 : 12" -->
                      <ion-col size="12">

                        <ion-card-header [hidden]="media.mode !== 'inpaint'">
                          <ion-card-title [innerHTML]="'inpaint'|translate"></ion-card-title>
                        </ion-card-header>

                        <!-- Loading -->
                        <ion-spinner [hidden]="!view.loading"></ion-spinner>
                        <ion-skeleton-text class="image" [hidden]="!view.loading" animated></ion-skeleton-text>

                        <!-- Media Type: Image -->
                        <pipeline-image-editor *ngIf="media.post_mime_type === 'image'"
                          (onInpaintChanged)="onInpaintChanged($event)" [(media)]="view.history[i]" [(view)]="view">
                        </pipeline-image-editor>

                        <!-- Media Type: Video & Mode: View -->
                        <video *ngIf="media.post_mime_type === 'video' && (view.mode !== 'edit')" width="100%"
                          height="100%" loop controls autoplay playsinline webkit-playsinline
                          [src]="(media.videoSrc || media.guid)"
                          [poster]="(media.thumbnail || media.photo) || fallbackImg" muted="muted">
                          <!--<source [src]="media.videoSrc || media.guid" type="video/mp4" />-->
                          <!--<source [src]="(media.videoSrc || media.guid).replace('.mp4', '.webm')" type="video/webm" />-->
                        </video>

                        <!-- Media: Type: Video & Mode: Edit -->
                        <pipeline-video-editor *ngIf="media.post_mime_type === 'video' && (view.mode === 'edit')"
                          [(media)]="view.history[i]" [(view)]="view">
                        </pipeline-video-editor>

                        <!-- Media Type: Attachment -->
                        <iframe
                          *ngIf="!view.loading && (media.post_type === 'attachment') && ((media.post_mime_type !== 'image') && (media.post_mime_type !== 'video')) && (media.extension !== 'csv')"
                          class="attachment-iframe post-type-{{media.post_type}} post-mime-type-{{media.post_mime_type}}"
                          [src]="view.document_url || media.guid">
                        </iframe>

                        <!-- View Mode: Table (CSV) -->
                        <pipeline-table-view *ngIf="media.extension === 'csv'"
                          (fieldsChanged)="onTableFieldsChanged($event)" (itemsChanged)="onTableItemsChanged($event)"
                          [buttons]="tableButtons" [fields]="view.tableKeys" [(view)]="view" [method]="view.tableMethod"
                          [mode]="tableViewOptions.mode" [options]="tableViewOptions"
                          [selectionOptions]="tableSelectionOptions" [url]="media.guid">
                        </pipeline-table-view>

                        <!-- Variations -->
                        <ion-grid *ngIf="!!media.variations" class="variations-grid">
                          <ion-row>

                            <ion-col *ngFor="let variation of media.variations">
                              <ion-card (click)="viewVariation(variation)">

                                <ion-skeleton-text class="image" [hidden]="!!variation"></ion-skeleton-text>
                                <ion-spinner [hidden]="!!variation"></ion-spinner>

                                <ion-thumbnail [hidden]="!variation">
                                  <ion-img [src]="variation || fallbackImg"></ion-img>
                                </ion-thumbnail>

                              </ion-card>
                            </ion-col>

                          </ion-row>
                        </ion-grid>

                      </ion-col>

                    </ion-row>
                  </ion-grid>

                  <!-- Actions bar -->
                  <pipeline-media-item-actions-toolbar
                    [options]="{ showRateItemButtons: !!i, showUseAsPrimary: false, }" [(media)]="view.history[i]"
                    [view]="view"
                    (buttonClicked)="mediaItemActionsToolbarButtonClicked($event)"></pipeline-media-item-actions-toolbar>

                </ion-card>
              </div>

            </div>

          </div>

        </ion-col>

        <!-- Mobile sidebar (above expert desktop sidebar )-->
        <ion-col size="12" *ngIf="!view.isDesktop">

          <!-- Caption ( desktop )-->
          <ion-card *ngIf="!!view.attributesConfig && !!view.attributesConfig.caption" class="sidebar-accordion-card">

            <ion-card-header>

              <ion-card-title [innerHTML]="'caption'|translate"></ion-card-title>

              <ion-button *ngIf="!!cards && !!cards.caption" class="card-toggle" (click)="toggleCard('caption')"
                icon-only color="dark" fill="clear">
                <ion-icon
                  [name]="!!cards && !!cards.caption && cards.caption.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
              </ion-button>

            </ion-card-header>

            <ion-list [hidden]="!!cards && !!cards.caption && !cards.caption.open">

              <ion-item lines="none">
                <ion-label class="ion-text-wrap">
                  <p [innerHTML]="view.attributesConfig.caption"></p>
                </ion-label>
              </ion-item>

            </ion-list>

          </ion-card>

          <!-- Audio transcription ( desktop )-->
          <ion-card *ngIf="!!view.attributesConfig && !!view.attributesConfig.audio_transcription"
            class="sidebar-accordion-card">

            <ion-card-header>

              <ion-card-title [innerHTML]="'audio_transcription'|translate"></ion-card-title>

              <ion-button *ngIf="!!cards && !!cards.audio_transcription" class="card-toggle"
                (click)="toggleCard('audio_transcription')" icon-only color="dark" fill="clear">
                <ion-icon
                  [name]="!!cards && !!cards.audio_transcription && cards.audio_transcription.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
              </ion-button>

            </ion-card-header>

            <ion-list [hidden]="!!cards && !!cards.audio_transcription && !cards.audio_transcription.open">

              <ion-item lines="none">
                <ion-label class="ion-text-wrap">
                  <p [innerHTML]="view.attributesConfig.audio_transcription"></p>
                </ion-label>
              </ion-item>

            </ion-list>

          </ion-card>

        </ion-col>

        <!-- Statistics & comments -->
        <ion-col class="sidebar" [size]="view.isDesktop ? 3 : 12" *ngIf="!!media && !!media.uid"
          [hidden]="!view.expertMode">

          <!-- Statistics -->
          <pipeline-post-statistics-card [(cards)]="cards" [(post)]="media" type="media"
            [(view)]="view"></pipeline-post-statistics-card>

          <!-- Reactions -->
          <pipeline-reactions-toolbar *ngIf="!view.rebuild" [disabled]="true" [item]="media" type="media"
            [hidden]="!media || media.vip" mode="summarized" [(view)]="view"></pipeline-reactions-toolbar>

          <!-- Comments -->
          <ion-card class="sidebar-accordion-card comments-card" *ngIf="!!media && !!media.uid">

            <ion-card-header>

              <ion-card-title>
                <span [innerHTML]="'comments'|translate"></span>
              </ion-card-title>

              <ion-button [hidden]="!!cards && !!cards.comments && !cards.comments.open" class="second"
                (click)="syncComments()" icon-only color="dark" fill="clear">
                <ion-icon name="sync-outline"></ion-icon>
              </ion-button>

              <ion-button *ngIf="!!cards && !!cards.comments" class="card-toggle" (click)="toggleCard('comments')"
                icon-only color="dark" fill="clear">
                <ion-icon
                  [name]="!!cards && !!cards.comments && cards.comments.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
              </ion-button>

            </ion-card-header>

            <pipeline-comments-list [hidden]="!!cards && !!cards.comments && !cards.comments.open" *ngIf="!view.rebuild"
              type="media" [uid]="media.uid" [context]="media.post_content" [options]="commentsListOptions"
              (onChanged)="onCommentsChanged($event)"></pipeline-comments-list>

          </ion-card>

        </ion-col>

      </ion-row>

    </ion-grid>

    <!-- Mobile sidebar (below expert desktop sidebar )-->
    <div *ngIf="!view.isDesktop">

      <!-- Media tags -->
      <pipeline-item-tags-card [(cards)]="cards" [(media)]="media" [(view)]="view"></pipeline-item-tags-card>

      <!-- Captions -->
      <pipeline-caption-generator [(cards)]="cards" [(media)]="media" [(view)]="view"
        (captionChanged)="onCaptionChanged($event)"></pipeline-caption-generator>

      <!-- Media attributes -->
      <pipeline-media-attributes-card *ngIf="!view.rebuild && !view.loading" [(cards)]="cards" [(media)]="media"
        [(view)]="view"></pipeline-media-attributes-card>

      <!-- Voiceover -->
      <pipeline-voiceover-card
        *ngIf="(view.mode === 'edit') && !!media && (media.type === 'audio' || media.type === 'video')"
        [options]="{ input: (view.audioInputText || '')}"
        (settingsChanged)="onVoiceoverSettingsChanged($event)"></pipeline-voiceover-card>

    </div>

  </div>

</ion-content>

<ion-footer class="ion-no-border">

  <!-- ai input prompt (mobile) -->
  <pipeline-ai-prompt-card *ngIf="!view.isDesktop && (view.mode === 'edit')" type="media" [disabled]="!!view.loading"
    [(input)]="view.aiPrompt" (onSubmit)="runAiPrompt($event)"></pipeline-ai-prompt-card>

  <ion-toolbar class="container ion-no-border">

    <ion-buttons slot="start" *ngIf="!!mediaList && !!mediaList.length && !view.isDesktop && (view.mode !== 'edit')">

      <!-- Prev button on mobile -->
      <ion-button fill="clear" color="primary" shape="round" class="nav-btn back" icon-only
        [disabled]="!view.canNavigateBack" (click)="navBack()">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>

    </ion-buttons>

    <!-- ai input prompt (desktop) -->
    <pipeline-ai-prompt-card type="media" *ngIf="!!view.isDesktop && (view.mode === 'edit')" [disabled]="!!view.loading"
      (onSubmit)="runAiPrompt($event)"></pipeline-ai-prompt-card>

    <ion-buttons slot="end">

      <!-- Model -->
      <pipeline-ai-settings-picker [config]="aiSettings" context="image-to-image"
        [hidden]="view.expertMode === false || (view.mode !== 'edit')" [mini]="false" slot="end" [view]="view"
        (changed)="aiSettingsChanged($event)"></pipeline-ai-settings-picker>

      <!-- View mode picker -->
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>

      <!-- Next button on mobile -->
      <ion-button *ngIf="!!mediaList && !!mediaList.length" fill="clear" color="primary" shape="round"
        class="nav-btn next" icon-only [disabled]="!view.canNavigateNext" (click)="navNext()"
        [hidden]="!!view.isDesktop || (view.mode === 'edit')">
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>
</ion-footer>