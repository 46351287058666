import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { AiCoderService } from 'src/app/services/ai/ai-coder.service';

import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from "src/app/services/core/events.service";
import { ModalService } from 'src/app/services/core/modal.service';
import { ParamsService } from 'src/app/services/core/params.service';
import { ViewService } from 'src/app/services/core/view.service';
import { ClipboardService } from "src/app/services/utils/clipboard.service";

import { proxyUrl } from 'src/config/variables';

import { EditorComponentModule } from 'src/app/components/editors/editor/editor.component.module';

import { AiCoderPage } from 'src/app/pages/core/ai/ai-coder/ai-coder.page';

@Component({
  selector: 'pipeline-ai-coder-card',
  standalone: false,
  templateUrl: './ai-coder.component.html',
  styleUrls: ['./ai-coder.component.scss']
})
export class AiCoderComponent implements AfterViewInit, OnDestroy, OnInit {

  aiCoderProject: aiCoderProject = {
    active: true,
    files: [],
    icon: 'rocket-outline',
    name: '',
  };

  aiSettings: aiSettings = {
    context: 'text_generation',
    use_proxy: true,
  };

  aiSettingsOptions: aiSettingsOptions = {
    operations: [
      'text_generation'
    ],
  };

  appConfig: pipelineAppConfig;

  @Input() autostart: boolean = false;

  buttonAction: string;

  @Input() code: string;

  code_preview: any;

  @Input() codeLanguage: string;

  @Input() coding: boolean = false;

  @Input() colSize: number | null = null;

  @Input() disabled: boolean = false;

  @ViewChild(EditorComponentModule) editor: any;

  editorMode: string = 'code';

  fallbackImg: string = './assets/img/fallback.webp';

  generatingSimulation: boolean = false;

  @Input() hidden: boolean = false;

  @Input() input: string = '';

  introOverlayConfig: introOverlayConfig = {
    allowManually: true,
    input_placeholder: 'ai_coder_ai_helper_input_placeholder',
    headline: 'ai_coder_ai_helper_text',
    showAiCreate: true,
    showAiSettings: true,
    showInput: true,
  };

  @Input() output: string;

  progress: number = 0;

  proxyUrl: string;

  rebuild: boolean = false;

  response: string = '';

  @Input() segment: string = 'code';

  @Input() size: number = 12;

  view: any = {};

  constructor(
    private aiCoder: AiCoderService,

    private clipboard: ClipboardService,
    private configService: ConfigService,
    private domSanitizer: DomSanitizer,
    private events: EventsService,
    private modalService: ModalService,
    private paramsService: ParamsService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
    this.proxyUrl = proxyUrl;
  }

  aiCreate(options: any = {}) {
    options.init = options.init !== false;
    options.input = options.input || this.input;

    if (!options.input || !options.input.length) {
      return false;
    }

    this.aiCoderProject = this.aiCoderProject || {};
    this.aiCoderProject.name = this.aiCoderProject.name || `${options.input || ''}`;

    this.coding = true;
    this.progress = 0;

    const params: any = {
      history: (options.history || []),
    };

    this.startManually();

    this.aiCoder.executeCreateCode(options, params)
      .then(async (response: any) => {

        if (!!options.init) {
          this.aiCoderProject.files = (!!response && !!response.files ? response.files : []);
        }

        if (!!response && !!response.output) {
          this.handleProvidedOutput(response.output);
        }

        this.coding = false;
        this.calcViewVars();
      })
      .catch((error: any) => {
        this.coding = false;
        this.events.publish('error', error);
      });
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  copyText(text: string | null = null) {

    if (!text || !text.length) {
      text = (this.segment === 'preview' ? this.code_preview : this.code);
    }

    if (!text || !text.length) {
      return false;
    }

    this.clipboard.copyText(text);
  }

  async handleProvidedOutput(output: string) {
    const contentLines: string[] = `${output || ''}`.split("\n");
    const blShouldBeautify: boolean = `${contentLines[0] || ''}`.indexOf('`') === 0;

    let outputFormat: string = `${contentLines[0] || ''}`.replace('```', '');
    let initCode: string | null = output;

    if (!outputFormat && !!initCode && (
      (initCode.toLocaleLowerCase().indexOf('<!doctype') !== -1) ||
      (initCode.toLocaleLowerCase().indexOf('<html') !== -1))
    ) {
      outputFormat = 'html';
    }

    // detect code in output and beautify it
    if (blShouldBeautify) {
      initCode = `${output || ''}`.replace(`${contentLines[0]}\n`, '').replace('```', '');
    }

    initCode = `${initCode || ''}`.replace('```html', '').replace('```', '');

    if (!!initCode) {

      if (!!this.aiSettings.use_proxy) {
        this.code = this.aiCoder.addProxyUrlToResources(initCode || '');
      } else {
        this.code = this.aiCoder.removeProxyUrlToResources(initCode || '');
      }

      this.codeLanguage = (outputFormat || 'html');
      this.coding = false;

      this.updateEditor();

      switch (outputFormat) {
        case 'html':
          this.code_preview = this.domSanitizer.bypassSecurityTrustHtml(`${this.code}`);
          break;
        default:

          this.generatingSimulation = true;

          const simulate: any = await this.aiCoder.simulate(`${this.code}`);
          console.log('ai-coder: simulate response', simulate);

          this.generatingSimulation = false;

          if (!!simulate && !!simulate.output) {
            this.code_preview = this.domSanitizer.bypassSecurityTrustHtml(`${simulate.output}`);
          } else {
            this.code_preview = this.domSanitizer.bypassSecurityTrustHtml(`${this.code}`);
          }

          break;
      }

      this.coding = false;
      this.buttonAction = 'rebuild';
    }
  }

  initEvents() {
    this.view.events = {};
  }

  async makeProject() {
    console.log('makeProject: code', this.code);
    console.log('makeProject: codeLanguage', this.codeLanguage);

    if (!this.code || !this.code.length) {
      return false;
    }

    const code: any = {
      post_content: this.code,
    };

    this.paramsService.set('viewData_createCode', {
      code: code,
    });

    const modal: any = await this.modalService.create({
      component: AiCoderPage,
      componentProps: {
        autostart: true,
        code: this.code,
        codeLanguage: this.codeLanguage,
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal',
    });

    modal.onWillDismiss().then((data: any) => {
      console.warn('create_post from dani: dismissed', data);
    });

    this.modalService.present(modal);
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.calcViewVars();
    this.initEvents();

    if (!!this.autostart && !!this.output && !!this.output.length) {
      this.startManually();
      this.handleProvidedOutput(this.output);
    } else
      if (!!this.autostart && !!this.input) {
        this.aiCreate();
      }
  }

  onEditorInputChanged(value: string, key: string = 'input') {
    this.code = `${value || ''}`;
  }

  refreshView() {
    return this.aiCreate();
  }

  segmentChanged() {

  }

  startManually() {
    this.view.startManually = true;
  }

  updateEditor() {
    try {
      if (!!this.editor) {
        this.editor.setInput(this.code);
      }
    } catch (e) {
      console.warn('updating editor failed', e);
    }
  }

}