import { AfterContentInit, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AiBridgeService } from 'src/app/services/ai/ai-bridge.service';
import { AiToolsService } from 'src/app/services/ai/ai-tools.service';
import { AiVideosService } from 'src/app/services/ai/ai-videos.service';

import { AlertService } from 'src/app/services/core/alert.service';
import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ParamsService } from 'src/app/services/core/params.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { TranslationService } from "src/app/services/core/translation.service";
import { UserService } from 'src/app/services/core/user.service';
import { ViewService } from 'src/app/services/core/view.service';
import { DaniService } from 'src/app/services/getgenius/dani.service';
import { ElevenlabsService } from 'src/app/services/integrations/elevenlabs.service';
import { MediaextendService } from 'src/app/services/media/mediaextend.service';
import { RendleyService } from 'src/app/services/media/rendley.service';
import { StablediffusionService } from 'src/app/services/media/stablediffusion.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { ToolsService } from "src/app/services/utils/tools.service";

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';
import { ViewMediaPage } from 'src/app/pages/core/media/media/view-media/view-media.page';

import { VideoEditorComponent } from 'src/app/components/editors/video-editor/video-editor.component';
import { InfoPopoverComponent } from 'src/app/components/generic/info-popover/info-popover.component';
import { MediaItemInfoPopoverComponent } from 'src/app/components/media/media-item-info-popover/media-item-info-popover.component';

@Component({
  selector: 'app-ai-video-creator',
  standalone: false,
  templateUrl: './ai-video-creator.page.html',
  styleUrls: ['./ai-video-creator.page.scss'],
})
export class AiVideoCreatorPage implements AfterContentInit, OnDestroy, OnInit {

  aiSettings: aiSettings = {
    //auto_upscale: true,
    context: 'image_to_video',
    upscale_end_result: true,
    upscale_first_frame: false,
    fps: 24,
    //models: ['stabilityai/stable-diffusion-3-medium-diffusers'],
  };

  aiSettingsOptions: aiSettingsOptions = {
    operations: [
      'image_to_video',
      'text_generation',
      'text_to_image',
    ],
  };

  appConfig: pipelineAppConfig;

  aspect_ratios: any[] = [
    {
      icon: 'tablet-landscape-outline',
      uid: '16x9',
      name: 'aspect_ratio_16x9',
    },
    {
      checked: true,
      icon: 'tablet-landscape-outline',
      uid: '3x2',
      name: 'aspect_ratio_3x2',
      label: 'suggested',
      labelColor: 'primary',
    },
    {
      icon: 'square-outline',
      uid: '1x1',
      name: 'aspect_ratio_1x1',
    },
    {
      icon: 'tablet-portrait-outline',
      uid: '9x16',
      name: 'aspect_ratio_9x16',
    },
  ];

  cards: any = {
    information: { open: true },
    output: { open: true },
  };

  fallbackAvatarImg: string = './assets/img/avatars/1.webp';
  fallbackImg: string = 'https://getgenius.app/assets/img/fallback.webp';

  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;

  @ViewChild(InfoPopoverComponent) infoPopover: any;

  inputModes: any[] = [
    {
      icon: 'text-outline',
      uid: 'text',
      name: 'input_mode_text',
    },
    {
      icon: 'images-outline',
      uid: 'media',
      name: 'input_mode_media',
    }
  ];

  introCard: introCardConfig = {
    uid: 'ai_video_creator_top_card',
    lottieSrc: './assets/lottie/light_bulb.json',
    text: 'ai_video_creator_top_card_text',
    title: 'ai_video_creator_top_card_title',
  };

  introOverlayConfig: introOverlayConfig = {
    allowManually: true,
    groups: [

      /* Step 1: Campaign type(s) */
      {
        buttonHandler: (event: any) => {

          if (!event || !event.button || !event.button.hasOwnProperty('uid')) {
            return false;
          }

          this.view.iVideoLength = event.button.uid;
        },
        buttons: [],
        description: 'ai_video_creator_ai_helper_type_description',
        headline: 'ai_video_creator_ai_helper_type_title',
        multiple: false,
        required: true,
      },

      /* Step 2: Campaign type(s) */
      {
        buttonHandler: (event: any) => {

          if (!event || !event.button || !event.button.hasOwnProperty('uid')) {
            return false;
          }

          this.view.aspect_ratio = event.button.uid;
        },
        buttons: [],
        description: 'ai_video_creator_ai_helper_aspect_ratio_description',
        headline: 'ai_video_creator_ai_helper_aspect_ratio_title',
        multiple: false,
        required: true,
      },

      /* Step 3: Describe campaign */
      {
        buttons: [],
        description: 'ai_video_creator_ai_helper_prompt_text',
        headline: 'ai_video_creator_ai_helper_prompt_title',
        inputs: [
          {
            placeholder: 'ai_video_creator_ai_helper_prompt_input_placeholder',
            type: 'textarea',
          }
        ],
      },

      /* Step 4: Configuration / Options */
      {
        inputs: [
          {
            icon: 'language-outline',
            label: 'languages',
            multiple: true,
            type: 'select',
            uid: 'languages',
            values: [],
          },
        ],
        description: 'ai_video_creator_ai_helper_configuration_text',
        headline: 'ai_video_creator_ai_helper_configuration_title',
      },

      /* Step 5: Media */
      {
        buttons: [],
        components: [
          'MediaPicker'
        ],
        description: 'ai_video_creator_ai_helper_media_description',
        headline: 'ai_video_creator_ai_helper_media_title',
      },

    ],
    showAiCreate: true,
    showAiSettings: true,
    showInput: false,
    showSliderButtons: true,
  };

  introOverlayMediaPickerOptions: mediaPickerOptions = {
    allowAuto: true,
    hideDescription: true,
    hideHeader: true,
    showGenerateOptions: true,
  };

  item: any;

  @ViewChild(MediaItemInfoPopoverComponent) itemInfoPopover: any;

  media: mediaItem = {};

  search: searchOptions = {
    itemsKey: 'items',
    keys: ['title', 'description', 'name', 'url', 'uid'],
    query: '',
  };

  sectionIndex: number = 0;

  selectionOptions: selectionOption[] = [
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
    {
      icon: 'copy-outline',
      label: 'duplicate',
      uid: 'duplicate',
    }
  ];

  state: state = {};

  stateKeys: string[] = ['failed', 'waiting', 'validating', 'starting', 'done'];

  // for timeline in expert mode
  template: any = {
    config: {
      aspect_ratio: '3x2',
      aspect_ratios: {
        '3x2': [],
      },
      timeline: {},
    },
    type: 'video',
  };

  types: any[] = [
    {
      checked: true,
      icon: 'phone-portrait-outline',
      uid: 15,
      name: 'ai_video_creator_length_15',
    },
    {
      icon: 'film-outline',
      uid: 60,
      name: 'ai_video_creator_length_60',
    }
  ];

  @ViewChild(VideoEditorComponent) videoEditor: any;

  videoProject: aiVideoProject = {
    active: true,
    name: '',
    sections: [
      {
        active: true,
        index: 0,
        loading: false,
        mode: 'edit',
        prompt: '',
        url: '',
      }
    ],
  };

  videoProjectId: number;

  view: any = {
    animation_editor: {
      keyframes: [],
      type: 'preset',
    },
    aspect_ratio: '3x2',
    buttonAction: 'generate',
    canGenerate: false,
    hideOrderByBtn: true,
    hideSearch: true,
    iMaxSectionFailsPerImage: 3,
    iMaxSectionFailsPerVideo: 7,
    iVideoLength: 15,
    input: '',
    multiple: true,
    output: '',
    partLookupUids: [],
    phase: 'edit',
    progress: 0,
    route: 'ai/items',
    showBackButton: true,
    showMenuButton: false,
    showProjectsSelect: true,
    showViewModeSelect: true,
    title: 'ai_video_creator',
    viewType: 'grid',
  };

  constructor(
    private aiBridge: AiBridgeService,
    private aiTools: AiToolsService,
    private aiVideos: AiVideosService,

    private alertService: AlertService,
    private configService: ConfigService,
    private dani: DaniService,
    private elevenlabs: ElevenlabsService,
    private events: EventsService,
    private mediaService: MediaextendService,
    private modalService: ModalService,
    private params: ParamsService,
    private projects: ProjectsService,
    private rendley: RendleyService,
    private route: ActivatedRoute,
    private sd: StablediffusionService,
    private sidebar: SidebarService,
    private tools: ToolsService,
    private translations: TranslationService,
    private userService: UserService,
    private viewService: ViewService,
    private zone: NgZone,
  ) {
    this.appConfig = this.configService.getConfig();
    this.videoProjectId = parseInt(this.route.snapshot.paramMap.get('videoProjectId'));

    // apply types to intro overlay component
    if (!!this.introOverlayConfig.groups && !!this.introOverlayConfig.groups[0]) {
      this.introOverlayConfig.groups[0].buttons = this.types || [];
    }

    // apply aspect ratios to intro overlay component
    if (!!this.introOverlayConfig.groups && !!this.introOverlayConfig.groups[1]) {
      this.introOverlayConfig.groups[1].buttons = this.aspect_ratios || [];
    }
  }

  accept(section: aiVideoCreatorSection, index: number, blWatch: boolean = true) {
    return new Promise(async (resolve, reject) => {
      console.log('accept: section', section);
      console.log('accept: index', index);
      console.log('accept: blWatch', blWatch);

      // if url is missing, load photo
      if (!section.url) {
        return this.loadMissingSectionPhoto(section, index);
      }

      // handle exit edit mode
      if (section.mode === 'edit') {
        section.mode = 'view';
        this.videoProject.sections[index] = section;
        reject(false);
        return false;
      }

      // else, handle image to video
      this.view.mode = 'generate';

      section.mode = 'accept';
      section.loading = true;
      section.loading_text = 'ai_video_creator_section_loading_image_to_video';
      section.state = 'waiting';

      this.videoProject.sections[index] = section;

      this.aiSettings.width = this.aiSettings.width || 768;
      this.aiSettings.height = this.aiSettings.height || 768;

      let prompt: string = `${!!section.camera_behaviour ? (section.camera_behaviour + ', ') : ''}${section.prompt || ''}`;

      this.events.publish('toast', {
        icon: 'hourglass-outline',
        message: 'ai_image_to_video_process_started',
        color: 'primary',
      });

      // try improving the input prompt for better animation
      try {
        const improve: any = await this.aiTools.optimizeImageToVideoPrompt(prompt, {});

        if (!!improve && !!improve.output) {
          prompt = this.tools.trim(this.tools.stripHtml(improve.output));
        }
      } catch (e) {
        console.warn('improve prompt failed: ', e);
      }

      // define the settings for image to video operation
      let imageToVideoConfig: any = {

        // text prompt
        prompt: prompt,
        negative_prompt: `${section.negative_prompt || ''}`,

        // attributes
        height: this.aiSettings.height,
        width: this.aiSettings.width,

        // used for time calculation
        set_index: (index || 0),
      };

      // if section is linked to next one, set start image of next slide as end image of current slide
      const nextSection: any = this.videoProject.sections[index + 1];

      if (!!section.linkWithNext && !!nextSection) {
        imageToVideoConfig.end_image = (nextSection.photo || nextSection.url);
      }

      // update ai config
      this.aiTools.setConfig(this.aiSettings);

      // execute the image to video execution
      this.sd.imageToVideo(section.photo || section.url, imageToVideoConfig)
        .then((response: any) => {

          // response is a link to media queue which can be watched then
          if (!!response && !!response.item && !!response.item.uid) {
            this.view.partLookupUids.push(response.item.uid);

            section.queue_item_uid = response.item.uid;
            section.state = 'waiting';
            section.uid = section.uid || section.queue_item_uid;

            this.videoProject.sections[index] = section;

            if (!!blWatch) {
              this.watchSingleSection(section, response.item);
            }

          } else {
            section.loading = false;
          }

          if (!!response && !!response.images) {
            this.addResponseImagesToHistory(response.images);
          }

          this.view.ai_tool_selection = null;
          this.view.loading = false;

          resolve(this.view);
        })
        .catch((error: any) => {
          this.view.ai_tool_selection = null;

          section.loading = false;
          section.loading_text = null;

          section.iFailed = section.iFailed || 0;
          section.iFailed++;

          if (section.iFailed < this.view.iMaxSectionFailsPerVideo) {
            this.accept(section, index, blWatch).then(resolve).catch(reject);
          } else {
            resolve(section);
          }
        });
    });
  }

  acceptAll() {
    return new Promise((resolve, reject) => {

      console.log('acceptAll: videoProject.sections', this.videoProject.sections);

      if (!this.videoProject.sections || !this.videoProject.sections.length) {
        reject(false);
      }

      let iDone: number = 0,
        iAll: number = this.videoProject.sections.length;

      this.videoProject.sections.forEach(async (section: aiVideoCreatorSection, index: number) => {
        setTimeout(async () => {
          try {
            await this.accept(section, index, false);

            iDone++;

            if (iDone === iAll) {
              resolve(true);
            }
          } catch (e) {
            console.warn('accepting single section failed', e);

            iDone++;

            if (iDone === iAll) {
              resolve(true);
            }
          }
        }, (index * 1000));
      });
    });
  }

  addMediaResponseItemToSection(item: any, section: aiVideoCreatorSection) {

    // handle single file selection first
    section.url = item.guid;

    if (!!section.url && section.url.indexOf('.getgenius.') !== -1) {
      section.url = section.url.replace('.mp4', '.webm');
    }

    section.type = item.type || section.type;

    return section;
  }

  addResponseImagesToHistory(images: any[]) {
  }

  addSection(data: any = {}) {

    const section: aiVideoCreatorSection = Object.assign({
      active: true,
      index: (this.videoProject.sections.length || 0),
      loading: false,
      mode: 'edit',
      prompt: '',
      url: '',
    }, data);

    this.setSections([section].concat(this.videoProject.sections));

    return section;
  }

  aiCreate() {

    // request alert if changes are made and not saved
    // allow video project saving

    if (!this.view.aiCreateInput || !this.view.aiCreateInput.length) {
      return false;
    }

    this.view.finalResultUrl = null;
    this.view.generating = true;
    this.view.loading = true;
    this.view.progress = 0;

    setTimeout(() => {
      this.startManually();
    }, 3500);

    this.dani.executeCreateVideo({
      input: this.view.aiCreateInput,
      provider: 'rendley',
      video_length: (this.view.iVideoLength || 15),
    })
      .then(async (response: any) => {
        this.startManually();

        this.view.generating = false;
        this.view.loading = false;
        this.introCard.hidden = true;

        console.log('create-video: response.data', response.data);

        if (!!response && !!response.data) {
          const srcClipTypes: string[] = ['audio', 'image', 'video'];

          if (typeof response.data === 'object' && response.data.hasOwnProperty('display')) {
            // full rendley serialized json, can be loaded as project
            const loadFromJson: any = await this.videoEditor.loadFromJson(response.data);
            console.log('loadFromJson', loadFromJson);
          } else {

            // check if output is a list of rendley layers:
            if (!!response.data[0] && !!response.data[0].hasOwnProperty('clips')) {
              let sections: aiVideoCreatorSection[] = [];

              response.data.forEach(async (layerData: any, iLayer: number) => {
                setTimeout(async () => {
                  console.log('> sync generated layerData: ', layerData);

                  const layer: any = await this.videoEditor.createLayer();
                  console.log('> sync generated layer: ', layer);

                  if (!!layerData.clips) {
                    layerData.clips.forEach((clipData: any, iClip: number) => {
                      setTimeout(async () => {
                        try {

                          console.log('> clipData', clipData);

                          // if video is requested but no preview src provided, create image first (for preview + image to video)
                          if (clipData.type === 'video' && !!clipData.prompt && !clipData.src) {
                            clipData.type = 'image';
                          }

                          if ((srcClipTypes.indexOf(clipData.type) !== -1) && !!clipData.prompt && !clipData.src) {
                            console.warn('missing clip src, creating it first: ', clipData);

                            switch (clipData.type) {
                              case 'audio':
                                break;
                              case 'image':

                                const searchOptions: any = Object.assign(this.aiSettings, {
                                  blFineTuneInput: true,
                                  limit: 1,
                                  negative_prompt: `${clipData.negative_prompt || ''}`,
                                  query: `${clipData.prompt || ''}`,
                                  request: 'images',
                                  showStep: (data: any) => {
                                    console.log('ai-video-creator: showStep: data', data);
                                  },
                                  height: this.aiSettings.height, // parseInt(`${this.aiSettings.height * 1.25}`),
                                  width: this.aiSettings.width, // parseInt(`${this.aiSettings.width * 1.25}`),
                                });

                                const searchResponse: any = await this.aiTools.search(searchOptions);

                                if (!!searchResponse && !!searchResponse.photo) {
                                  clipData.src = searchResponse.photo;
                                } else {
                                  console.warn('> generating single clip image failed: searchResponse: ', searchResponse);
                                }
                                break;
                              case 'video':
                                break;
                            }
                          }

                          await layer.addClip(clipData);
                          sections.push(clipData);
                        } catch (e) {
                          console.warn('> generating single clip failed: error: ', e);
                        }
                      }, (iClip * 250));
                    });
                  }
                }, (iLayer * 1000));
              });

              console.log('> calculated sections: ', sections);
            } else {

              // else, old GG sections format (fallback)
              const sections: any[] = (response.data || []).map((section: aiVideoCreatorSection) => {
                section.mode = section.mode || 'text';
                return section;
              });

              await this.setSections(sections);
            }
          }

          this.loadMissingSectionPhotos();
          this.loadMissingSectionsAudios();
        }
      })
      .catch((error: any) => {
        this.view.generating = false;
        this.view.loading = false;

        this.events.publish('error', error);
      });
  }

  aiSettingsChanged(event: any | null = null) {
    console.log('aiSettingsChanged', event);
  }

  amountChanged() {
    this.view.blCustomAmount = true;
  }

  aspectRatioChanged() {
    this.aiSettings = this.aiSettings || {};

    let iWidth: number = 768, iHeight: number = 768, fps: number = (this.aiSettings.fps || 24);
    let iDefaultFps: number = 16;

    switch (this.view.aspect_ratio) {
      case '3x2':
        iDefaultFps = 24, iHeight = 768, iWidth = 1360;
        //iDefaultFps = 24, iHeight = 480, iWidth = 720;
        break;
      case '9x16':
        iHeight = 1024, iWidth = 576;
        break;
      case '16x9':
        iHeight = 576, iWidth = 1024;
        break;
    }

    this.aiSettings.fps = fps || iDefaultFps;
    this.aiSettings.height = iHeight || 768;
    this.aiSettings.width = iWidth || 768;

    // update related variables
    if (!!this.view.aspect_ratio) {
      this.view.filters = this.view.filters || {};
      this.view.filters.aspect_ratios = [this.view.aspect_ratio];

      if (!!this.view.templateView) {
        this.view.templateView.aspectRatio = this.view.aspect_ratio;
      }

    }

    try {
      this.videoEditor.setResolution(iWidth, iHeight);
    } catch (e) {
      console.warn('changing video editor resolution failed', e);
    }
  }

  back() {

    // if is save mode, cancel it
    if (!!this.view.isSaveMode) {
      this.view.isSaveMode = false;
      return false;
    }

    // if no changes exist, just close
    if (!this.view.blHasChanges) {
      this.aiVideos.detailItem(null);
      this.modalService.dismiss();
      return false;
    }

    this.translations.get([
      'discard',
      'save',
      'save_ai_video_project_changes_title',
      'save_ai_video_project_changes_text',
    ])
      .subscribe((translations: any) => {
        this.alertService.requestConfirm({
          header: translations.save_ai_video_project_changes_title || 'save_ai_video_project_changes_title',
          message: translations.save_ai_video_project_changes_text || 'save_ai_video_project_changes_text',
          buttons: [
            {
              handler: async () => {

                // only save changes if bl is true & post is ready
                if (!!this.videoProject && !!this.videoProject.uid) {
                  await this.save();
                }

                this.dismiss();
              },
              role: 'save',
              text: translations.save || "save",
            },
            {
              color: 'danger',
              handler: () => {
                this.dismiss();
              },
              role: 'discard',
              text: translations.discard || "discard",
            },
          ],
        })
          .then(async (bl: boolean) => {

            // only save changes if bl is true & post is ready
            if (!!bl && !!this.videoProject && !!this.videoProject.uid) {
              await this.save();
            }

            this.dismiss();
          })
          .catch((error: any) => {
            console.warn('alert confirm failed', error);
          });
      });
  }

  calcColSize() {
    this.view.isDesktop = this.tools.isDesktop();
    this.view.colSize = (!!this.view.isDesktop ? 4 : 12);
  }

  calcInputModeStates() {
    this.view.selectedInputModesList = {};

    this.view.selectedInputModes = (this.inputModes || []).filter((mode: any) => {
      this.view.selectedInputModesList[mode.uid] = !!mode.checked;

      return !!mode.checked;
    });

    this.view.hasSelectedInputModes = !!this.view.selectedInputModes.length;
  }

  calcViewStates() {
    this.view.canGenerate = true;
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.calcColSize();
  }

  compileParts() {

    const parts: string[] = this.videoProject.sections.map((section: aiVideoCreatorSection) => {
      return section.url;
    });

    if (!parts || !parts.length) {
      return false;
    }

    this.view.loading = true;

    this.mediaService.compileVideoParts({
      parts: parts,
    })
      .then((response: any) => {
        if (!!response && !!response.url) {
          this.view.loading = false;
          this.view.finalResultUrl = response.url;
          this.view.phase = 'done';

          this.media.guid = response.url;
          this.media.type = 'video';
          this.media.videoSrc = response.url;
        }
      })
      .catch((error: any) => {
        this.view.loading = false;
        this.view.phase = 'error';

        this.events.publish('error', error);
      });
  }

  delete(section: aiVideoCreatorSection, index: number) {
    console.log('delete', section);

    this.setSections(this.videoProject.sections.filter((_section: aiVideoCreatorSection, _index: number) => {
      return index !== _index;
    }));
  }

  dismiss(data: any | null = null) {
    this.aiVideos.detailItem(null);
    this.modalService.dismiss(data);
  }

  doRefresh(event: any | null = null) {
    this.loadVideoCreator(true)
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
        this.runSearch();
      })
      .catch((error: any) => {
        if (!!event) {
          event.target.complete();
        }
        this.events.publish('error', error);
      });
  }

  download() {

    if (!this.view.finalResultUrl || !this.view.finalResultUrl.length) {
      return false;
    }

    this.view.downloaded = false;

    this.mediaService.importFromUrl(this.view.finalResultUrl)
      .then((response: any) => {
        this.view.downloaded = true;
        //this.browser
      })
      .catch((error: any) => {
        this.view.downloaded = false;
        this.events.publish('error', error);
      });
  }

  dropSectionReorder(event: any) {
    console.log('dropSectionReorder: event', event);
  }

  edit(section: aiVideoCreatorSection, index: number) {
    section.mode = 'edit';
    this.view.mode = 'edit';
  }

  editVideoParts() {
    this.view.finalResultUrl = null;
    this.view.phase = 'edit';
  }

  focusSection(section: aiVideoCreatorSection, index: number) {

    this.videoProject.sections.forEach((_section: aiVideoCreatorSection, _index: number) => {
      _section.active = (index === _index);
    });

    this.sectionIndex = index;

    // Music
    if (!!this.view.music_types && !!this.view.music_types[0]) {
      this.view.music_types[0].checked = !!(!!section && !!section.background_sound);
    }

    // Voiceover
    this.view.use_tts = !!(!!section && !!section.speaker_text);
  }

  async generate() {
    this.view.finalResultUrl = null;
    this.view.partLookupUids = [];

    // first, accept all sections
    await this.acceptAll();

    this.startManually();

    // then, watch queue
    this.watchQueue();
  }

  async imageToText(sectionIndex: number) {

    if (!this.videoProject.sections || !this.videoProject.sections[sectionIndex] || !this.videoProject.sections[sectionIndex].url) {
      return false;
    }

    this.videoProject.sections[sectionIndex].analyzing = true;

    try {
      //const exec: any = await this.sd.imageToText(this.videoProject.sections[sectionIndex].url);

      //if (!!exec && !!exec.caption) {
      //  this.videoProject.sections[sectionIndex].prompt = exec.caption;
      //}

      if (!!this.videoProject.sections[sectionIndex].media_uid) {
        const analyseResponse: any = await this.mediaService.analyse([this.videoProject.sections[sectionIndex].media_uid], {}, false);
        console.log('ai-video-creator: imageToText: analyseResponse', analyseResponse);
      }

      this.videoProject.sections[sectionIndex].analyzing = false;
    } catch (e) {
      console.warn('ai-video-creator: imageToText: error', e);
      this.videoProject.sections[sectionIndex].analyzing = false;
    }
  }

  async importToLibrary() {

    if (!this.view.finalResultUrl || !this.view.finalResultUrl.length) {
      return false;
    }

    this.view.imported = false;

    this.mediaService.importFromUrl(this.view.finalResultUrl)
      .then((response: any) => {
        this.view.imported = (!!response && !!response.success);

        if (!!response && !!response.link_uid) {
          this.media.uid = response.link_uid;
        }

        return this.media;
      })
      .catch((error: any) => {
        this.view.imported = false;
        this.events.publish('error', error);

        return error;
      });
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', () => {
      this.doRefresh();
    });

    this.events.subscribe('window:resized', () => {
      this.calcViewVars();
    });
  }

  ionViewWillEnter() {
    this.initEvents();
  }

  ionViewWillLeave() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  itemInfo(image: any, event: any | null = null) {
    this.item = image;

    this.itemInfoPopover.show({
      event: event,
      item: image,
    });
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  loadMissingSectionsAudios() {
    console.log('loadMissingSectionsAudios: sections', this.videoProject.sections);

    if (!this.videoProject.sections || !this.videoProject.sections.length) {
      return false;
    }

    this.view.iCount = this.videoProject.sections.length;
    this.view.iDone = 0;
    this.view.progress = 0;

    this.videoProject.sections.forEach((section: aiVideoCreatorSection, index: number) => {
      setTimeout(async () => {
        try {
          console.log('loadMissingSectionsAudios: section', section);

          if (!!section && !!section.speaker_text) {

            const textToAudio: any = await this.elevenlabs.textToSpeech(section.speaker_text);
            console.log('loadMissingSectionsAudios: section textToAudio', textToAudio);

            if (!!textToAudio && !!textToAudio.url) {
              section.settings = section.settings || {};
              section.settings.speaker_text_file = textToAudio.url;

              this.videoProject.sections[index] = section;
              this.syncSection(section, index);
            }
          }
        } catch (e) {
          console.warn('loadMissingSectionsAudios: section text to audio failed: ', e);
        }
      }, (index * 1500));
    });
  }

  loadMissingSectionPhoto(section: any, index: number, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      section.loading = true;
      section.loading_text = 'ai_video_creator_section_loading_text_to_image';
      section.state = 'waiting';

      this.videoProject.sections[index] = section;

      this.aiSettings.width = this.aiSettings.width || 768;
      this.aiSettings.height = this.aiSettings.height || 768;

      const searchOptions: any = Object.assign(this.aiSettings, {
        blFineTuneInput: true,
        limit: 1,
        negative_prompt: `${section.negative_prompt || ''}`,
        query: `${section.prompt || ''}`,
        request: 'images',
        showStep: (data: any) => {
          console.log('ai-video-creator: showStep: data', data);
        },
        height: this.aiSettings.height, // parseInt(`${this.aiSettings.height * 1.25}`),
        width: this.aiSettings.width, // parseInt(`${this.aiSettings.width * 1.25}`),
      });

      if (!!this.videoEditor) {
        this.videoEditor.updateMedia(this.fallbackImg, {
          duration: section.duration || 4,
          index: index,
          section: section,
          startTime: section.startTime || (index * 4),
          type: section.type || 'image',
        });
      }

      this.aiTools.search(searchOptions, {}, blForceRefresh)
        .then(async (response: any) => {
          section.loading = false;
          section.loading_text = null;

          this.videoProject.sections[index] = section;

          const suggestion: any = (!!response && !!response.suggestions && !!response.suggestions[0] ? response.suggestions[0] : response);

          if (!!suggestion && !!suggestion.images && !!suggestion.images[0]) {
            section.iFailed = 0;
            section.guid = suggestion.images[0];
            section.photo = suggestion.images[0];
            section.state = 'done';
            section.type = 'image';
            section.url = suggestion.images[0];
            section.mode = 'view';

            // append metadata object
            if (!!suggestion.info) {
              section.info = suggestion.info;
            }

            this.videoProject.sections[index] = section;

            if (!!this.aiSettings && !!this.aiSettings.auto_upscale) {
              await this.upscale(section, index, blForceRefresh);
            }

            this.syncSection(section, index);
          } else {
            section.state = 'failed';
          }

          resolve(section);
        })
        .catch((error: any) => {
          section.loading = false;
          section.loading_text = null;

          this.videoProject.sections[index] = section;

          section.iFailed = section.iFailed || 0;
          section.iFailed++;

          if (section.iFailed < this.view.iMaxSectionFailsPerImage) {
            this.loadMissingSectionPhoto(section, index, blForceRefresh).then(resolve).catch(reject);
          } else {
            resolve(section);
          }
        });
    });
  }

  loadMissingSectionPhotos() {

    if (!this.videoProject.sections || !this.videoProject.sections.length) {
      return false;
    }

    this.view.iCount = this.videoProject.sections.length;
    this.view.iDone = 0;
    this.view.progress = 0;

    this.videoProject.sections.forEach((section: aiVideoCreatorSection, index: number) => {
      setTimeout(async () => {

        if (!section.photo || !section.photo.length) {
          await this.loadMissingSectionPhoto(section, index);
        }

        this.view.iDone++;
        this.view.progress = ((100 / this.view.iCount) * this.view.iDone / 100);

        // reset progress if done
        if (this.view.progress === 1) {
          setTimeout(() => {
            this.view.progress = 0;
          }, 250);
        }

      }, (index * 1000));
    });
  }

  loadQueue() {
    return new Promise(async (resolve, reject) => {

      // first, try accepting all items before throwing an error
      if (!this.view.partLookupUids || !this.view.partLookupUids.length) {
        console.warn('empty parts list, accepting all items');
        await this.acceptAll();
      }

      // if still no part uids exist, throw error
      if (!this.view.partLookupUids || !this.view.partLookupUids.length) {
        reject('error_missing_part_lookup_uids');
      } else {
        // otherwise, queue + build parts

        //this.view.iCount = this.view.partLookupUids.length;
        this.view.iCount = this.videoProject.sections.length;
        this.view.phase = 'loading';

        this.aiBridge.getQueue(true, {
          filter: {
            user_uid: this.userService.getUid(),
          },
          lookup_uids: this.view.partLookupUids,
        })
          .then((queue: mediaQueueItem[]) => {
            try {

              // sort queue by states + url existence
              queue.sort((a, b) => this.stateKeys.indexOf(b.state) - this.stateKeys.indexOf(a.state))
                .sort((a, b) => {
                  if (a.name < b.name) return -1;
                  if (a.name > b.name) return 1;
                  return 0;
                });

              console.log('> queue', queue);

              // apply results to sections
              if (!!queue && !!queue.length) {
                queue.forEach((queueItem: any) => {

                  let matchingSection: any | null = null,
                    matchingSectionIndex: number | null = null;

                  this.videoProject.sections.forEach((section: aiVideoCreatorSection, sectionIndex: number) => {
                    if (section.queue_item_uid === queueItem.uid) {
                      matchingSection = section;
                      matchingSectionIndex = sectionIndex;
                    }
                  });

                  if (!!matchingSection && (!!matchingSection.loading || (!matchingSection.url || (matchingSection.type !== 'video')))) {

                    if ((queueItem.state === 'failed') && !queueItem.url) {
                      // handle error

                      matchingSection.loading = false;
                      matchingSection.loading_text = null;
                      matchingSection.state = 'failed';

                      // set section one type / step back
                      if (matchingSection.type === 'video') {
                        matchingSection.type = 'image';
                      } else
                        if (matchingSection.type === 'image') {
                          matchingSection.type = 'text';
                        }

                      matchingSection.iFailed = matchingSection.iFailed || 0;
                      matchingSection.iFailed++;

                      if (matchingSectionIndex !== null && (matchingSection.iFailed < this.view.iMaxSectionFailsPerVideo)) {
                        this.accept(matchingSection, matchingSectionIndex, true).then(resolve).catch(reject);
                      }
                    } else
                      if (!!queueItem.url) {
                        // handle success (url provided)

                        this.view.partLookupUids = (this.view.partLookupUids || []).filter((uid: number) => {
                          return uid !== queueItem.uid;
                        });

                        matchingSection.loading = false;
                        matchingSection.loading_text = null;
                        matchingSection.state = queueItem.state || matchingSection.state;
                        matchingSection.type = 'video';
                        matchingSection.url = queueItem.url;
                        matchingSection.uid = matchingSection.uid || matchingSection.queue_item_uid;

                        // if url provided, mark as "done"
                        if (!!matchingSection.url) {
                          matchingSection.state = 'done';
                        }

                        if (matchingSectionIndex !== null) {
                          this.focusSection(matchingSection, matchingSectionIndex);

                          this.videoProject.sections[matchingSectionIndex] = matchingSection;
                          this.view.iDone++;
                          this.view.progress = ((100 / this.view.iCount) * this.view.iDone) / 100;

                          if (!!this.videoEditor) {
                            this.videoEditor.updateMedia(matchingSection.url, {
                              duration: matchingSection.duration || 4,
                              index: matchingSectionIndex,
                              section: matchingSection,
                              startTime: matchingSection.startTime || (matchingSectionIndex * 4),
                              type: matchingSection.type || 'video',
                            });
                          }

                          if (this.view.iDone === this.view.iCount) {
                            this.onPartsCreated();
                          }
                        }
                      }
                  } else
                    if (!!matchingSection && (!!matchingSection.loading || (!matchingSection.url || (matchingSection.type !== 'video'))) && !!queueItem.state) {
                      // else, only update state 
                      matchingSection.state = queueItem.state;
                    }
                });
              }

              resolve(this.view);
            } catch (e) {
              console.warn('updating queue failed', e);
              resolve(this.view);
            }
          })
          .catch((error: any) => {
            reject(error);
          });
      }
    });
  }

  loadVideoCreator(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      this.view.loading = true;

      await this.loadProject();

      this.view.loading = false;

      resolve(this.view);
    });
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  loadUIParams() {
    const videoProjectFromService: aiVideoProject = this.aiVideos.detailItem() as aiVideoProject;

    // apply video project from service if provided
    if (!!videoProjectFromService && !!videoProjectFromService.uid) {
      this.videoProject = videoProjectFromService;
      this.startManually();

      return false;
    }

    const createParams: any = this.params.get('viewData_createVideo');

    if (!createParams) {

      if (!!this.videoProjectId) {
        console.log('loadUIParams: videoProjectId', this.videoProjectId);
      }

      return false;
    }

    if (!!createParams.search) {
      this.search = Object.assign(this.search, createParams.search);
    }

    if (!!createParams.view) {
      this.view = Object.assign(this.view, createParams.view);
    }

    let imageToTextRequests: number = 0;

    // apply media from view params
    if (!!this.view && !!this.view.mediaList) {
      this.view.mediaList = this.view.mediaList.slice(0, 100);

      this.view.mediaList.reverse().forEach((mediaItem: mediaItem) => {
        let prompt: string = '';

        // iterate attributes first
        if (!!mediaItem && !!mediaItem.attributes) {
          mediaItem.attributes.forEach((attribute: any) => {
            if ((attribute.key === 'caption' || attribute.uid === 'caption') && !!attribute.value) {
              prompt = attribute.value;
            }
          });
        }

        // if prompt is empty and tags are defined, create prompt on tags
        if ((!prompt || !prompt.length) && (!!mediaItem && !!mediaItem.tags && !!mediaItem.tags.length)) {
          prompt = this.tools.trim(mediaItem.tags.map((tag: any) => {
            return (tag.title || tag.indent);
          }).join(', '));
        }

        const section: aiVideoCreatorSection = {
          headline: mediaItem.title,
          media_uid: (mediaItem.ID || mediaItem.uid),
          mode: 'view',
          prompt: prompt,
          state: 'done',
          type: mediaItem.type,
          url: mediaItem.guid,
        };

        this.addSection(section);

        // interrogate image if no caption found
        if (!prompt || !prompt.length) {
          imageToTextRequests++;

          const sectionIndex: number = this.videoProject.sections.length - 1;

          setTimeout(() => {
            this.imageToText(sectionIndex);
          }, (imageToTextRequests * 250));
        }
      });
    }

    setTimeout(() => {
      if (!!createParams && !!createParams.fire) {
        this.generate();
      } else {
        this.startManually();
      }
    }, 100);
  }

  newProject() {
    this.media = {};
    this.setSections([]);
    this.view.startManually = false;
  }

  ngAfterContentInit() {
    this.aspectRatioChanged();
  }

  ngOnDestroy() {
    this.aiVideos.detailItem(null);
    this.stopWatcher();

    try {

      if (!this.videoEditor) {
        return false;
      }

      this.videoEditor.destroy();
    } catch (e) {
      console.warn('destroying video editor failed: ', e);
    }
  }

  ngOnInit() {
    this.aspectRatioChanged();
    this.calcViewVars();

    this.loadCards();
    this.loadProject();
    this.loadUIParams();
  }

  onEditorClipUpdated(event: any = null) {
    console.log('onEditorClipUpdated: event', event);

    if (!!event && !!event.options && event.options.hasOwnProperty('index')) {
      this.sectionIndex = event.options.index || 0;
    }
  }

  onEditorClipStyleUpdated(event: any = null) {
    //console.log('onEditorClipStyleUpdated: event', event);
  }

  onEditorLayerUpdated(event: any = null) {
    //console.log('onEditorLayerUpdated: event', event);
  }

  onEditorLibraryUpdated(event: any = null) {
    //console.log('onEditorLibraryUpdated: event', event);
  }

  onEditorTimeUpdated(time: number | null = null) {
    this.zone.run(() => {

      if (!time || !this.videoProject.sections || !this.videoProject.sections.length) {
        return false;
      }

      let sectionIndex: number | null = null;

      const matches: any[] = this.videoProject.sections.filter((section: any, index: number) => {
        if (!!section && !!section.settings && (section.settings.start <= time) && (section.settings.end >= time)) {
          sectionIndex = index;
          return true;
        }
      });

      const match: any = (!!matches && (matches.length === 1) ? matches[0] : null);

      if (!!match && (sectionIndex !== null)) {
        this.sectionIndex = sectionIndex;
      }
    });
  }

  onMusicSettingsChanged(settings: any) {

  }

  onOverlayInputChanged(event: any = null) {

    if (!event || !event.input || !event.input.hasOwnProperty('value')) {
      return false;
    }

    this.view.aiCreateInput = event.input.value;

    if (!!this.videoProject && !!this.videoProject.sections && !!this.videoProject.sections[this.sectionIndex]) {
      this.videoProject.sections[this.sectionIndex].prompt = event.input.value;
    }
  }

  onPartsCreated() {
    this.view.phase = 'presenting';
    this.view.progress = 0;

    this.stopWatcher();
  }

  onSearchChanged(searchOptions: any | null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }

  onSelectionActionChanged(event: any | null = null) {

  }

  onVoiceoverSettingsChanged(settings: any) {

  }

  presentInfoPopover(e: Event, message: string) {
    this.view.infoPopoverContent = message;

    this.infoPopover.show({
      content: message,
      event: e,
    });
  }

  qualityChanged() {

  }

  async rebuildSection(section: aiVideoCreatorSection, index: number) {
    console.log('ai-video-creator: reubildSection: section', section);

    // if type is video, rebuild using image to video
    if (section.type === 'video') {
      return this.accept(section, index, false);
    }

    // else, rebuild using image to image
    this.videoProject.sections[index] = section;

    const lookup: any = await this.loadMissingSectionPhoto(section, index, true);
    console.log('ai-video-creator: reubildSection: lookup', lookup);
  }

  async runAiPrompt(event: any = null) {
    console.log('runAiPrompt: event', event);
    console.log('runAiPrompt: view.aiCreateInput', this.view.aiCreateInput);

    if (!this.view.aiCreateInput || !this.view.aiCreateInput.length) {
      return false;
    }

    const json: any = this.videoEditor.toJSON();
    console.log('runAiPrompt: json', json);

    if (!json) {
      return false;
    }

    const exec: any = await this.rendley
      .runAiPromptOnProjectJson(
        this.view.aiCreateInput,
        json,
        {

        }
      );

    console.log('> exec', exec);
  }

  public runSearch() {
    try {

      if (!this.searchToolbar) {
        return false;
      }

      this.searchToolbar.runSearch();
    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

  save() {

    if (!this.view.isSaveMode) {
      this.view.isSaveMode = true;
      return false;
    }

    // update video project if uid exists
    if (!!this.videoProject && !!this.videoProject.uid) {
      return this.update();
    }

    const json: any = this.videoEditor.toJSON();
    console.log('create: json', json);

    this.aiVideos.create(this.videoProject)
      .then((response: any) => {
        console.log('ai-video-creator: create response', response);

        if (!!response && !!response.project) {
          this.videoProject = response.project;
        }

        this.view.isSaveMode = false;
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  setSections(sections: any[] = []) {
    this.videoProject.sections = (sections || []);

    const iVideoLength: number = 4;

    if (!!this.template && !!this.template.config && !!this.template.config.aspect_ratios && !!this.template.config.aspect_ratio) {

      this.template.config.aspect_ratios[this.template.config.aspect_ratio] = this.videoProject.sections.map((section: aiVideoCreatorSection, index: number) => {
        section.settings = section.settings || {};
        section.settings.src = section.url;
        section.settings.start = (!!index ? ((index * iVideoLength) - index) : 0);
        section.settings.end = (section.settings.start + iVideoLength);

        return section;
      });
    }
  }

  setVideoLength(iLength: number) {
    this.view.iVideoLength = iLength;
    this.view.iRequiredSections = parseInt(`${(iLength / 4) + 1}`);
  }

  async shareFinalVideo() {
    await this.importToLibrary();
    this.viewMedia(this.media);
  }

  startManually() {
    this.view.canGenerate = true;
    this.view.canSlideBack = false;
    this.view.canSlideNext = true;
    this.view.startManually = true;
  }

  stopWatcher() {
    try {
      clearInterval(this.view.refreshInterval);
    } catch (e) {

    }

    this.view.loading = false;
  }

  async syncSection(section: any, index: number) {
    console.log('syncSection: section by index: ', index, section);

    // contiue section generation if url is missing
    if (!section || !section.url) {
      return false;
    }

    try {

      // add main media if url provided
      await this.videoEditor.updateMedia(`${section.url || ''}`, {
        duration: section.duration || 4,
        index: index,
        section: section,
        startTime: section.startTime || (index * 4),
        type: section.type || 'image',
      });

      // add media audio if provided
      if (!!section && !!section.settings && !!section.settings.speaker_text_file) {
        setTimeout(async () => {
          await this.videoEditor.addMedia(section.settings.speaker_text_file, {
            //duration: (!!section.settings && !!section.settings.end ? (section.settings.end || 4) - (section.settings.start || 0) : 4),
            section: section,
            startTime: (!!section.settings ? (section.settings.start || 0) : (index * 4)),
            type: 'audio',
          }, false);
        }, 500);
      }
    } catch (e) {
      console.warn('sync section failed: ', e);
    }
  }

  syncSections() {

    if (!this.videoProject || !this.videoProject.sections) {
      return false;
    }

    this.videoProject.sections.forEach(async (section: any, index: number) => {
      setTimeout(async () => {
        await this.syncSection(section, index);
      }, (index * 1000));
    });
  }

  tabChanged() {

  }

  thumbnailLoadingFailed(item: any, key: string = 'photo') {
    item[key] = this.fallbackImg;
  }

  toggleAspectRatio(aspect_ratio: any, iAspectRatio: number) {

    // legacy, use multi support:
    this.aspect_ratios.forEach((_aspect_ratio: any) => {
      _aspect_ratio.checked = false;
    });

    aspect_ratio.checked = !aspect_ratio.checked;

    this.view.aspect_ratio = aspect_ratio.uid;

    // update template
    this.template = this.template || {};
    this.template.config = this.template.config || {};
    this.template.config.aspect_ratio = this.view.aspect_ratio;

    this.template.config.aspect_ratios = this.template.config.aspect_ratios || {
      '1x1': [],
      '3x2': [],
      '4x3': [],
      '9x16': [],
      '16x9': [],
    };

    this.template.config.aspect_ratios[this.template.config.aspect_ratio] = this.template.config.aspect_ratios[this.template.config.aspect_ratio] || [];

    this.aspectRatioChanged();
  }

  toggleCard(cardName: string) {

    if (!this.cards[cardName]) {
      this.cards[cardName] = {};
    }

    this.cards[cardName].open = !this.cards[cardName].open;

    this.sidebar.setCards(this.cards);
  }

  toggleInputMode(mode: any, section: aiVideoCreatorSection) {
    section.mode = 'edit';
    section.type = 'image';

    // choose media from uploader
    if (mode.uid === 'media') {
      this.mediaService.applyFromWeb(null, {})
        .then((response: any) => {
          this.calcViewStates();

          if (!!response && !!response.items && !!response.items[0] && !!response.items[0].guid) {

            this.addMediaResponseItemToSection(response.items[0], section);

            // handle multi file selection if set
            if (!!response && !!response.items && !!response.items && (response.items.length > 1)) {
              response.items.forEach((item: any, itemIndex: number) => {

                if (!itemIndex) {
                  return;
                }

                const itemSection: any = this.addSection();

                this.addMediaResponseItemToSection(item, itemSection);
              });

            }
          }
        })
        .catch((error: any) => {
          if (!!error) {
            this.events.publish('error', error);
          }
        });
    }

    this.calcInputModeStates();
  }

  toggleLinkSectionWithNext(section: aiVideoCreatorSection) {
    section.linkWithNext = !section.linkWithNext;
  }

  toggleType(type: any, iType: number) {

    this.types.forEach((_type: any, _iType: number) => {
      _type.checked = (iType === _iType);
    });

    this.view.iVideoLength = type.uid;
    console.log('> this.view.iVideoLength', this.view.iVideoLength);
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  update() {
    const json: any = this.videoEditor.toJSON();
    console.log('upate: json', json);

    this.aiVideos.update(this.videoProject)
      .then((response: any) => {
        console.log('ai-video-creator: update response', response);

        if (!!response && !!response.project) {
          this.videoProject = response.project;
        }

      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  upscale(section: aiVideoCreatorSection, index: number, blForceRefresh: boolean = false) {

    if (!section || !section.url || !section.url.length) {
      return false;
    }

    section.loading = true;
    section.loading_text = 'ai_image_is_upscaling';
    section.state = 'waiting';

    this.videoProject.sections[index] = section;

    this.sd.upscale(section.url)
      .then((response: any) => {
        section.loading = false;
        section.loading_text = null;
        section.state = 'done';
        section.type = 'image';

        if (!!response && !!response.images && !!response.images[0]) {
          section.url = response.images[0];
        } else
          if (!!response && !!response.outputFile) {
            section.url = response.outputFile;
          }

        this.videoProject.sections[index] = section;
      })
      .catch((error: any) => {
        console.warn('upscaling failed', error);

        section.loading = false;
        section.loading_text = null;
        section.state = 'failed';

        this.videoProject.sections[index] = section;
      });
  }

  async viewMedia(mediaItem: any) {
    mediaItem.guid = mediaItem.guid || mediaItem.url;
    mediaItem.post_mime_type = mediaItem.post_mime_type || mediaItem.type;
    mediaItem.thumbnail = mediaItem.thumbnail || mediaItem.photo;

    const modal: any = await this.modalService.create({
      component: ViewMediaPage,
      componentProps: {
        mode: 'view',
        media: mediaItem,
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    modal.onWillDismiss().then(() => {
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

  watchQueue() {
    this.stopWatcher();

    this.view.loading = true;
    this.view.iDone = 0;

    this.watchQueue_Interval();

    this.view.refreshInterval = setInterval(() => {
      this.watchQueue_Interval();
    }, (10 * 1000));
  }

  watchQueue_Interval() {
    this.loadQueue()
      .then(() => {
        this.view.loadingQueue = false;
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
    if (this.view.queueTimeIndex < 5) {
      this.view.queueTimeIndex++;
    } else {
      this.view.queueTimeIndex = 1;
    }
  }

  watchSingleSection(section: aiVideoCreatorSection, queueItem: any) {
    if (!!queueItem.uid) {

      if (!!this.view.partLookupUids && !!this.view.partLookupUids.length && (this.view.partLookupUids.indexOf(queueItem.uid) === -1)) {
        // if already in watch queue mode, add to queue list
        this.view.partLookupUids.push(queueItem.uid);
      } else
        if (!this.view.partLookupUids || !this.view.partLookupUids.length) {
          // else, if no queue list present, set it to current item only
          this.view.partLookupUids = [queueItem.uid];
        }

      this.watchQueue();
    }
  }

}