import { Component, HostListener, OnInit, ViewChild } from '@angular/core';

import { LanguageService } from 'src/app/services/core/language.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { EventsService } from "src/app/services/core/events.service";
import { UserService } from 'src/app/services/core/user.service';
import { PostsService } from 'src/app/services/posts/posts.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { SearchService } from 'src/app/services/core/search.service';
import { TranslationService } from "src/app/services/core/translation.service";
import { PostsAdminService } from 'src/app/services/posts/posts-admin.service';
import { ViewService } from 'src/app/services/core/view.service';

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';

@Component({
  selector: 'app-edit-post-translations-modal',
  standalone: false,
  templateUrl: './edit-post-translations-modal.page.html',
  styleUrls: ['./edit-post-translations-modal.page.scss'],
})
export class EditPostTranslationsModalPage implements OnInit {
  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;

  post: post;

  search: searchOptions = {
    itemsKey: 'languages',
    keys: ['indent', 'language', 'name', 'post_content', 'host', 'title', 'url', 'uid'],
    query: '',
  };

  state: state = {};

  view: any = {
    hasSelectedItems: false,
    hideOrderByBtn: true,
    language: 'en',
    route: 'item/translations',
    key: ['indent', 'name', 'description'],
    selectionOptions: [
      {
        icon: 'create-outline',
        label: 'create',
        uid: 'create',
      },
      {
        icon: 'trash-outline',
        label: 'delete',
        uid: 'delete',
      },
      {
        icon: 'sync-outline',
        label: 'update',
        uid: 'update',
      }
    ],
    title: 'edit_translations',
  };

  constructor(
    private events: EventsService,
    private language: LanguageService,
    private modalService: ModalService,
    private posts: PostsService,
    private postsAdmin: PostsAdminService,
    private projects: ProjectsService,
    private searchService: SearchService,
    private translations: TranslationService,
    private userService: UserService,
    private viewService: ViewService,
  ) {
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  create(language: language, blForceRefresh: boolean = false) {
    language.loading = true;

    this.translations.translatePost(this.post, language.indent, blForceRefresh)
      .then((response: any) => {
        language.loading = false;

        if (!!response && !!response.post) {
          language.post = response.post;
          language.created = true;
          language.state = 'success';
        } else {
          language.created = false;
          language.state = 'warning';
        }

      })
      .catch((error: any) => {
        language.loading = false;

        this.events.publish('error', error);
      });
  }

  createSelected() {

  }

  deleteSelected() {

  }

  detectChanges() {

  }

  async dismiss(data: any|null = null, role: string | null = 'dismiss') {
    (await this.modalService).dismiss(data, role);
  }

  edit(language: any) {
    this.dismiss({
      language: language || this.view.language,
    });
  }

  ionViewWillEnter() {
    this.loadData()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  loadData(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      let response: any = { errors: [], success: false };

      this.view.loading = true;
      this.view.project = await await this.projects.getCurrent();
      this.view.foundLanguageKeys = [];
      this.view.postByLanguage = {};

      try {
        response.languages = await this.loadLanguages(blForceRefresh);
      } catch (e) {
        response.errors.languages = e;
      }

      try {
        response.posts = await this.loadLanguagesByPostSettings(blForceRefresh);
      } catch (e) {
        response.posts = [];
        response.errors.posts = e;
      }

      this.view.loading = false;
      resolve(response);
    });
  }

  loadLanguages(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      this.view.project = await this.projects.getCurrent();

      this.language.getLanguages()
        .then((languages: language[]) => {

          this.view.languages = (languages || [])
            .map((language: language) => {
              language.created = !!language.created;
              language.state = language.state || 'warning';

              return language;
            });

          if (!!this.view.project && !!this.view.project.config && !!this.view.project.config.languages) {
            this.view.languages = this.view.languages.filter((language: language) => {
              return this.view.project.config.languages.indexOf(language.indent) !== -1;
            });
          }

          resolve(this.view.languages);
        })
        .catch(reject);
    });
  }

  loadLanguagesByPostSettings(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      let project: project | null = await this.projects.getCurrent();

      this.view.foundLanguageKeys = [];

      if (!!this.view.languages && !!this.view.languages.length) {
        this.view.languages.forEach((language: language) => {
          language.post = null;
        });
      }

      this.posts.getPosts({
        allLanguages: true,
        filter: {
          type: 'all',
          url: `${this.post.url}`,
        },
        language: null,
      })
        .then((posts: post[]) => {
          this.view.foundLanguageKeys = [];
          this.view.posts = posts;
          this.view.postByLanguage = {};

          if (!!posts && !!posts.length) {
            posts.forEach((post: post) => {

              if (!!post.language && (this.view.foundLanguageKeys.indexOf(post.language) === -1)) {
                this.view.foundLanguageKeys.push(post.language);
                this.view.postByLanguage[post.language] = post;
              }

            });
          }

          if (!!this.view.languages && !!this.view.languages.length) {
            this.view.languages.forEach((language: language) => {
              language.state = ((this.view.foundLanguageKeys.indexOf(language.indent) !== -1) ? 'success' : 'warning');

              if (!!language.indent && !!this.view.postByLanguage[language.indent]) {
                language.post = this.view.postByLanguage[language.indent];
              }
            });
          }

          resolve(posts);
        })
        .catch(reject);
    });
  }

  ngOnInit() {
    this.post = (this.postsAdmin.detailItem() || {} as any);
    this.view.language = this.userService.getLanguage() || 'en';

    this.calcViewVars();
  }

  onItemChanged(language: language) {
    language.checked = !language.checked;

    this.view.selectedItems = this.view.languages.filter((language: language) => {
      return !!language.checked;
    });

    this.view.hasSelectedItems = !!(!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  @HostListener('window:resize')
  onResize() {
    this.view = this.viewService.calcScreenSizeVars(this.view);
  }

  onSearchChanged(searchOptions: any|null = null) {

    if (!this.view.hasOwnProperty('_languages')) {
      this.view._languages = JSON.parse(JSON.stringify(this.view.languages || []));
    }

    this.view.languages = this.searchService.search(this.search, this.view._languages);
  }

  onSelectionActionChanged(event: any|null = null) {
    switch (this.view.selectionAction) {
      case 'create':
        this.createSelected();
        break;
      case 'delete':
        this.deleteSelected();
        break;
      case 'update':
        this.updateSelected();
        break;
    }
  }

  onSelectOptionChanged() {

  }

  runSearch() {
    try {
      this.searchToolbar.runSearch();
    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

  update(language: language) {
    return this.create(language, true);
  }

  updateSelected() {

  }

}