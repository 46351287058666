import { Injectable } from '@angular/core';

import { AiLoaderService } from './ai-loader.service';
import { AppcmsService } from 'src/app/services/core/appcms.service';
import { GetgeniusService } from '../getgenius/getgenius.service';

@Injectable({
  providedIn: 'root'
})
export class AiBridgeService {

  constructor(
    private aiLoader: AiLoaderService,
    private AppCMS: AppcmsService,
    private getgenius: GetgeniusService,
  ) {

  }

  execute(item: aiExecutionRequest, blForceRefresh: boolean = false, config: any | null = null, params: any = {}) {
    return new Promise(async (resolve, reject) => {

      if (!!config) {
        item.config = config;
      }

      console.log('ai-bridge: execute: config', config);
      console.log('ai-bridge: execute: item', item);
      console.log('ai-bridge: execute: params', params);

      try {

        if (!!config && !!config.provider && (config.provider === 'local')) {
          // if local execution requested, execute using AI loader
          this.executeLocal(item, blForceRefresh, config, params).then(resolve).catch(reject);
        } else {
          // else, execute using server-side request

          // first register ai execution job on the server-side (token + queue)
          await this.getgenius.registerAction({
            config: config,
            item: item,
            params: params,
          });

          // then execute the server-side request
          this.AppCMS.loadPluginData('pipeline', Object.assign((params || {}), {
            item: item,
          }), ['ai', 'execute'], {}, blForceRefresh).then(resolve).catch(reject);
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  executeLocal(item: aiExecutionRequest, blForceRefresh: boolean = false, config: any | null = null, params: any = {}) {
    return new Promise(async (resolve, reject) => {
      console.log('executeLocal: item', item);
      console.log('executeLocal: config', config);
      console.log('executeLocal: params', params);

      if (!item || !item.post_content || !item.post_content.length) {
        reject('error_missing_input_content');
      } else {
        try {

          if (!!config && !!config.model) {
            this.aiLoader.setSelectedModel(config.model);
          }

          const history: aiExecutionHistoryItem[] = (item.history || []).map((historyItem: aiExecutionHistoryItem) => {
            return {
              content: `${historyItem.content || 'user'}`,
              role: `${historyItem.role || 'user'}`,
            }
          }) as aiExecutionHistoryItem[];

          console.log('executeLocal: history', history);

          this.aiLoader.completions(
            item.post_content,
            history,
            true,
            {

              // stream partial result (currently only supported in local mode)
              onPartialResult: params.onPartialResult || ((partialResult: string, partIndex: number) => {
                console.log('executeLocal: default: partialResult', partialResult);
              }),

            }
          ).then(resolve).catch(reject);
        } catch (e) {
          reject(e);
        }
      }
    });
  }

  getBasePrompt() {
    return this.aiLoader.getBasePrompt();
  }

  getConfig() {
    return this.aiLoader.getConfig();
  }

  async getPreferredModels(context: string = 'general') {
    return this.aiLoader.getPreferredModels(context);
  }

  getProvider() {
    return this.aiLoader.getProvider();
  }

  async initConfig() {
    return this.aiLoader.initConfig();
  }

  search(options: any = {}, params: any = {}, blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      options = options || {};
      params = params || {};

      if (this.getProvider() === 'local') {
        // if local execution requested, execute using ai loader

        const localExec: any = await this.aiLoader.textToImage({
          index: (params.hasOwnProperty('index') ? (params.index || 0) : null),
          guidance: parseInt(`${options.cfg_scale || 7}`),
          prompt: options.query,
          showStep: params.showStep || ((data: any) => {
            console.log('ai-bridge: showStep: data', data);
          }),
          steps: parseInt(`${options.steps || 12}`),
        });

        resolve(localExec);
      } else {
        // else, execute using cloud infrastructure

        this.AppCMS.loadPluginData('pipeline', Object.assign(params, {
          options: options,
        }), ['ai', 'search'], {}, blForceRefresh).then(resolve).catch(reject);
      }
    });
  }

  setBasePrompt(prompt: string) {
    return this.aiLoader.setBasePrompt(prompt);
  }

  setConfig(config: aiSettings) {
    return this.aiLoader.setConfig(config);
  }

  async setPreferredModels(models: aiModel[], context: string = 'general') {
    return this.aiLoader.setPreferredModels(models, context);
  }

  async syncPreferredModels(models: aiModel[], context: string = 'general') {
    return this.aiLoader.syncPreferredModels(models, context);
  }

}