import { Injectable } from '@angular/core';

import { AiBridgeService } from 'src/app/services/ai/ai-bridge.service';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ToolsService } from "src/app/services/utils/tools.service";

@Injectable({
    providedIn: 'root'
})
export class ObjectsService {

    prompts: any = {
        object_type_generation: `Your output must start with "{" and look like this (may contain more results): {
            "data": [
                {
                    "Artikelnummer": "123",
                    "Hersteller: "XYZ",
                    "Bezeichnung": "Lorem ipsum dolor sit amet."
                },
                {
                    "Artikelnummer": "456",
                    "Hersteller: "ZYX",
                    "Bezeichnung": "Ipsum dolor sit amet lorem."
                },
                {
                    "Artikelnummer": "789",
                    "Hersteller: "ZYX",
                    "Bezeichnung": "Lorem ipsum. Ipsum dolor sit amet lorem."
                },
                ...
            ],
            "type": {
                "active": true,
                "fields": [
                    {
                        "name": "Artikelnummer",
                        "type": "number",
                        "uid": "Artikelnummer"
                    },
                    {
                        "name": "Bezeichnung",
                        "type": "text",
                        "uid": "Bezeichnung"
                    },
                    {
                        "name": "Telefonnummer",
                        "type": "phone",
                        "uid": "Telefonnummer"
                    },
                    {
                        "name": "URL",
                        "type": "url",
                        "uid": "URL"
                    },
                    ...
                ],
                "name": "Stammdaten",
                "timestamp": "YYYY-MM-DD HH:ii:ss",
                "type": "object_type"
            }
        }`
    };

    constructor(
        private aiBridge: AiBridgeService,

        private AppCMS: AppcmsService,
        private events: EventsService,
        private tools: ToolsService,
    ) {
    }

    createObject(object: any, typeId: number) {
        return this.AppCMS.loadPluginData('pipeline', {
            object: object,
            type_uid: typeId,
        }, ['objects', 'create']);
    }

    createType(type: any) {
        return new Promise((resolve, reject) => {
            this.AppCMS.loadPluginData('pipeline', {
                type: type,
            }, ['objects', 'types', 'create'])
                .then((response: any) => {

                    if (!!response.type) {
                        this.events.publish('objects:type:created', response.type);
                    }

                    resolve(response);
                })
                .catch(reject);
        });
    }

    deleteObject(objectId: number, typeId: number | null = null) {
        return this.AppCMS.loadPluginData('pipeline', {
            object_uid: objectId,
            type_uid: typeId,
        }, ['objects', objectId, 'delete']);
    }

    deleteObjectType(objectTypeId: number) {
        return new Promise((resolve, reject) => {
            this.AppCMS.loadPluginData('pipeline', {
                type_uid: objectTypeId,
            }, ['objects', 'types', 'delete'])
                .then(async (response: any) => {
                    console.log('objects-service: delete object type response', response);

                    // removes entry from (custom) menu if exists
                    this.events.publish('objects:type:deleted');

                    resolve(response);
                })
                .catch(reject);
        });
    }

    generateObjectType(options: any = {}) {
        return new Promise(async (resolve, reject) => {
            options.limit = options.limit || 100;

            try {

                const exec: any = await this.aiBridge.execute({
                    context: 'text_generation',
                    history: [
                        {
                            role: 'system',
                            content: `Return a JSON-object that contains the table view data requested based on provided user input. No further output allowed.`,
                        },
                        {
                            role: 'user',
                            content: `MOST IMPORTANT: Minimum amount of items in data array: ${parseInt(`${options.limit / 2}`)}. Maximum amount of items in data array: ${options.limit}`,
                        },
                        {
                            role: 'user',
                            content: `Return the perfect table with full data (as much items / results as possible) provided for input: ${options.input || ''}`,
                        },
                    ],
                    post_content: this.prompts.object_type_generation,
                });

                const json: any = this.tools.extractJson(exec.output);

                if (!!json && !!json.data && !!json.type) {
                    resolve(json);
                } else {
                    reject(json);
                }
            } catch (e) {
                reject(e);
            }
        });
    }

    getAll(options: any = {}, blForceRefresh: boolean = false) {
        return this.getObjects(options, blForceRefresh);
    }

    getObjects(options: any = {}, blForceRefresh: boolean = false) {
        return this.AppCMS.loadPluginData('pipeline', options, ['objects'], {}, blForceRefresh);
    }

    getObjectsByTypeUid(typeId: number, blForceRefresh: boolean = false, options: any = {}) {
        options.filter = options.filter || {};
        options.filter.type_uid = typeId;
        return this.AppCMS.loadPluginData('pipeline', options, ['objects'], {}, blForceRefresh);
    }

    getTypes(options: any = {}, blForceRefresh: boolean = false) {
        return this.AppCMS.loadPluginData('pipeline', options, ['objects', 'types'], {}, blForceRefresh);
    }

    getTypeByUid(typeId: number, blForceRefresh: boolean = false) {
        return this.AppCMS.loadPluginData('pipeline', {}, ['objects', 'types', typeId], {}, blForceRefresh);
    }

    updateObject(object: any, typeId: number) {
        return this.AppCMS.loadPluginData('pipeline', {
            object: object,
            type_uid: typeId,
        }, ['objects', 'update']);
    }

    updateType(type: any) {
        return new Promise((resolve, reject) => {
            this.AppCMS.loadPluginData('pipeline', {
                type: type,
            }, ['objects', 'types', 'update'])
                .then((response: any) => {

                    if (!!response.type) {
                        this.events.publish('objects:type:updated', response.type);
                    }

                    resolve(response);
                })
                .catch(reject);
        });
    }

}