import { Injectable, NgZone } from '@angular/core';

import { AiBridgeService } from 'src/app/services/ai/ai-bridge.service';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { EventsService } from 'src/app/services/core/events.service';
import { StablediffusionService } from 'src/app/services/media/stablediffusion.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';
import { CrawlerService } from 'src/app/services/utils/crawler.service';
import { FoldersService } from 'src/app/services/utils/folders.service';
import { ToolsService } from 'src/app/services/utils/tools.service';

@Injectable({
  providedIn: 'root'
})
export class AiToolsService {

  _detailItem: any | null;

  categoryIcons: any = {
    "audio-classification": 'text-outline',
    "audio-to-text": 'recording-outline',
    "automatic-speech-recognition": 'recording-outline',
    checkpoint: "document-outline",
    "depth-estimation": "layers-outline",
    "document-question-answering": "document-outline",
    "feature-extraction": "layers-outline",
    "fill-mask": "sparkles-outline",
    hypernetwork: 'diamond-outline',
    "image-classification": 'text-outline',
    "image-feature-extraction": "sparkles-outline",
    "image-segmentation": 'locate-outline',
    "image-text-to-text": 'text-outline',
    "image-to-3d": 'cube-outline',
    "image-to-image": 'brush-outline',
    "image-to-text": 'text-outline',
    "image-to-video": 'film-outline',
    img2img: 'brush-outline',
    img2txt: 'text-outline',
    img2vid: 'film-outline',
    locon: 'diamond-outline',
    lora: 'diamond-outline',
    "mask-generation": "sparkles-outline",
    motionmodule: 'film-outline',
    "object-detection": "scan-outline",
    other: "ellipsis-horizontal-outline",
    poses: 'image-outline',
    "question-answering": "information-outline",
    "sentence-similarity": "calculator-outline",
    summarization: 'text-outline',
    "table-question-answering": "information-outline",
    "text-classification": 'text-outline',
    "text2text-generation": 'text-outline',
    "text-generation": 'text-outline',
    "text-to-image": 'image-outline',
    "text-to-speech": 'mic-outline',
    "text-to-text": 'text-outline',
    "text-to-video": 'film-outline',
    "text-to-audio": 'mic-outline',
    textualinversion: 'text-outline',
    "time-series-forecasting": "time-outline",
    "token-classification": "calculator-outline",
    translation: "language-outline",
    txt2img: 'image-outline',
    txt2txt: 'text-outline',
    txt2vid: 'film-outline',
    txt2wav: 'mic-outline',
    vae: 'diamond-outline',
    "video-classification": 'text-outline',
    "video-to-text": 'text-outline',
    vid2txt: 'text-outline',
    "voice-activity-detection": "recording-outline",
    upscaler: 'expand-outline',
    "visual-question-answering": "information-outline",
    wav2txt: 'recording-outline',
    wildcards: "document-outline",
    "zero-shot-audio-classification": "recording-outline",
    "zero-shot-classification": "layers-outline",
    "zero-shot-image-classification": 'text-outline',
    "zero-shot-object-detection": "scan-outline",
  };

  contexts: aiContext[] = [
    {
      category: 'all',
      icon: 'home-outline',
      label: 'general',
      uid: 'general',
    },
    {
      category: 'text-generation',
      icon: 'chatbubbles-outline',
      label: 'chat_completions',
      uid: 'chat_completions',
    },
    {
      category: 'image-to-text',
      icon: 'search-outline',
      label: 'image_to_text',
      uid: 'image_to_text',
    },
    {
      category: 'image-to-video',
      icon: 'fil,-outline',
      label: 'image_to_video',
      uid: 'image_to_video',
    },
    {
      category: 'text-classification',
      icon: 'text-outline',
      label: 'text_classification',
      uid: 'text_classification',
    },
    {
      category: 'text-generation',
      icon: 'create-outline',
      label: 'text_to_text',
      uid: 'text_generation',
    },
    {
      category: 'text-generation',
      icon: 'sparkles-outline',
      label: 'text_optimization',
      uid: 'text_optimization',
    },
    {
      category: 'text-to-image',
      icon: 'image-outline',
      label: 'text_to_image',
      uid: 'text_to_image',
    },
    {
      category: 'text-to-video',
      icon: 'film-outline',
      label: 'text_to_video',
      uid: 'text_to_video',
    },
    {
      category: 'text-generation',
      icon: 'languages-outline',
      label: 'translation',
      uid: 'text_translation',
    },
  ];

  promptFunctionActions: string[] = [
    'ai_image_to_image',
    'ai_image_to_text',
    'ai_image_to_video',
    //'ai_speech_to_speech',
    'ai_text_to_audio',
    'ai_text_to_image',
    'ai_text_to_speech',
    'ai_video_to_video',
    'ai_upscaler',
    'api',
    'code',
    'crawl_url_content',
    'ai_media_search',
  ];

  promptTypes: aiPromptType[] = [
    {
      icon: 'person-outline',
      name: 'prompt_type_user',
      uid: 'user',
    },
    {
      icon: 'terminal-outline',
      name: 'prompt_type_system',
      uid: 'system',
    },
    {
      icon: 'sparkles-outline',
      name: 'prompt_type_assistant',
      uid: 'assistant',
    },
    {
      icon: 'code-slash-outline',
      name: 'prompt_type_function',
      uid: 'function',
    }
  ];

  tools: aiTool[] = [
    {
      icon: 'globe-outline',
      label: 'ai_browser_execution',
      uid: 'ai_browser',
    },
    {
      icon: 'code-slash-outline',
      label: 'ai_code_generation',
      uid: 'ai_code_generation',
    },
    {
      icon: 'server-outline',
      label: 'ai_crawlers',
      uid: 'ai_crawlers',
    },
    {
      icon: 'cash-outline',
      label: 'ads',
      uid: 'ads',
    },
    {
      icon: 'phone-portrait-outline',
      label: 'apps',
      uid: 'apps',
    },
    {
      icon: 'create-outline',
      label: 'create_post',
      uid: 'create_post',
    },
    {
      icon: 'chatbubbles-outline',
      label: 'comments',
      uid: 'comments',
    },
    {
      icon: 'images-outline',
      label: 'create_media',
      uid: 'media_creator',
    },
    {
      icon: 'library-outline',
      label: 'media_library',
      uid: 'media_library',
    },
    {
      icon: 'calendar-outline',
      label: 'campaigns',
      uid: 'ai_plans',
    },
    {
      icon: 'terminal-outline',
      label: 'ai_tasks',
      uid: 'ai_tasks',
    },
    {
      icon: 'film-outline',
      label: 'ai_videos',
      uid: 'ai_videos',
    },
    {
      icon: 'happy-outline',
      label: 'getgenius_dani',
      uid: 'getgenius_dani',
    },
    {
      icon: 'search-outline',
      label: 'search',
      uid: 'getgenius_search',
    },
    {
      icon: 'file-tray-outline',
      label: 'mail',
      uid: 'mail_inbox',
    },
    {
      icon: 'mail-outline',
      label: 'newsletters',
      uid: 'newsletters',
    },
    {
      icon: "storefront-outline",
      label: "shop_products",
      uid: "shop_products"
    },
    {
      icon: 'funnel-outline',
      label: 'target_groups',
      uid: 'target_groups',
    },
    /*
    {
      icon: 'film-outline',
      label: 'ai_video',
      uid: 'video_creator',
    },
    */
    {
      icon: 'desktop-outline',
      label: 'websites',
      uid: 'websites',
    },
  ];

  view: any = {};

  constructor(
    private aiBridge: AiBridgeService,

    private AppCMS: AppcmsService,
    private chooser: ChooserService,
    private crawler: CrawlerService,
    private events: EventsService,
    private folders: FoldersService,
    private sd: StablediffusionService,
    private toolsService: ToolsService,
    private zone: NgZone,
  ) {

  }

  calculateSentenceSimilarity(texts: string[], options: any = {}) {
    return this.aiBridge.calculateSentenceSimilarity(texts, options);
  }

  caption(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'caption'], {}, blForceRefresh);
  }

  chooseProvider(options: any = {}) {
    return new Promise(async (resolve, reject) => {

      const choose: chooseResponse = await this.chooser.choose(Object.assign(options, {
        data: await this.getAvailableProviders(),
        labelKey: 'name',
        title: 'choose_ai_provider',
        valueKey: 'uid',
      }));

      if (choose && choose.data && choose.data.item && choose.data.item.uid) {
        resolve(choose.data.item.uid);
      } else {
        resolve(false);
      }
    });
  }

  async convertUserInputToSearchQuery(query: string, options: any = {}) {

    if (!query || !query.length) {
      return false;
    }

    const history: any[] = [
      {
        role: 'system',
        content: `IMPORTANT: Respond with the search query only. No further output allowed.`,
      },
      {
        role: 'system',
        content: `Convert any input to english search query (single word or seperated with "+").
        
        Examples:
        "Frohe Weihnachten ihr Lieben" should be converted to "christmas".
        "Alles Gute zum Geburtstag Tim!" should be converted to "birthday".
        "Große Online-Shop Rabatt-Aktion": should be converted to "ecommerce".
        `,
      }
    ];

    const response: any = await this.aiBridge.execute({
      context: 'text_optimization',
      history: history,
      post_content: `${query || ''}`,
    }, true);

    return response;
  }

  createFolder(folder: folder) {
    folder.location = folder.location || 'ai';
    return this.folders.create(folder);
  }

  createImagesSearchQuery(input: string, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', {
      item: {
        post_content: input,
      },
    }, ['ai', 'createImagesSearchQuery'], {}, blForceRefresh);
  }

  createImageVariations(src: string, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', {
      url: src,
    }, ['ai', 'createImageVariations'], {}, blForceRefresh);
  }

  detailItem(item: any | null = null) {

    if (item !== null) {
      this._detailItem = item;
      return this;
    }

    return this._detailItem;
  }

  async executeFunctionCall(response: any) {
    return new Promise((resolve, reject) => {
      let decoded: any | null = null, decodedArgs: any | null = null;

      if (!!response && !!response.output) {
        decoded = this.toolsService.extractJson(`${(response.output || '').replace(/(\r\n|\n|\r)/gm, "")}`);

        if (!!decoded && !!decoded.name && !!decoded.arguments) {
          decodedArgs = (typeof decoded.arguments === 'string' ? JSON.parse(`${decoded.arguments}`.replace(/(\r\n|\n|\r)/gm, "")) : decoded.arguments);
        }
      }

      if (!decodedArgs) {
        reject('error_missing_arguments');
        return false;
      }

      const call: any = {
        arguments: decodedArgs,
        raw: response,
        name: decoded.name,
      };

      this.events.publish('ai:function:call', call);

      resolve(call);
    });
  }

  enhance(item: any) {
    return this.AppCMS.loadPluginData('pipeline', {
      item: item,
    }, ['ai', 'enhance']);
  }

  extend(item: any) {
    return this.AppCMS.loadPluginData('pipeline', {
      item: item,
    }, ['ai', 'extend']);
  }

  factchecking(item: any) {
    return this.AppCMS.loadPluginData('pipeline', {
      item: item,
    }, ['ai', 'factchecking']);
  }

  getAvailableProviders(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'providers'], {}, blForceRefresh);
  }

  getCategoryIcons() {
    return this.categoryIcons;
  }

  getContexts() {
    return this.contexts;
  }

  getFolders(options: any = {}, blForceRefresh: boolean = false, params: any = {}) {
    options.location = options.location || 'ai';
    return this.folders.get(options, blForceRefresh, params);
  }

  getPromptFunctionActions() {
    return this.promptFunctionActions;
  }

  getPromptTypes() {
    return this.promptTypes;
  }

  getProvider() {
    return this.aiBridge.getProvider();
  }

  getStyles(blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', {}, ['ai', 'styles'], {}, blForceRefresh);
  }

  getTools() {
    return this.tools;
  }

  gender(item: any) {
    return this.AppCMS.loadPluginData('pipeline', {
      item: item,
    }, ['ai', 'gender']);
  }

  hasFunctionCallResponse(response: any) {
    let bl: boolean = false, details: any = {};

    try {
      if (!!response && !!response.output) {
        const decoded: any = this.toolsService.extractJson(`${(response.output || '').replace(/(\r\n|\n|\r)/gm, "")}`);

        if (!!decoded && !!decoded.name && !!decoded.arguments) {
          const decodedArgs: any = (typeof decoded.arguments === 'string' ? JSON.parse(`${decoded.arguments}`.replace(/(\r\n|\n|\r)/gm, "")) : decoded.arguments);

          bl = !!decodedArgs;
          details = decoded;
        }
      }
    } catch (e) {
      console.warn('validating if response is function call failed', e);
    }

    return {
      match: !!bl,
      details: details,
    };
  }

  imageToDepth(url: string, options: any = {}, blBackground: boolean = false, loadingModal: any = null, loadingOptions: loadingOptions | null = null) {
    return this.aiBridge.imageToDepth(url, options, blBackground, loadingModal, loadingOptions);
  }

  imageToMask(url: string, options: any = {}, blBackground: boolean = false, loadingModal: any = null, loadingOptions: loadingOptions | null = null) {
    return this.sd.imageToMask(url, options);
  }

  imageToSegmentation(url: string, options: any = {}, blBackground: boolean = false, loadingModal: any = null, loadingOptions: loadingOptions | null = null) {
    return this.sd.imageToSegmentation(url, options);
  }

  inspectURL(url: string, blForceRefresh: boolean = false, options: any = {}) {
    return new Promise(async (resolve, reject) => {
      try {

        // first, locally crawl url (if not provided in options)
        const content: any = (options.content || await this.crawler.fetch(url, {
          proxy: true,
        }));

        // then, run server-side inspection
        this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
          content: content,
          url: url,
        }), ['ai', 'inspectURL'], {}, blForceRefresh).then(resolve).catch(reject);

      } catch (e) {
        reject(e);
      }
    });
  }

  lookupImagesOnChildren(children: any[], taskIndex: number) {
    return new Promise((resolve, reject) => {
      if (!!children && !!children.length) {
        children.forEach(async (child: aiTask, childIndex: number) => {
          setTimeout(async () => {
            try {

              const searchParams: any = {
                options: {
                  creative: true,
                  negative_prompt: 'text, font, details, person',
                  height: 576,
                  width: 1024,
                },
              };

              const response: any = await this.textToImage(`${child.name}`, searchParams, true);

              console.log('lookupImagesOnChildren: child', child);
              console.log('lookupImagesOnChildren: searchParams', searchParams);
              console.log('lookupImagesOnChildren: response', response);

              if (!!response && !!response.photos) {
                child.media = (response.photos || []).slice(0, 10);
              }

              if (!!response && !!response.photo) {
                child.photo = `${response.photo || response.thumbnail}`;
                child.thumbnail = `${response.thumbnail || response.photo}`;
              }

              if (!!this.view.tasks && !!this.view.tasks[taskIndex] && !!this.view.tasks[taskIndex].children && !!this.view.tasks[taskIndex].children[childIndex]) {
                this.view.tasks[taskIndex].children[childIndex] = child;
              }

              if (childIndex === (children.length - 1)) {
                resolve(this.view.tasks);
              }
            } catch (e) {
              console.warn('> creating search query for child failed', child, e);

              if (childIndex === (children.length - 1)) {
                resolve(this.view.tasks);
              }
            }
          }, (childIndex * 1000));
        });
      }
    });
  }

  mapContextToCategory(context: string) {
    const mappedContext: string = `${context || ''}`.replaceAll('_', '-');

    const mapToTextContexts: string[] = this.contexts.filter((context: aiContext) => {
      return context.category === 'text-generation';
    }).map((context: aiContext) => {
      return `${context.uid || ''}`;
    }) as string[];

    if (mapToTextContexts.indexOf(context) !== -1) {
      return 'text-generation';
    }

    return mappedContext;
  }

  optimizeImageToVideoPrompt(prompt: string, options: any = {}) {
    return new Promise(async (resolve, reject) => {
      try {

        const exec: any = await this.aiBridge.execute({
          context: 'text_generation',
          history: [
            {
              role: 'system',
              content: `Reproduce an optimized version of the prompt that gives the scene more life.
              Focus on detailed, chronological descriptions of actions and scenes.
              Include specific movements, appearances, camera angles, and environmental details - all in a single flowing paragraph.
              Start directly with the action, and keep descriptions literal and precise.
              Think like a cinematographer describing a shot list. Keep within 200 words.
              
              For best results, build your prompts using this structure:
              - Start with main action in a single sentence
              - Add specific details about movements and gestures
              - Describe character/object appearances precisely
              - Include background and environment details
              - Specify camera angles and movements
              - Describe lighting and colors
              - Note any changes or sudden events

              IMPORTANT:
              - Your prompt must be english
              - Do not output anything else except the improved prompt only.`
            }
          ],
          post_content: `Input prompt: "${prompt}"`,
        }, true);

        resolve(exec);
      } catch (e) {
        reject(e);
      }
    });
  }

  optimizeProjectPromptAttributes(project: project, key: string) {
    return new Promise((resolve, reject) => {

      if (!project.config.assets[key] || (project.config.assets[key] === '')) {
        switch (key) {
          case 'negative_prompt':
            project.config.assets[key] = 'bad quality, worst quality, ugly, deformed';
            break;
          case 'prompt_suffix':
            project.config.assets[key] = 'best quality, masterpiece, pixelperfect, ultrarealistic';
            break;
          default:
            project.config.assets[key] = '';
            break;
        }
      }

      const history: any[] = [
        {
          role: 'system',
          content: `Optimize the parameters for an optimal AI text-to-image result.
          Automatically recognize the language and translate all keywords to english language.
          Important: Only answer with an optimized, comma-separated list of English keywords based on the input (6-12 keywords).`,
        }
      ];

      if (typeof project.config.assets[key] === 'object') {
        project.config.assets[key] = this.toolsService.trim(project.config.assets[key].map((attribute: any) => {
          return attribute.title || attribute.uid;
        }).join(','));
      }

      this.aiBridge.execute({
        context: 'text_optimization',
        history: history,
        post_content: `${project.config.assets[key] || ''}`,
      }, true)
        .then((response: any) => {
          response.items = [];

          if (!!response && !!response.output) {
            response.items = response.output.split(',').map((tag: string) => {
              tag = this.toolsService.trim(tag);
              return { title: tag, uid: tag };
            });
          }

          resolve(response);
        })
        .catch(reject);
    });
  }

  optimizePrompt(prompt: string, options: any = {}) {
    options = options || {};

    options.context = 'text_optimization';

    options.config = options.config || {
      context: options.context,
    };

    options.history = options.history || [];
    options.post_content = `Improve only the following prompt and return the improved prompt (plain text string, no HTML) only without any further output.`;

    options.history.push({
      role: 'user',
      content: `${prompt || ''}`,
    });

    return this.aiBridge.execute(options);
  }

  quiz(item: any) {
    return this.AppCMS.loadPluginData('pipeline', {
      item: item,
    }, ['ai', 'quiz']);
  }

  removeBackground(url: string, options: any = {}, blBackground: boolean = false, loadingModal: any = null, loadingOptions: loadingOptions | null = null) {
    return this.aiBridge.removeBackground(url, options, blBackground, loadingModal, loadingOptions);
  }

  rewrite(item: any) {
    return this.AppCMS.loadPluginData('pipeline', {
      item: item,
    }, ['ai', 'rewrite']);
  }

  search(options: any = {}, params: any = {}, blForceRefresh: boolean = false) {
    return this.aiBridge.search(options, params, blForceRefresh);
  }

  segmentAnything(url: string, options: any = {}, blBackground: boolean = false, loadingModal: any = null, loadingOptions: loadingOptions | null = null) {
    return this.aiBridge.segmentAnything(url, options, blBackground, loadingModal, loadingOptions);
  }

  setConfig(config: aiSettings) {
    return this.aiBridge.setConfig(config);
  }

  shorten(item: any) {
    return this.AppCMS.loadPluginData('pipeline', {
      item: item,
    }, ['ai', 'shorten']);
  }

  simplify(item: any) {
    return this.AppCMS.loadPluginData('pipeline', {
      item: item,
    }, ['ai', 'simplify']);
  }

  textToImage(input: string, searchParams: any = {}, blForceRefresh: boolean = false) {
    return this.aiBridge.textToImage(input, searchParams, blForceRefresh);
  }

  textToSpeech(text: string, options: any = {}) {
    return new Promise(async (resolve, reject) => {
      if (!text) {
        reject('error_missing_input');
      } else {
        try {
          this.zone.runOutsideAngular(async () => {

            const ttsPipe = await this.aiBridge.initTextToSpeechPipeline();
            const speaker_embeddings = 'https://huggingface.co/datasets/Xenova/transformers.js-docs/resolve/main/speaker_embeddings.bin';
            const result = await ttsPipe(text, { speaker_embeddings });

            function audioBufferToWav(buffer: AudioBuffer): ArrayBuffer {
              const numChannels = buffer.numberOfChannels;
              const sampleRate = buffer.sampleRate;
              const format = 1; // PCM
              const bitDepth = 16;

              const bytesPerSample = bitDepth / 8;
              const blockAlign = numChannels * bytesPerSample;

              const dataLength = buffer.length * blockAlign;
              const bufferLength = 44 + dataLength;

              const arrayBuffer = new ArrayBuffer(bufferLength);
              const view = new DataView(arrayBuffer);

              // WAV header
              writeString(view, 0, 'RIFF');
              view.setUint32(4, 36 + dataLength, true);
              writeString(view, 8, 'WAVE');
              writeString(view, 12, 'fmt ');
              view.setUint32(16, 16, true);
              view.setUint16(20, format, true);
              view.setUint16(22, numChannels, true);
              view.setUint32(24, sampleRate, true);
              view.setUint32(28, sampleRate * blockAlign, true);
              view.setUint16(32, blockAlign, true);
              view.setUint16(34, bitDepth, true);
              writeString(view, 36, 'data');
              view.setUint32(40, dataLength, true);

              // Write audio data
              const offset = 44;
              const channelData = new Float32Array(buffer.length);

              for (let channel = 0; channel < numChannels; channel++) {
                buffer.copyFromChannel(channelData, channel, 0);
                let pos = offset;
                for (let i = 0; i < channelData.length; i++) {
                  const sample = Math.max(-1, Math.min(1, channelData[i]));
                  view.setInt16(pos, sample < 0 ? sample * 0x8000 : sample * 0x7FFF, true);
                  pos += bytesPerSample;
                }
              }

              return arrayBuffer;
            }

            function createAudioFromFloat32(audioData, sampleRate) {

              // Create audio context
              const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();

              // Create buffer with audio data
              const audioBuffer = audioContext.createBuffer(1, audioData.length, sampleRate);
              audioBuffer.getChannelData(0).set(audioData);

              // Create buffer source
              const source = audioContext.createBufferSource();
              source.buffer = audioBuffer;

              // Connect to audio context destination for output
              source.connect(audioContext.destination);

              // Create audio element
              const audio = new Audio();

              // Convert buffer to WAV format
              const wavBuffer = audioBufferToWav(audioBuffer);
              const blob = new Blob([wavBuffer], { type: 'audio/wav' });
              audio.src = URL.createObjectURL(blob);

              return new Promise((resolve, reject) => {
                audio.oncanplay = () => resolve(audio);
                audio.onerror = (e) => reject(e);
              });
            }

            function writeString(view: DataView, offset: number, string: string) {
              for (let i = 0; i < string.length; i++) {
                view.setUint8(offset + i, string.charCodeAt(i));
              }
            }

            const audioElement: any = await createAudioFromFloat32(result.audio, result.sampling_rate);

            // Add event listeners to debug
            audioElement.addEventListener('playing', () => {
              console.log('[ AI TOOLS ] textToSpeech: Audio is playing');

              if (!!options.hasOwnProperty('onPlay')) {
                options.onPlay({
                  options: options,
                  text: text,
                });
              }
            });

            audioElement.addEventListener('error', (e) => {
              console.error('[ AI TOOLS ] textToSpeech: Audio error:', e);

              if (!!options.hasOwnProperty('onError')) {
                options.onError({
                  options: options,
                  text: text,
                });
              }
            });

            // Set volume to make sure it's not muted
            audioElement.volume = 1.0;

            await audioElement.play();

            const response: any = {
              audio: audioElement,
              options: options,
              text: text,
            };

            if (!!options.hasOwnProperty('onEnd')) {
              options.onEnd(response);
            }

            resolve(response);
          });
        } catch (e) {
          console.error('[ AI TOOLS ] textToSpeech: Error:', e);

          if (!!options.hasOwnProperty('onError')) {

            const response: any = {
              error: e,
              options: options,
              text: text,
            };

            options.onError(response);
            resolve(response);
          } else {
            reject(e);
          }
        };
      }
    });
  }

  ungender(item: any) {
    return this.AppCMS.loadPluginData('pipeline', {
      item: item,
    }, ['ai', 'ungender']);
  }

  updateFolder(folder: folder) {
    return this.folders.update(folder);
  }

}